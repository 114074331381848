import React from 'react';
import '../styles/components/NotFound.css';
import { TbCloudSearch } from "react-icons/tb";

const NotFound = () => {
    return (
      <div className="not-found">
        <TbCloudSearch className="notfound-icon" />
        <h1>Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
      </div>
    );
  };
  
export default NotFound;
  