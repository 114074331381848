import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/NarrativeOverview.css';
import { GoChevronDown, GoChevronRight } from "react-icons/go"; //import icon
import AuthContext from '../context/AuthProvider';
import AxiosIntercept from '../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const NarrativeOverview = () => {
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    if (auth !== null) {
        axios.defaults.withCredentials = true;
        const role = auth.userRole;
        // handle jwt refresh
        AxiosIntercept(userID, role);
    }

    const [narratives, setNarratives] = useState([]);
    const [originalNarratives, setOriginalNarratives] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const handleAddNewNarrative = () => {
        navigate('/add-new-narrative');
    }

    //handle toggle blurb 
    const [showBlurb, setShowBlurb] = useState(false);

    const toggleBlurb = () => {
        setShowBlurb(!showBlurb);
    };

    useEffect(() => {
        const getNarratives = async () => {
            try {
                const response = await axios.get(`${_URL}/narratives`);
                console.log(response.data);
                setNarratives(response.data);
                setOriginalNarratives(response.data);
            } catch (error) {
                console.error('error getting narratives', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Narratives.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            } finally {
                setLoading(false);
            }
        };

        getNarratives();
    }, []);

    useEffect(() => {
        const filteredNarratives = originalNarratives.filter(narrative => {
            const matchesSearch = narrative.narrTitle.toLowerCase().includes(searchText) ||
                                  narrative.narrDesc.toLowerCase().includes(searchText);
            const matchesCategory = selectedCategories.length === 0 || 
                                    selectedCategories.includes(narrative.narrCategory);
            return matchesSearch && matchesCategory;
        });
        setNarratives(filteredNarratives);
    }, [searchText, selectedCategories, originalNarratives]);

    const handleSearch = (event) => {
        setSearchText(event.target.value.toLowerCase());
    };

    const handleFilterClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleFilter = (category) => {
        if (category === 'All') {
            setSelectedCategories([]);
        } else {
            setSelectedCategories([category]); 
        }
        setShowDropdown(false);
    };
    
    const truncateNarrative = (text, limit) => {
        if (!text) return '';
        const words = text.split(' ');
        if (words.length > limit) {
            return words.slice(0, limit).join(' ') + '...';
        }
        return text;
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="narrativesContainer">
            <div className="narrativeTitle">
                <h1><strong>Repository</strong></h1>
                <input
                    type="text"
                    className="searchBar"
                    placeholder="Search bar"
                    onChange={handleSearch}
                />
                <div className="filterContainer">
                    <button className="filterButton" onClick={handleFilterClick}>
                        Filter
                    </button>
                    {showDropdown && (
                        <div className="filterDropdown">
                            <ul className="filterDropdownList">
                                <li className="filterDropdownItem">
                                    <a className="filterDropdownLink" onClick={() => handleFilter('All')}>All</a>
                                </li>
                                <li className="filterDropdownItem">
                                    <a className="filterDropdownLink"><strong>Categories</strong></a>
                                    {['Entrepreneur', 'Homemaker', 'Social Worker'].map(category => (
                                        <a key={category} className="filterDropdownLink-categories" onClick={() => handleFilter(category)}>{category}</a>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            {/* information blurb */}
            <div className="nar-info-bar" onClick={toggleBlurb}>
                <span className="nar-info-bar-icon">
                    {showBlurb ? <GoChevronDown /> : <GoChevronRight />}
                </span>
                <span className="nar-info-bar-title">
                    {showBlurb ? 'Hide Information About The Narratives' : 'Show Information About The Narratives'}
                </span>
            </div>
            {showBlurb && (
                <div className="nar-info-blurb">
                    <span>
                    This page hosts anonymised accounts from women sharing their real experiences with microfinance, both positive and negative.  
                    </span>
                </div>
            )}
            
            {narratives.map(narrative => (
                <Link key={narrative.narrID} to={`/narratives/${narrative.narrID}`} className="narrativeLink">
                    <div className="narrativeBox">
                        <h2>{narrative.narrTitle}</h2>
                        <p className="narrativeCategory"><strong>Category: {narrative.narrCategory}</strong></p>
                        <p className="narrativeDescription">{truncateNarrative(narrative.narrDesc, 58)}</p>
                    </div>
                </Link>
            ))}
        
            {auth && auth.userRole === 'moderator' && ( // if userRole: 'moderator' show Add Narrative button
                <div>
                  <button className='mod-add-narrative' onClick={handleAddNewNarrative}>Add Narrative</button>
                </div>
            )}
        </div>
    );
};

export default NarrativeOverview;
