import { useNavigate } from 'react-router-dom';
import '../../styles/moderator/ModArchivedPostReportsOverview.css';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AxiosIntercept from '../../context/AxiosIntercept';
import AuthContext from '../../context/AuthProvider';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModArchivedPostReportsOverview = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);


    const navigate = useNavigate(); 
    const handleClick = (id, source) => { // redirect to individual post report
        navigate(`/archived-post-reports/${id}?source=${source}`, {state: {isArchived: true}});  
    };

    const [archForumPostReports, setArchForumPostReports] = useState('');
    const [archPostReports, setArchPostReports] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getReportedPosts = async () => {
            try {
                console.log(`getting archived post reports`);
                const response = await axios.get(`${_URL}/archived-post-reports`);
                setArchForumPostReports(response.data.forum); 
                setArchPostReports(response.data.archived)
            } 
            catch (error) {
                console.error('error getting archived post reports:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Archived Forum Post Reports',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } 
            finally {
                setLoading(false);
            }
        };
        getReportedPosts();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="archived-post-reports-container">
            <h1>Archived Post Reports</h1>
            <div className='archived-post-reports-table'>
                <table>
                    <tr>
                        <th>Post Title</th>
                        <th className='reason'>Reason</th>
                        <th className='status'>Post Status</th>
                        <th className='status'>Report Status</th>
                        <th>Moderator</th>
                    </tr>
                    {archForumPostReports.map((report, index) => {
                        return (
                            <tr key={report.reportID} onClick={() => handleClick(report.reportID, "published")}>
                                <td className='title'>{report.post.postTitle}</td> 
                                <td className='reason'>{report.reportReason}</td>
                                <td className='status'>{report.post.postStatus}</td>
                                <td className='status'>{report.reportStatus}</td>
                                <td>{report.user.username}</td>
                            </tr>
                        );
                    })}
                    {archPostReports.map((report, index) => {
                        return (
                            <tr key={report.reportID} onClick={() => handleClick(report.reportID, "archived")}>
                                <td className='title'>{report.post.postTitle}</td> 
                                <td className='reason'>{report.reportReason}</td>
                                <td className='status'>{report.post.postStatus}</td>
                                <td className='status'>{report.reportStatus}</td>
                                <td>{report.user.username}</td>
                            </tr>
                        );
                    })}
                </table>
            </div>
        </div>
    );
};

export default ModArchivedPostReportsOverview;
