import React from 'react';
import '../styles/Resource.css';
import Loading from '../components/Loading';
import  { useState, useEffect } from 'react';

const Resource = () => {
  const additionalResources = [
    { id: 1, link: 'https://www.afsa.gov.au/i-cant-pay-my-debts/support-services/budgeting', name: 'Budgeting Tools' },
    { id: 2, link: 'https://www.forbes.com/advisor/au/savings/best-budgeting-apps/', name: 'Best Budgeting Apps' },
    { id: 3, link: 'https://moneysmart.gov.au/loans/payday-loan-calculator', name: 'moneysmart Payday Loan Calculator' },
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true); 
        await new Promise(resolve => setTimeout(resolve, 500)); 
        setLoading(false); 
    };

    fetchData();
    }, []);

    if (loading) {
        return <Loading />;  
    }
    
  return (
    <div id='additional-resource'>
      <h1 className='page-header'>Additional Resources</h1>

      {/* First Resource Category */}
      <div className='resources-section'>
        <div className='resource-category-box'>
          <h className='resource-title'>Other budgeting resources:</h>
        </div>
        <div className='links'>
          {additionalResources.map((resource) => (
              <div
                  key={resource.id}
                  className="resource-box-budgeting"
                  onClick={() => window.open(resource.link, '_blank', 'noopener noreferrer')}
                  style={{ cursor: 'pointer' }} 
              >
                  {resource.name}
              </div>
          ))}
        </div>
      </div>

      {/* Second Resource Category */}
      <div className='resources-section'>
        <div className='resource-category-box'>
          <h className='resource-title'>Feeling unsafe?</h>
        </div>
        
        {/* Helpline Titles inside the container */}
        <div className='links'>
          <div className='resource-box-feeling-unsafe'>
          <div className='resource-unsafe-content'>
            <h3 className='sub-resource-title-nh' >National Helpline</h3>
            <p style={{ paddingTop: '75px', textAlign:'left' }}>
            <strong style={{ display: 'block', textAlign: 'center', width: '100%'}}>1800RESPECT</strong><br />
              <strong>Phone:</strong> 1800 737 732<br />
              <strong>Availability:</strong> 24/7<br />
              National domestic, family, and sexual violence counselling service offering free and confidential support.<br />
              <br />
              <strong>In immediate danger?<br /></strong> Call <strong>000</strong> for police or ambulance assistance.
            </p>
          </div>
          </div>

          <div className='resource-box-feeling-unsafe'>
            <h3 className='sub-resource-title'>State-Specific Helplines</h3>
            <p style={{ paddingTop: '63px' ,textAlign:'left'}}>
              <strong>Western Australia:</strong><br />
              Women's Domestic Violence Helpline<br />
              <strong>Phone:</strong> 1800 007 339 (24/7)<br /><br />

              <strong>Victoria:</strong><br />
              Safe Steps Family Violence Response Centre<br />
              <strong>Phone:</strong> 1800 015 188 (24/7)<br /><br />

              <strong>New South Wales:</strong><br />
              Domestic Violence Line<br />
              <strong>Phone:</strong> 1800 656 463 (24/7)<br /><br />

              <strong>Queensland:</strong><br />
              DVConnect<br />
              <strong>Phone:</strong> 1800 811 811 (24/7)<br /><br />

              <strong>South Australia:</strong><br />
              Domestic Violence Crisis Service<br />
              <strong>Phone:</strong> 1800 800 098 (24/7)<br /><br />

              <strong>Tasmania:</strong><br />
              Family Violence Counselling and Support Service<br />
              <strong>Phone:</strong> 1800 608 122
            </p>
          </div>

          <div className='resource-box-feeling-unsafe'>
            <h3 className='sub-resource-title'>Additional Resources</h3>
            <p style={{ paddingTop: '63px' , textAlign:'left'}}>
            <strong
              onClick={() => window.open('https://www.dss.gov.au/ending-violence/help-and-support', '_blank', 'noopener noreferrer')}
              className='add-link'
            >
              Legal Aid Services
            </strong><br />
              Free legal advice and representation.<br />
              <br />
              <strong
              onClick={() => window.open('https://www.dss.gov.au/women-programs-services-ending-violence/keeping-women-safe-in-their-homes', '_blank', 'noopener noreferrer')}
              className='add-link'
            >
              Keeping Women Safe in their Homes
            </strong><br />
              Program helping women stay safely in their homes when appropriate.<br />
              <br />
              <strong
              onClick={() => window.open('https://www.esafety.gov.au/women', '_blank', 'noopener noreferrer')}
              className='add-link'
            >
              eSafetyWomen
            </strong><br />
              Resources on managing technology risks and online abuse.<br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resource;
