import DOMPurify from 'dompurify';

/**
 * @param {string} input - The input string to sanitize.
 * @returns {string} - The sanitized string.
 */
export const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
};

/**
 * @param {string} input - The input string to sanitize.
 * @returns {string} - The sanitized string with only numbers and dots.
 */
export const allowOnlyNumbers = (input) => {
    return input.replace(/[^0-9.]/g, ''); // Allow only digits and dots
};

/**
 * @param {string} input - The input string to sanitize.
 * @param {Object} options - Options to specify what to allow/disallow.
 * @returns {string} - The sanitized string.
 */
export const sanitizeInputGeneral = (input) => {
    return sanitizeInput(input); // Just a pass-through for now
};

/**
 * @param {string} input - The input string to sanitize.
 * @returns {string} - The sanitized string.
 */
export const sanitizeEmail = (input) => {
    // This function ensures only valid email characters are allowed
    return input.replace(/[^a-zA-Z0-9@._-]/g, ''); // Allow only email-friendly characters
};
