import { useNavigate, useParams, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../../styles/moderator/ModReportedPosts.css';
import { checkAge } from '../../components/FormatFunctions';
import AuthContext from '../../context/AuthProvider';
import { formatDateTime } from '../../components/FormatFunctions';
import AxiosIntercept from '../../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModReportedPosts = () => {
    axios.defaults.withCredentials = true;
    const {auth} = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // set userID to null if undefined
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const navigate = useNavigate();
    const {id} = useParams(); // get id from params
    const [postReport, setPostReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modCmt, setModCmt] = useState('');

    const query = new URLSearchParams(window.location.search);
    const source = query.get('source');

    const location = useLocation();
    const isArchived = location.state?.isArchived;


    // set post status to Rejected since post report was Approved
    const rejectPost = async () => {
        try {
            const responsePost = await axios.put(`${_URL}/reported-posts/${postReport.postRef}/reject-post-status?source=${source}`, {userID});
            if (responsePost) {
                if (source === "published" && postReport.postStatus === "Published") {
                    Swal.fire({
                        title: 'Post report has been Approved. \nUsers will no longer see this post in the Forum.',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        customClass: {
                          popup: 'swal2-popup-custom',
                          title: 'swal2-title-custom',
                          confirmButton: 'swal2-confirmbutton-custom'
                        }
                    });
                } else if ((source === "published" && postReport.postStatus === "Rejected") || source === "archived") {
                    Swal.fire({
                        title: 'Post report has been Approved. \nThe post will continue to remain hidden from users in the Forum',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        customClass: {
                          popup: 'swal2-popup-custom',
                          title: 'swal2-title-custom',
                          confirmButton: 'swal2-confirmbutton-custom'
                        }
                    });
                }
                navigate('/reported-posts');
            } else {
                Swal.fire({
                    title: 'Error: \nPost status update failed.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                      }
                });
            }
        } catch (error) {
            console.error('error rejecting post:', error);
            Swal.fire({
                title: 'Error: \nFailed to reject Forum Post',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom'
                }
            });
        } finally {
            setLoading(false);
        }
    };
    
    // set post status to Published
    const publishPost = async () => {
        try {
            const responsePost = await axios.put(`${_URL}/reported-posts/${postReport.postRef}/publish-post-status?source=${source}`, {userID});
            if (responsePost) {
                Swal.fire({
                    title: 'Post report has been Rejected. \nThis post will now be visible to all users in the Forum.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom',
                        confirmButton: 'swal2-confirmbutton-custom'
                      }
                });
                navigate('/reported-posts');
            } else {
                Swal.fire({
                    title: 'Error: \nPost status update failed.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                      }
                });
            }
        } catch (error) {
            console.error('error rejecting post:', error);
            Swal.fire({
                title: 'Error: \nFailed to reject Forum Post Report',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom'
                }
            });
        } finally {
            setLoading(false);
        }
    };

    const handleApprove = async (e) => {
        e.preventDefault();
        let confirmApprove;
        if (source === "published" && postReport.postStatus === "Published") {
            confirmApprove = await Swal.fire({
                title: 'Do you wish to Approve this post report? \nApproving it will remove it from the Forum for all users.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom',
                  confirmButton: 'swal2-confirmbutton-custom',
                  cancelButton: 'swal2-cancelbutton-custom'
                }
            });
        } else if (source === "published" && postReport.postStatus === "Rejected") {
            confirmApprove = await Swal.fire({
                title: 'Do you wish to Approve this post report? \nThis post has already been Rejected and is no longer visible in the Forum. \nApproving this post report will have no changes.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom',
                  confirmButton: 'swal2-confirmbutton-custom',
                  cancelButton: 'swal2-cancelbutton-custom'
                }
            });
        } else if (source === "archived") {
            confirmApprove = await Swal.fire({
                title: 'Do you wish to Approve this post report? \nThis post has already been deleted / archived and is no longer visible in the Forum. \nApproving this post report will have no changes.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom',
                  confirmButton: 'swal2-confirmbutton-custom',
                  cancelButton: 'swal2-cancelbutton-custom'
                }
            });
        }
    
        if (confirmApprove.isConfirmed) {
            try {
                const responseReport = await axios.put(`${_URL}/reported-posts/${id}/approve`, {moderatorComment: modCmt, userID: userID});
                if (responseReport.data) {
                    await rejectPost();
                } else {
                    Swal.fire({
                        title: 'Error: \nApprove failed.',
                        icon: 'error',
                        showConfirmButton: false,
                        customClass: {
                          popup: 'swal2-popup-custom',
                          title: 'swal2-title-custom'
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                Swal.fire({
                    title: 'Error: Error occurred. \nApprove unsuccessful.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      popup: 'swal2-popup-custom',
                      title: 'swal2-title-custom'
                    }
                });
            }
        }
    };

    const handleReject = async (e) => {
        e.preventDefault();
        let confirmReject;
        if (source === "published" && postReport.postStatus === "Published") {
            confirmReject = await Swal.fire({
                title: 'Do you wish to Reject this post report? \nRejecting it will allow this post to remain Published in the Forum.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom',
                  confirmButton: 'swal2-confirmbutton-custom',
                  cancelButton: 'swal2-cancelbutton-custom'
                }
            });
        } else if (source === "published" && postReport.postStatus === "Rejected") {
            confirmReject = await Swal.fire({
                title: 'Do you wish to Reject this post report? \nThis post has already been Rejected and is no longer visible in the Forum. \nRejecting this post will Publish the post in the Forum and make it visible to all users.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom',
                  confirmButton: 'swal2-confirmbutton-custom',
                  cancelButton: 'swal2-cancelbutton-custom'
                }
            });
        } else if (source === "archived") {
            confirmReject = await Swal.fire({
                title: 'Do you wish to Reject this post report? \nThis post has already been deleted / archived and is no longer visible in the Forum.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom',
                    confirmButton: 'swal2-confirmbutton-custom',
                    cancelButton: 'swal2-cancelbutton-custom'
                }
            });
        }
        if (confirmReject.isConfirmed) {
            try {
                const response = await axios.put(`${_URL}/reported-posts/${id}/reject`, {moderatorComment: modCmt, userID: userID});
                if (response.data) {
                    if (source === "published" && postReport.postStatus === "Published") {
                        Swal.fire({
                            title: 'Post report has been Rejected. \nThis post will remain visible to all users.',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            customClass: {
                              popup: 'swal2-popup-custom',
                              title: 'swal2-title-custom',
                              confirmButton: 'swal2-confirmbutton-custom'
                            }
                        });
                    } else if (source === "published" && postReport.postStatus === "Rejected") {
                        await publishPost();
                    } else if (source === "archived") {
                        Swal.fire({
                            title: 'Post report has been Rejected. \nThis post will continue to remain hidden from all users.',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            customClass: {
                              popup: 'swal2-popup-custom',
                              title: 'swal2-title-custom',
                              confirmButton: 'swal2-confirmbutton-custom'
                            }
                        });
                    }
                    navigate('/reported-posts');
                } else {
                    Swal.fire({
                        title: 'Error: \nReject failed.',
                        icon: 'error',
                        showConfirmButton: false,
                        customClass: {
                            popup: 'swal2-popup-custom',
                            title: 'swal2-title-custom'
                          }
                    });
                }
            } catch (err) {
                console.error(err);
                Swal.fire({
                    title: 'Error: Error occurred. \nReject unsuccessful.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      popup: 'swal2-popup-custom',
                      title: 'swal2-title-custom'
                    }
                });
            }
        }
    };

    useEffect(() => {
        const getPostReport = async () => {
            try {
                console.log(`getting post report`);
                let response;
                if (!isArchived) {
                    response = await axios.get(`${_URL}/reported-posts/${id}?source=${source}`);
                }
                else if (isArchived) {
                    response = await axios.get(`${_URL}/archived-post-reports/${id}?source=${source}`);
                }
                setPostReport(response.data[0]);
            } 
            catch (error) {
                console.error('error getting post report:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Forum Posts Report',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } 
            finally {
                setLoading(false);
            }
        };
        getPostReport();
    }, [id, source, isArchived]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'Published':
                return '#BEF2B5';
            case 'Rejected':
                return '#DEDFDF';
            case 'Archived':
                return '#F5F5F5';
            default:
                return '#F5F5F5';
        }
    };

    if (loading) {
        return <Loading />;  
    }
 
    return (
        <div className="indiv-reportedpost">
            <div className='indiv-reportedpost-header'>
                <h1>{postReport.post.postTitle}</h1>
                <span className="indiv-reportedpost-status" style={{ backgroundColor: getStatusColor(postReport.postStatus) }}>
                    {postReport.postStatus}
                </span>
            </div>
            <div className='reportedpost-content'>
                <p className='authCategory'>{postReport.postUser.username} {checkAge(postReport.postUser.age)} | {postReport.post.postCategory}</p>
                <p>{postReport.post.postDesc}</p>
            </div>
            <div className='post-reason'>
                <label><strong>Reason</strong></label>
                <textarea value={postReport.reportReason} readOnly></textarea>
            </div>
            {(!isArchived) ? (
                <div className='post-reason'>
                    <label><strong>Moderator Comment</strong></label>
                    <textarea value={modCmt} onChange={(e) => setModCmt(e.target.value)}></textarea>
                </div>
            ) : (
                <div className='post-reason'>
                    <label><strong>Moderator Comment</strong></label>
                    <textarea value={postReport.moderatorComment || ""} readOnly placeholder={postReport.moderatorComment ? '' : 'No comment left by moderator.'}></textarea>
                </div>
            )}

            {(!isArchived) && (
                <div>
                    <button className='post-reject' onClick={handleReject}>Reject</button>
                    <button className='post-approve' onClick={handleApprove}>Approve</button>
                </div>
            )}
            {(isArchived) && (
                <div>
                    <p className='archived-info'>Post report was closed by {postReport.moderator.username} on {formatDateTime(postReport.modified)}.</p>
                </div>
            )}

        </div>
    );
};

export default ModReportedPosts;
