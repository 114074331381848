import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../styles/SignUp.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { validateUsername, validatePassword, validateEmail } from '../components/InputRegularExpression';
// import SweetAlert2
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

function SignUp() {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    age: '',
    occupation: '',
    password: '',
    confirmPassword: ''
  });

  // States for password visibility
  const [signUpPasswordVisible, setSignUpPasswordVisible] = useState(false);
  const [signUpConfirmPasswordVisible, setSignUpConfirmPasswordVisible] = useState(false);

  // Toggle password visibility
  const toggleSignUpPasswordVisibility = () => {
    setSignUpPasswordVisible(!signUpPasswordVisible);
  };

  // Toggle confirm password visibility
  const toggleSignUpConfirmPasswordVisibility = () => {
    setSignUpConfirmPasswordVisible(!signUpConfirmPasswordVisible);
  };

  // Handle changes to input fields
  const handleChange = (i) => {
    const { id, value } = i.target;
    setNewUser({ ...newUser, [id]: value });
  };

  // Handle Terms of Use and Policies checkbox
  const handleAcceptTerms = (i) => {
    setAcceptTerms(i.target.checked);
  };

  const handleTerm = () => {
    navigate('/terms-of-use-and-policies')
  }

  // Handle create new account submit
  const handleSubmit = async (i) => {
    i.preventDefault();

    const usernameValidation = validateUsername(newUser.username);
    const passwordValidation = validatePassword(newUser.password);
    const emailValidation = validateEmail(newUser.email);

    if (usernameValidation !== true) {
      Swal.fire({
        title: 'Error: \n' + usernameValidation,
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          title: 'swal2-title-custom',
          popup: 'swal2-popup-custom'
        }
      });
      return;
    }

    if (passwordValidation !== true) {
      Swal.fire({
        title: 'Error: \n' + passwordValidation,
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          title: 'swal2-title-custom',
          popup: 'swal2-popup-custom'
        }
      });
      return;
    }

    if (emailValidation !== true) {
      Swal.fire({
        title: 'Error: \n' + emailValidation,
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          title: 'swal2-title-custom',
          popup: 'swal2-popup-custom'
        }
      });
      return;
    }

    if (newUser.password !== newUser.confirmPassword) {
      Swal.fire({
        title: 'Error: Passwords provided do not match. \nPlease try again.',
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          title: 'swal2-title-custom',
          popup: 'swal2-popup-custom'
        }
      });
      return;
    }

    if (!acceptTerms) {
      Swal.fire({
        title: 'Error: \nTerms of Use and Policies must be accepted.',
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          title: 'swal2-title-custom',
          popup: 'swal2-popup-custom'
        }
      });
      return;
    }

    try {
      const response = await axios.post(`${_URL}/signup`, newUser);
      if (response.data.userID) {
        Swal.fire({
          title: 'Account has been created.',
          icon: 'success',
          confirmButtonText: 'Ok',
          customClass: {
            title: 'swal2-title-custom',
            popup: 'swal2-popup-custom',
            confirmButton: 'swal2-confirmbutton-custom'
          }
        }).then(() => {
          navigate(`/login`);
        });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        Swal.fire({
          title: 'Error: \n' + err.response.data.message,
          icon: 'error',
          showConfirmButton: false,
          customClass: {
            title: 'swal2-title-custom',
            popup: 'swal2-popup-custom'
          }
        });
      } else {
        Swal.fire({
          title: 'Error: Sign Up failed. \nAccount could not be created.',
          icon: 'error',
          showConfirmButton: false,
          customClass: {
            title: 'swal2-title-custom',
            popup: 'swal2-popup-custom'
          }
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true); 
        await new Promise(resolve => setTimeout(resolve, 500)); 
        setLoading(false); 
    };

    fetchData();
  }, []);

  if (loading) {
      return <Loading />;  
  }

  return (
    <div className="create-account-page">
      <div className="account-form-container">
        <h1 className="form-title">Create your account</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input id="username" type="text" value={newUser.username} onChange={handleChange}/>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input id="email" type="text" value={newUser.email} onChange={handleChange}/>
          </div>
          <div className="form-group">
            <label htmlFor="age">Age</label>
            <select id="age" value={newUser.age} onChange={handleChange}>
              <option value="">Select age</option>
              <option value="18-24">18-24</option>
              <option value="25-34">25-34</option>
              <option value="35-44">35-44</option>
              <option value="45-54">45-54</option>
              <option value="55 above">55 above</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="occupation">Occupation</label>
            <input id="occupation" type="text" value={newUser.occupation} onChange={handleChange}/>
          </div>
          <div className="form-group">
            <label htmlFor="password">Password *</label>
            <div className="password-input-container">
              <input id="password" type={signUpPasswordVisible ? "text" : "password"} value={newUser.password} onChange={handleChange}/>
              <i onClick={toggleSignUpPasswordVisibility} className="eye-icon">
                <FontAwesomeIcon icon={signUpPasswordVisible ? faEye : faEyeSlash} />
              </i>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password *</label>
            <div className="password-input-container">
              <input id="confirmPassword" type={signUpConfirmPasswordVisible ? "text" : "password"} value={newUser.confirmPassword} onChange={handleChange}/>
              <i onClick={toggleSignUpConfirmPasswordVisibility} className="eye-icon">
                <FontAwesomeIcon icon={signUpConfirmPasswordVisible ? faEye : faEyeSlash} />
              </i>
            </div>
          </div>
          <div className="form-group-terms">
            <input type="checkbox" id="terms" checked={acceptTerms} onChange={handleAcceptTerms}/>
            <label htmlFor="terms">By signing up, I agree to the <br /> 
            <span onClick={handleTerm}>
                  Terms of Use and Policies
            </span>
            </label>
          </div>
          <button type="submit" className="submit-button">Create Account</button>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
