import React, { useState , useEffect} from 'react';
import '../styles/ForgotPassword.css'; 
import Swal from 'sweetalert2';
import { validateEmail } from '../components/InputRegularExpression';
import Loading from '../components/Loading';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    //if email is empty
    if (email.trim() === '') {
      Swal.fire({
        title: 'Error: \nEmail cannot be empty.',
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          popup: 'swal2-popup-custom',
          title: 'swal2-title-custom'
        }
      });
      return;
    } 

    const emailValidation = validateEmail(email);
    //if email is not valid
    if (emailValidation !== true) {
      Swal.fire({
        title: 'Error: \n' + emailValidation,
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          title: 'swal2-title-custom',
          popup: 'swal2-popup-custom'
        }
      });
      return; 
    }
    
    //if email is valid
    Swal.fire({
      title: 'Password reset link has been sent. Please check your email.',
      icon: 'success',
      confirmButtonText: 'Ok',
      customClass: {
        popup: 'swal2-popup-custom',
        title: 'swal2-title-custom',
        confirmButton: 'swal2-confirmbutton-custom'
      }
    });
    setEmail('');
  };

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true); 
        await new Promise(resolve => setTimeout(resolve, 500)); 
        setLoading(false); 
    };

    fetchData();
  }, []);

  if (loading) {
      return <Loading />;  
  }

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-container">
        <div className="image-placeholder"></div>
        <div className="forgot-password-content">
          <h2 className="forgot-password-title">Forgot your password? <br /> Fret not, we got your back!</h2>
          <input 
            type="email" 
            className="forgot-password-input" 
            placeholder="Enter your email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="forgot-password-button" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
