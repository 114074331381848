import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Add this import statement

// Import CSS
import '../styles/FAQs.css';
import Loading from '../components/Loading';

const FAQs = () => {
    const [openQuestions, setOpenQuestions] = useState([]);
    const [loading, setLoading] = useState(true);

    const toggleQuestion = (id) => {
        if (openQuestions.includes(id)) {
            setOpenQuestions(openQuestions.filter(q => q !== id));
        } else {
            setOpenQuestions([...openQuestions, id]);
        }
    };

    // frequently asked questions list with answers
    const freQuestions = [
        { id: 1, question: 'What is microfinance?', answer: (
          <>Microfinance refers to the provision of financial services like small loans, savings accounts, insurance, and other products to individuals or small businesses that lack access to traditional banking. <br/><br/>
          Microfinance targets low-income groups predominantly in developing countries, who are often excluded from conventional banking due to a lack of collateral, credit history, or stable income.

          </>
        )},

        { id: 2, question: 'Is WillowSphere free to use?', answer: (
          <>Yes, WillowSphere is free for use to all. It is free to create an account to access restricted content as well. <br/><br/>
          However, all content, trademark, logos, and other intellectual property relating to WillowSphere are owned by WillowSphere. You can read more about this in the <Link to="/terms-of-use-and-policies">Terms of Use and Policies</Link>.
          </>
        )},

        { id: 3, question: 'What resources does WillowSphere have?', answer: (
          <>
            WillowSphere offers the following tools to help you improve your experience with microfinance. They are:<br />
            
            <strong>1. <Link to="/calculator">Calculator</Link></strong><br />
            You can use the calculator to better estimate loan expenses and identify any underlying costs you might have missed out.<br /><br />
      
            <strong>IMPORTANT NOTE:</strong><br />
            The calculated amount provided is an estimate. It may not represent your exact loan expenses due to varying loan terms and rules of different financial institutes. The information provided is not meant to and should not be taken as financial or legal advice.<br /><br />
      
            <strong>2.  <Link to="/narratives">Narratives</Link></strong><br />
            You can read the many narratives available on WillowSphere to gain insights and perspectives from diverse microfinance scenarios of other individuals.<br /><br />
      
            <strong>IMPORTANT NOTE:</strong><br />
            Although the narratives are accounts of real people, the names used in the Narrative are pseudonyms to protect the identities of the narrative owners.<br /><br />
      
            <strong>3. <Link to="/forum">Forum</Link></strong><br />
            You can use the Forum to engage in conversations with other WillowSphere users to share your experience and voice your opinions.<br /><br />
      
            <strong>IMPORTANT NOTE:</strong><br />
            A user account and user login is required to access the <Link to="/forum">Forum</Link>. All new Forum posts are reviewed by WillowSphere moderators before they can be “Published” in the Forum for all users to see.<br /><br />
            Failure to comply with the <Link to="/rules-of-engagement">Rules of Engagement</Link> and <Link to="/terms-of-use-and-policies">Terms of Use and Policies</Link> may result in your post and/or comments being taken down and removed from the platform.<br /><br />
      
            <strong>4. <Link to="/exhibit">Exhibit</Link></strong><br />
            You can use the Exhibit to learn more about microfinance and how it might affect your daily life.<br />
          </>
        )
      },

        { id: 4, question: 'What resources are restricted on WillowSphere?', answer: (
          <>
            All resources (<Link to="/calculator">Calculator</Link>, <Link to="/narratives">Narratives</Link>, and <Link to="/graph">Exhibit</Link>) on WillowSphere are free to use except the <Link to="/forum">Forum</Link>. <br />
            A user account is required to access the <Link to="/forum">Forum</Link>.You can create a WilowSphere user account by signing up.
          </>
        )
      },

        { id: 5, question: 'How do I sign up and create a WillowSphere account?', answer: (
          <>It is recommended to be at least 18 years of age when creating a WillowSphere account.  There are 2 ways you can sign up and join WillowSphere today.<br/>
          Navigate to either the <Link to="/">landing</Link> or <Link to="/login">Login page</Link>. Find the “<Link to="/signup">Sign Up</Link>” button and click on it to be redirected to the sign up page. 
          </>
          )
      },

        {id: 6, question: 'What information does WillowSphere collect when signing up for an account?', answer: (
          <>
            The following information is asked for:<br /><br />
            1. Username*<br />
            2. Email*<br />
            3. Age<br />
            4. Occupation<br />
            5. Password*<br /><br />
            *Mandatory / Required fields.<br />
            You can refer to our Privacy Policy in WillowSphere's <Link to="/terms-of-use-and-policies">Terms of Use and Policies</Link> for more details.
          </>
          )
      },

        { id: 7, question: 'Where can I find WillowSphere’s Terms of Use and Policies?', answer: (
          <>A summary of WillowSphere's <Link to="/terms-of-use-and-policies">Terms of Use and Policies</Link> can be found on the <Link to="/">landing</Link> page. You can further navigate to the <Link to="/terms-of-use-and-policies">Terms of Use and Policies</Link> page for more details. 
          </>
        )},

        { id: 8, question: 'How do I update my account and profile information?', answer:  (
          <>You must be logged in to your WillowSphere account to do this. <br/>
          Using the Account dropdown in the navigation bar and navigate to My Account. <br/>
          Find the “Update Information” button at the bottom left side of the page. You can use it to update your account and profile information.
          </>
        )},

        { id: 9, question: 'How do I reset my password?', answer: (
          <>You must be logged in to your WillowSphere account to do this. <br />
          Using the Account dropdown in the navigation bar and navigate to <Link to="/account">My Account</Link>. <br />
          Find the "Reset Password" button at the bottom right side of the page. You can use it to modify and reset your account password.<br /><br />
          If you have forgotten your password and are unable to login, refer to I forgot my WillowSphere account password. What should I do?
          
          </>
          )
      },

        { id: 10, question: 'I forgot my WillowSphere account password. What should I do?', answer: (
          <>'Navigate to the “<Link to="/login">Login</Link>” page. Find “<Link to="/forgot-password">Forgot your password</Link>” which is located below the password input field. Click on it and you will be redirected to reset your password.
          </>
        )},
        
        { id: 11, question: 'How do I create a new post in the Forum?', answer: (
          <>
          You must have a WillowSphere account and be logged in to create a new post. There are 2 ways you can create a new post.<br/>
          Navigate to either <Link to="/forum">Forum</Link> from the navigation bar or My Posts from the Account dropdown. Find the “New Post” button at the bottom right side of the page to create a new post.<br/><br/>
          All new posts will have “Pending” as the default status until the post content is reviewed by WillowSphere moderators.

          </>
      )},

        { id: 12, question: 'Why are all new Forum posts reviewed and moderated before they are Published in the Forum?', answer: (
          <>All new posts are reviewed by WillowSphere moderators before they are “Published” to ensure they comply with WillowSphere's <Link to="/terms-of-use-and-policies">Terms of Use and Policies</Link> and <Link to="/rules-of-engagement">Rules of Engagement</Link>. <br/>
          Moderation is also done to safeguard the discussion space and further prevent spread of inappropriate and inaccurate information which might cause harm to all Forum users. 
          </>
        )},

        { id: 13, question: 'What are the various post statuses?', answer: (
          <>
            There are 3 different post statuses:<br />
      
            <strong>1. Pending</strong><br />
            All new posts created will have Pending as the default status. It means the post is awaiting Moderator review and is not visible to all other users in the Forum yet.<br /><br />
      
            <strong>2. Published</strong><br />
            Published means WillowSphere moderators have approved the newly created post. It means the post is now “Published” and visible to all users in the Forum.<br /><br />
      
            <strong>3. Rejected</strong><br />
            Rejected means WillowSphere moderators did not approve the newly created post content as it may have violated the <Link to="/terms-of-use-and-policies">Terms of Use and Policies</Link> and/or <Link to="/rules-of-engagement">Rules of Engagement</Link>. It means the post will not be shared or visible to other users in the Forum.
          </>
          )
        },

        {id: 14, question: 'What are the Forum posting and commenting guidelines?', answer: (
          <>
            The Forum's <Link to="/rules-of-engagement">Rules of Engagement</Link> are:<br /><br />
            1. Be kind.<br />
            2. Respond appropriately, and avoid going off topic.<br />
            3. Respect everyone's opinions and privacy.<br />
            4. No bullying and hate speech.<br />
            5. No advertisements and spams.<br /><br />
            You can refer to WillowSphere's <Link to="/terms-of-use-and-policies">Terms of Use and Policies</Link> for additional information on the Use of the App.
          </>
        )
      },

      { id: 15, question: 'What can I do if I encounter behaviour and content I find inappropriate? ', answer: (
        <>
        Your opinions matter to us here at WillowSphere.<br/><br/>
        If you encounter content you feel does not comply with the <Link to="/terms-of-use-and-policies">Terms of Use and Policies</Link> and <Link to="/rules-of-engagement">Rules of Engagement</Link>, you can use the “Report” button or icons associated with the respective content to create a Report. All Reports submitted must be accompanied by a reason.<br/><br/>
        Your report will be received and thoroughly reviewed by WillowSphere moderators who will decide on the outcome of the Report. 
        </>
        )
    },

      { id: 16, question: 'What should I do if I face issues while using WillowSphere?', answer: (
        <>If you face any issues while using WillowSphere, please inform WillowSphere moderators through the <Link to="/contact-us">Contact Us Form</Link>. The moderators will do their best to help resolve your issues as soon as possible. 
        </>
      )},

      { id: 17, question: 'How can I get help and support or submit enquiries?', answer: (
        <>You can submit your questions through the <Link to="/contact-us">Contact Us Form</Link>. WillowSphere moderators will get back to you as soon as possible.<br/><br/>
        You can refer to WillowSphere's <Link to="/additional-resources">Additional Resources</Link> page for external third party information and resources on topics like Domestic Violence assistance and microfinance. 
        </>
      )},

      { id: 18, question: 'What languages does WillowSphere support?', answer: (
        <>Presently, WillowSphere supports English only. If you wish to see WillowSphere in other languages you can provide feedback to us via the <Link to="/contact-us">Contact Us Form</Link>.
        </>
      )},

    ];

    useEffect(() => {
      const fetchData = async () => {
          setLoading(true); 
          await new Promise(resolve => setTimeout(resolve, 500)); 
          setLoading(false); 
      };

      fetchData();
    }, []);

  
    if (loading) {
      return <Loading />;  
    }

    return (
        <div id='faqs'>
            <div className='title'>
                <p>Frequently Asked Questions</p>
            </div>
            <div className='questions'>
                <ul>
                    {freQuestions.map((item) => (
                        <li key={item.id} className='article'>
                            <div className='question-row' onClick={() => toggleQuestion(item.id)}>
                                <p>{item.question}</p>
                                {/* Add down arrow, rotated if open */}
                                <span className={`arrow ${openQuestions.includes(item.id) ? 'rotate' : ''}`}>&#x25BC;</span>
                            </div>
                            {openQuestions.includes(item.id) && <p className='answer'>{item.answer}</p>}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FAQs;
