// import css
import '../styles/Terms.css'
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Loading from '../components/Loading';

function Terms() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await new Promise(resolve => setTimeout(resolve, 500)); 
            setLoading(false); 
        };
  
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;  
    }
    
    return (
        <div>
            <h1 className='terms-header'>Terms of Use and Policies</h1>

            {/* Terms of Use and Policies content */}
            <p className='terms-p'>
                <strong>Introduction</strong><br />
                Welcome to WillowSphere. These Terms of Use and Policies govern your use of our app and services. By accessing or using WillowSphere, you agree to be bound by these Terms of Use and Policies. If you do not agree with these terms, please refrain from using the app.
            </p>
            <p className='terms-p'>
                <strong>Eligibility</strong><br />
                WillowSphere is intended for use by individuals aged 18 years or older. By using the app, you represent and warrant that you meet this age requirement and have the capacity to enter into these Terms of Use and Policies.
            </p>
            <p className='terms-p'>
                <strong>User Accounts</strong><br />
                Registration: To access certain features of the app, you may need to create an account. You agree to provide accurate and complete information during registration and to update your information as necessary.<br />
                <br/>
                Account Security: You are responsible for maintaining the confidentiality of your account login credentials and for all activities that occur under your account. Notify us immediately if you suspect any unauthorised use of your account.
            </p>
            <p className='terms-p'>
                <strong>Use of the App</strong><br />
                Personal Use: WillowSphere is intended for personal use only. You may not use the app for commercial purposes without prior written consent from us.<br />
                Prohibited Activities: You agree not to:<br />
                <br/>
                • Use the app for any unlawful or harmful purpose.<br />
                • Harass, abuse, or harm other users.<br />
                • Post or share false, misleading, or defamatory content.<br />
                • Interfere with the app’s operation or security.<br />
                • Attempt to gain unauthorised access to our systems or user accounts.
            </p>
            <p className='terms-p'>
                <strong>Content</strong><br />
                User-Generated Content: WillowSphere allows users to share their experiences and opinions. By submitting content, you grant us a non-exclusive, royalty-free, worldwide licence to use, distribute, modify, and display your content in connection with the app. You represent that you own or have the necessary rights to submit the content and that it does not violate any third-party rights.<br />
                <br/>
                Content Moderation: We reserve the right to review, remove, or modify any content that violates these Terms of Use and Policies or is deemed inappropriate.
            </p>
            <p className='terms-p'>
                <strong>Privacy</strong><br />
                Your privacy is important to us. Our Privacy Policy explains how we collect, use, and protect your personal information. By using WillowSphere, you agree to the terms of our Privacy Policy.
                <br />
                WillowSphere collects certain personal information like name, email, age, and occupation during the Account Sign Up process and in the Contact Us Form. All information collected is used solely for contact purposes and is not shared with any external third parties.
            </p>
            <p className='terms-p'>
                <strong>Intellectual Property</strong><br />
                All content, trademarks, logos, and other intellectual property related to WillowSphere are owned by us. You may not use our intellectual property without our prior written consent, except as permitted under these Terms of Use and Policies.
            </p>
            <p className='terms-p'>
                <strong>Disclaimer of Warranties</strong><br />
                WillowSphere is provided on an “as is” and “as available” basis. We make no warranties, express or implied, regarding the app's operation, availability, or the accuracy of information. We do not warrant that the app will be error-free or that any defects will be corrected.
            </p>
            <p className='terms-p'>
                <strong>Limitation of Liability</strong><br />
                To the fullest extent permitted by law, WillowSphere and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including lost profits or data, arising out of or related to your use of the app. Our total liability to you for any claim shall not exceed the amount you paid, if any, to use the app.
                <br />
                <br />
                Disclaimer: WillowSphere is not a financial advisor. All information, resources, and tools on WillowSphere are for general information and provided for your information and to illustrate scenarios. All information, resources, and tools should not be taken as substitues for professional advice. WillowSphere recommends contacting professional legal, financial, taxational, and or other adivce to determine and verify how WillowSphere information, resources, and tools are relevant to your personal cirsumstances.
                <br />
                <br />
                Calculator: Reasonable factors have been taken into account when designing the Calculator. However, WillowSphere provides no warranties and claims that information provided by the Calculator are catered and suited towards your personal circumstance. 
                <br />
                <br />
                Repository: The narratives in WillowSphere's Repository are to provide perspective on various microfinancial situations and share the impacts of microfinance in diverse and different scenarios. Although real, pseuodonyms are used to protect the identities of individuals the narratives are based on.
                <br />
                <br />
                Additional Resources: WillowSphere provides a curated list of links to external and third party sites that we feel may benefit you. However, we do not endorse these sites. When choosing to open and view these sites, ensure you review their terms of use and privacy policies. WillowSphere is not responsible for their content and practices. If you encounter any broken links, please inform us through the Contact Us Form.
                
            </p>
            <p className='terms-p'>
                <strong>Indemnification</strong><br />
                You agree to indemnify, defend, and hold harmless WillowSphere, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising from your use of the app or violation of these Terms of Use and Policies.
            </p>
            <p className='terms-p'>
                <strong>Changes to Terms</strong><br />
                We reserve the right to update or modify these Terms of Use and Policies at any time. We will notify you of any significant changes by posting the updated terms on the app or through other means. Your continued use of the app after such changes constitutes your acceptance of the new terms.
            </p>
            <p className='terms-p'>
                <strong>Termination</strong><br />
                We may terminate or suspend your access to WillowSphere at any time, with or without cause, and without prior notice. Upon termination, your right to use the app will immediately cease.
            </p>
            {/* <p className='terms-p'>
                <strong>Governing Law</strong><br />
                These Terms of Use and Policies are governed by and construed in accordance with the laws of Australia, without regard to its conflict of law principles. Any disputes arising out of or related to these terms shall be resolved in the courts of Australia.
            </p> */}
            <p className='terms-p'>
                <strong>Contact Us</strong><br />
                If you have any questions or concerns about these Terms of Use and Policies, please contact us through the <Link to="/contact-us"> Contact Us Form</Link>.
            </p>
        </div>
    );
}
export default Terms;
