import '../../styles/moderator/ModReportedCmts.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { formatDateTime, checkAge } from '../../components/FormatFunctions';
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModReportedCmts = () => {
    axios.defaults.withCredentials = true;
    const {auth} = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // set userID to null if undefined
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const {id} = useParams();
    const navigate = useNavigate();
    const [cmtReport, setCmtReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modCmt, setModCmt] = useState(null);
    const query = new URLSearchParams(window.location.search);
    const source = query.get('source');
    const location = useLocation();
    const isArchived = location.state?.isArchived;

    // update cmtStatus to Rejected since the comment report was Approved
    const rejectComment = async () => {
        try {
            const responseComment = await axios.put(`${_URL}/reported-comments/${cmtReport.reportRef}/reject-comment-status?source=${source}`, {userID});
            if (responseComment) {
              if (source === "published" && cmtReport.post.comments.cmtStatus === "Published") {
                await Swal.fire({
                  title: 'Comment report has been Approved. \nUsers will no longer see this comment in the Forum.',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom',
                    confirmButton: 'swal2-confirmbutton-custom'
                  }
                });
              } else if (
                (source === "published" && cmtReport.post.comments.cmtStatus === "Rejected") || 
                source === "archived"
              ) {
                await Swal.fire({
                  title: 'Comment report has been Approved. \nThe comment will continue to remain hidden from users in the Forum',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom',
                    confirmButton: 'swal2-confirmbutton-custom'
                  }
                });
              }
              navigate('/reported-comments');
            } else {
              Swal.fire({
                title: 'Error: \nComment status update failed.',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom'
                  }
              });
            }
          } catch (error) {
            console.error('error rejecting post:', error);
            Swal.fire({
              title: 'Error: \nFailed to reject Forum Post',
              icon: 'error',
              showConfirmButton: false,
              customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom'
              }
          });
          } finally {
            setLoading(false);
          }
        };
      

    // set comment status to Published
    const publishComment = async () => {
        try {
            const responseComment = await axios.put(
              `${_URL}/reported-comments/${cmtReport.reportRef}/publish-comment-status?source=${source}`, 
              { userID }
            );
            if (responseComment) {
              await Swal.fire({
                title: 'Comment report has been Rejected. \nThis comment will now be visible to all users in the Forum if the associated post status is updated from Rejected to Published.',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom',
                    confirmButton: 'swal2-confirmbutton-custom'
                  }
              });
              navigate('/reported-comments');
            } else {
              Swal.fire({
                title: 'Error: \nComment status update failed.',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom'
                  }
              });
            }
          } catch (error) {
            console.error('error rejecting post:', error);
            Swal.fire({
              title: 'Error: \nFailed to reject Forum Post Report',
              icon: 'error',
              showConfirmButton: false,
              customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom'
              }
          });
          } finally {
            setLoading(false);
          }
        };

    const handleApprove = async (e) => {
        e.preventDefault();
        let confirmApprove;
        if (source === "published" && cmtReport.post.comments.cmtStatus === "Published") {
            confirmApprove = await Swal.fire({
              title: 'Do you wish to Approve this comment report? \nApproving it will permanently hide this comment from all users in the Forum.',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                confirmButton: 'swal2-confirmbutton-custom',
                cancelButton: 'swal2-cancelbutton-custom'
              }
            });
          } else if (source === "published" && cmtReport.post.comments.cmtStatus === "Rejected") {
            confirmApprove = await Swal.fire({
              title: 'Do you wish to Approve this post report? \nThe comment has already been Rejected and is no longer visible in the Forum. \nApproving this comment report will have no changes.',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                confirmButton: 'swal2-confirmbutton-custom',
                cancelButton: 'swal2-cancelbutton-custom'
              }
            });
          } else if (source === "archived") {
            confirmApprove = await Swal.fire({
              title: 'Do you wish to Approve this commment report? \nThe post this comment is associated with has already been deleted / archived and is no longer visible in the Forum. \nApproving this comment report will have no changes.',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                confirmButton: 'swal2-confirmbutton-custom',
                cancelButton: 'swal2-cancelbutton-custom'
              }
            });
          }
        
          if (confirmApprove.isConfirmed) {
            try {
              const responseReport = await axios.put(
                `${_URL}/reported-comments/${id}/approve`, 
                { moderatorComment: modCmt, userID: userID }
              );
              if (responseReport.data) {
                await rejectComment();
              } else {
                Swal.fire({
                  title: 'Error: \nApprove failed.',
                  icon: 'error',
                  showConfirmButton: false,
                  customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom'
                  }
                });
              }
            } catch (err) {
              console.error(err);
              Swal.fire({
                title: 'Error: Error occurred. \nApprove unsuccessful.',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom'
                }
              });
            }
          }
        };

        const handleReject = async (e) => {
            e.preventDefault();
            let confirmReject;
          
            if (source === "published" && cmtReport.post.comments.cmtStatus === "Published") {
              confirmReject = await Swal.fire({
                title: 'Do you wish to Reject this comment report? \nRejecting it will allow this comment to remain visible to all users.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom',
                  confirmButton: 'swal2-confirmbutton-custom',
                  cancelButton: 'swal2-cancelbutton-custom'
                }
              });
            } else if (source === "published" && cmtReport.post.comments.cmtStatus === "Rejected") {
              confirmReject = await Swal.fire({
                title: 'Do you wish to Reject this comment report? \nRejecting this comment report will allow this comment to be visible to all users in the Forum.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                  popup: 'swal2-popup-custom',
                  title: 'swal2-title-custom',
                  confirmButton: 'swal2-confirmbutton-custom',
                  cancelButton: 'swal2-cancelbutton-custom'
                }
              });
            } else if (source === "archived") {
              confirmReject = await Swal.fire({
                title: 'Do you wish to Reject this comment report? \nThe post this comment is associated with has already been deleted / archived and is no longer visible in the Forum.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom',
                    confirmButton: 'swal2-confirmbutton-custom',
                    cancelButton: 'swal2-cancelbutton-custom'
                }
              });
            }
          
            if (confirmReject.isConfirmed) {
              try {
                const response = await axios.put(
                  `${_URL}/reported-comments/${id}/reject`, 
                  { moderatorComment: modCmt, userID: userID }
                );
                if (response.data) {
                  if (source === "published" && cmtReport.post.comments.cmtStatus === "Published") {
                    await Swal.fire({
                      title: 'Comment report has been Rejected. \nThis comment will remain visible to all users.',
                      icon: 'success',
                      confirmButtonText: 'Ok',
                      customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom',
                        confirmButton: 'swal2-confirmbutton-custom'
                      }
                    });
                  } else if (source === "published" && cmtReport.post.comments.cmtStatus === "Rejected") {
                    await publishComment();
                  } else if (source === "archived") {
                    await Swal.fire({
                      title: 'Comment report has been Rejected. \nThis comment will continue to remain hidden from all users as the associated post has been deleted / archived.',
                      icon: 'success',
                      confirmButtonText: 'Ok',
                      customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom',
                        confirmButton: 'swal2-confirmbutton-custom'
                      }
                    });
                  }
                  navigate('/reported-comments');
                } else {
                  await Swal.fire({
                    title: 'Error: \nReject failed.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                      }
                  });
                }
              } catch (err) {
                console.error(err);
                await Swal.fire({
                  title: 'Error: Error occurred. \nReject unsuccessful.',
                  icon: 'error',
                  showConfirmButton: false,
                  customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom',
                  }
                });
              }
            }
          };
          
    useEffect(() => {
        const getCmtReport = async () => {
            try {
                console.log(`getting comment report`);
                let response;
                if (!isArchived) {
                    response = await axios.get(`${_URL}/reported-comments/${id}?source=${source}`);
                }
                else if (isArchived) {
                    response = await axios.get(`${_URL}/archived-comment-reports/${id}?source=${source}`);
                }
                setCmtReport(response.data[0]); 
            } catch (error) {
                console.error('error getting comment report:', error);
                Swal.fire({
                  title: 'Error: \nFailed to get Forum Comment Report',
                  icon: 'error',
                  showConfirmButton: false,
                  customClass: {
                      popup: 'swal2-popup-custom',
                      title: 'swal2-title-custom',
                  }
              });
            } finally {
                setLoading(false);
            }
        };
        getCmtReport();
    }, [id, source, isArchived]);

    if (loading) {
      return <Loading />;  
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 'Published':
                return '#BEF2B5';
            case 'Rejected':
                return '#DEDFDF';
            case 'Archived':
                return '#F5F5F5';
            default:
                return '#F5F5F5';
        }
    };
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="indiv-reportedcmt">
            <div className='indiv-reportedcmt-header'>
                <h1>{cmtReport.post.postTitle}</h1>
                <span className="indiv-reportedcmt-status" style={{ backgroundColor: getStatusColor(cmtReport.postStatus) }}>
                    {cmtReport.postStatus}
                </span>
            </div>
            <div className='desc'>
                <p className='authCategory'><strong>{cmtReport.postUser.username} {checkAge(cmtReport.postUser.age)} | {cmtReport.post.postCategory}</strong></p>
                <p>{cmtReport.post.postDesc}</p>
            </div>

            <div className='reportcmt-content'>
                <div className='report-box'></div>
                <div className='report-user'>
                    <p><strong>{cmtReport.cmtUser.username}</strong></p>
                    <p><strong>{formatDateTime(cmtReport.post.comments.cmtDateTime)}</strong></p>
                    <p>{cmtReport.post.postDesc}</p>
                </div>
            </div>
            <p><strong>Comment Status: {cmtReport.post.comments.cmtStatus}</strong></p>
            <div className='cmt-reason'>
                <label><strong>Reason</strong></label>
                <textarea value={cmtReport.reportReason} readOnly></textarea>
            </div>      
            {(isArchived) ? (
                <div className='cmt-reason'>
                    <label><strong>Moderator Comment</strong></label>
                    <textarea value={cmtReport.moderatorComment || ""} readOnly placeholder={cmtReport.moderatorComment ? "" : 'No comment left by moderator.'}></textarea>
                </div>
            ) : (
                <div className='cmt-reason'>
                    <label><strong>Moderator Comment</strong></label>
                    <textarea value={modCmt} onChange={(e) => setModCmt(e.target.value)}></textarea>
                </div>
            )}      
            {(!isArchived) && (
                <div>
                    <button className='cmt-reject' onClick={handleReject}>Reject</button>
                    <button className='cmt-approve' onClick={handleApprove}>Approve</button>
                </div>
            )}
            {(isArchived) && (
                <div>
                    <p>Comment report was closed by {cmtReport.moderator.username} on {formatDateTime(cmtReport.modified)}.</p>
                </div>
            )}
        </div>
    );
};
export default ModReportedCmts;

