import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../../styles/moderator/ModReviewNewPost.css";
import AuthContext from "../../context/AuthProvider";
import AxiosIntercept from "../../context/AxiosIntercept";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import { _URL } from "../../Utils";

const ModReviewNewPost = () => {
  axios.defaults.withCredentials = true;
  const { auth } = useContext(AuthContext);
  const userID = auth ? String(auth.userID) : null; // set userID to null if undefined
  const role = auth.userRole;
  // handle jwt refresh
  AxiosIntercept(userID, role);

  const navigate = useNavigate();
  const { id } = useParams();
  const [newPost, setNewPost] = useState();
  const [loading, setLoading] = useState(true);

  const handleApprove = async (e) => {
    e.preventDefault();
    Swal.fire({
      title:
        "Do you wish to approve this new discussion post ? \nApproving it will publish and make it visible to all users.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        title: "swal2-title-custom",
        popup: "swal2-popup-custom",
        confirmButton: "swal2-confirmbutton-custom",
        cancelButton: "swal2-cancelbutton-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(
            `${_URL}/review-new-posts/${id}/approve`
          );
          if (response.data) {
            Swal.fire({
              title:
                "New discussion post is approved. \nUser status will be updated.",
              icon: "success",
              confirmButtonText: "Ok",
              customClass: {
                title: "swal2-title-custom",
                popup: "swal2-popup-custom",
                confirmButton: "swal2-confirmbutton-custom",
              },
            }).then(() => {
              navigate("/review-new-posts");
            });
          } else {
            Swal.fire({
              title: "Error: Approve failed. \nPlease try again.",
              icon: "error",
              showConfirmButton: false,
              customClass: {
                title: "swal2-title-custom",
                popup: "swal2-popup-custom",
              },
            });
          }
        } catch (err) {
          console.error(err);
          Swal.fire({
            title: "Error: Error occurred. Approve unsuccessful.",
            icon: "error",
            showConfirmButton: false,
            customClass: {
              title: "swal2-title-custom",
              popup: "swal2-popup-custom",
            },
          });
        }
      }
    });
  };

  const handleReject = async (e) => {
    e.preventDefault();
    Swal.fire({
      title:
        "Do you wish to reject this new discussion post? \nRejecting it will prevent it from being published.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        title: "swal2-title-custom",
        popup: "swal2-popup-custom",
        confirmButton: "swal2-confirmbutton-custom",
        cancelButton: "swal2-cancelbutton-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(
            `${_URL}/review-new-posts/${id}/reject`
          );
          if (response.data) {
            Swal.fire({
              title:
                "New discussion post is rejected. \nUser post status will be updated.",
              icon: "success",
              confirmButtonText: "Ok",
              customClass: {
                title: "swal2-title-custom",
                popup: "swal2-popup-custom",
                confirmButton: "swal2-confirmbutton-custom",
              },
            }).then(() => {
              navigate("/review-new-posts");
            });
          } else {
            Swal.fire({
              title: "Error: \nReject failed",
              icon: "error",
              showConfirmButton: false,
              customClass: {
                title: "swal2-title-custom",
                popup: "swal2-popup-custom",
              },
            });
          }
        } catch (err) {
          console.error(err);
          Swal.fire({
            title: "Error: Error occurred. \nReject unsuccessful.",
            icon: "error",
            showConfirmButton: false,
            customClass: {
              title: "swal2-title-custom",
              popup: "swal2-popup-custom",
            },
          });
        }
      }
    });
  };

  useEffect(() => {
    const getNewPost = async () => {
      try {
        console.log(`getting new post for review`);
        const response = await axios.get(
          `${_URL}/review-new-posts/${id}`
        );
        setNewPost(response.data[0]);
      } catch (error) {
        console.error("error getting new post for review:", error);
        Swal.fire({
          title: "Error: \nFailed to get New Forum Post for review",
          icon: "error",
          showConfirmButton: false,
          customClass: {
            popup: "swal2-popup-custom",
            title: "swal2-title-custom",
          },
        });
      } finally {
        setLoading(false);
      }
    };
    getNewPost();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="review-new-post-container">
      <h1>{newPost.postTitle}</h1>
      <div className="review-new-post-content">
        <p className="review-new-post-category">
          {newPost.authorInfo.username} | {newPost.postCategory}
        </p>
        <p>{newPost.postDesc}</p>
      </div>
      <button className="review-post-reject" onClick={handleReject}>
        Reject
      </button>
      <button className="review-post-approve" onClick={handleApprove}>
        Approve
      </button>
    </div>
  );
};

export default ModReviewNewPost;
