import { useParams, useNavigate } from 'react-router-dom';
import '../styles/ForumReportPost.css';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthProvider';
import { anonymousUser, checkAge } from '../components/FormatFunctions';
import AxiosIntercept from '../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const ForumReportPost = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const navigate = useNavigate();
    const { id } = useParams();
    const [post, setPost] = useState();
    const [loading, setLoading] = useState(true);
    const [reason, setReason] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (reason.length <= 0) {
            Swal.fire({
                title: 'Error: \nReported reason cannot be empty.',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                  title: 'swal2-title-custom',
                  popup: 'swal2-popup-custom'
                }
              });
            return;
        }

        try {
            const response = await axios.post(`${_URL}/report-post/${id}`, {reportedBy: userID, reportReason: reason});
            if (response.data.postID) {
              Swal.fire({
                title: 'Your report has been sent to the moderator.',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                  title: 'swal2-title-custom',
                  popup: 'swal2-popup-custom',
                  confirmButton: 'swal2-confirmbutton-custom'
                }
              });
              navigate(`/forum/${response.data.postID}`);
            }
        } 
        catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
              Swal.fire({
                title: 'Error: \n' + err.response.data.message,
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                  title: 'swal2-title-custom',
                  popup: 'swal2-popup-custom'
                }
              });
            } else {
              console.error(err);
              Swal.fire({
                title: 'Error: \nPost report failed.',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                  title: 'swal2-title-custom',
                  popup: 'swal2-popup-custom'
                }
              });
            }
          }

    }
    useEffect(() => {
        const getReportPost = async () => {
            try {
                console.log(`getting report forum post`);
                const response = await axios.get(`${_URL}/report-post/${id}`);
                
                setPost(response.data[0]); 
            } catch (error) {
                console.error('error getting report forum post:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Report Forum Post',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            } finally {
                setLoading(false);
            }
        };
        getReportPost();
    }, [id]);

    if (loading) {
      return <Loading />; 
    }

    return (
        <div className="reportpost-container">
            <h1>{post.postTitle}</h1>
            <div className='desc'>
                <p className='authCategory'><strong>{anonymousUser(post.postAnon, post.postUser.username)} {checkAge(post.postUser.age)} | {post.postCategory}</strong></p>
                <p>{post.postDesc}</p>
            </div>

            <div className='reportpost-reason'>
                <label><strong>Reason</strong></label>
                <textarea placeholder='Reporting reason' value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
            </div>
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
};
export default ForumReportPost;

