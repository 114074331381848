// What is Microfinance

import { useState, useEffect } from 'react';
import '../../styles/graph/WhatIsMicrofinance.css';
import Loading from '../../components/Loading';

import Microloans from '../../images/exhibit/WhatIsMicrofinance/Microloans.png';
import FinancialServices from '../../images/exhibit/WhatIsMicrofinance/FinancialServices.png';
import TargetDemographics from '../../images/exhibit/WhatIsMicrofinance/TargetDemographics.png';
import MicroloansAndDebtBurden from '../../images/exhibit/WhatIsMicrofinance/MicroloansAndDebtBurden.png';
import GroupLendingPressure from '../../images/exhibit/WhatIsMicrofinance/GroupLendingPressure.png';
import InadequateBusinessReturns from '../../images/exhibit/WhatIsMicrofinance/InadequateBusinessReturns.png';
import SocialAndPsychologicalStress from '../../images/exhibit/WhatIsMicrofinance/SocialAndPsychologicalStress.png';
import MisallocationOfLoans from '../../images/exhibit/WhatIsMicrofinance/MisallocationOfLoans.png';
import ExacerbationOfInequality from '../../images/exhibit/WhatIsMicrofinance/ExacerbationOfInequality.png';
import ExploitativePractices from '../../images/exhibit/WhatIsMicrofinance/ExploitativePractices.png';
import BenefitsOfMicrofinance from '../../images/exhibit/WhatIsMicrofinance/BenefitsOfMicrofinance.png'

const WhatIsMicrofinance = () => {


        //slide show animation
        const [slideIndex, setSlideIndex] = useState(1);
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            const fetchData = async () => {
                setLoading(true); 
                await new Promise(resolve => setTimeout(resolve, 500)); 
                setLoading(false); 
            };
        
            fetchData();
          }, []);

        const plusSlides = (n) => {
            setSlideIndex(prevIndex => {
                let newIndex = prevIndex + n;
                if (newIndex > 5) newIndex = 1;
                if (newIndex < 1) newIndex = 5;
                return newIndex;
            });
        }
    
        const currentSlide = (n) => {
            setSlideIndex(n);
        }
    
        useEffect(() => {
            const slides = document.getElementsByClassName("what-is-mfs-mySlides");
            const dots = document.getElementsByClassName("what-is-mfs-dot");

           if (slides.length>0 && dots.length>0) {
                for (let i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";
                }
                slides[slideIndex - 1].style.display = "block";

                for (let i = 0; i < dots.length; i++) {
                    dots[i].className = dots[i].className.replace(" what-is-mfs-active", "");
                }
                dots[slideIndex - 1].className += " what-is-mfs-active";
           }
        }, [slideIndex]);

        if (loading) {
            return <Loading />;  
        }

    return (
        <div className='what-is-mfs'>
            <h1 className='what-is-mfs-h1'>What Is Microfinance?</h1>
            <p className='what-is-mfs-p'>
            <strong>Microfinance:</strong> refers to a range of financial services aimed at low-income individuals (overwhelmingly women) that lack access to traditional banking systems. This includes microcredit (small loans), savings accounts, microinsurance, and payment systems. The primary goal of microfinance is to promote financial inclusion and empower marginalised populations based on the argument that this helps achieve economic self-sufficiency and improve their living standards.
            </p>
        
            
            {/* slideshow */}
            <div className="what-is-mfs-slideshow-container">

                <div className="what-is-mfs-mySlides what-is-mfs-fade" style={{ display: slideIndex === 1 ? "block" : "none" }}>
                    <h2>Key Features of Microfinance</h2>
                    <div className='what-is-mfs-mySlides-div'>
                        <div className='what-is-mfs-mySlides-div-text-pink'>
                            <p className='what-is-mfs-mySlides-div-text-header'><strong>Microloans</strong></p>
                            <p>These are small loans, typically ranging from $50 to $50,000, designed to help individuals start or expand small businesses. The loans are often provided without the need for traditional collateral, relying instead on group lending models or community ties to ensure repayment.</p>
                        </div>
                        <div className='what-is-mfs-mySlides-div-img'>
                            <img src={Microloans} alt='Microloans'></img>
                        </div>
                    </div>
                    <div className='what-is-mfs-mySlides-div'>
                        <div className='what-is-mfs-mySlides-div-img'>
                            <img src={FinancialServices} alt='Financial Services'></img>
                        </div>
                        <div className='what-is-mfs-mySlides-div-text-blue'>
                            <p className='what-is-mfs-mySlides-div-text-header'><strong>Financial Services</strong></p>
                            <p>In addition to loans, microfinance institutions (MFIs) offer various services such as savings accounts, insurance products, and financial education. This holistic approach aims to equip clients with the necessary tools to manage their finances effectively and sustainably.</p>
                        </div>
                    </div>
                    <div className='what-is-mfs-mySlides-div'>
                        <div className='what-is-mfs-mySlides-div-text-pink'>
                            <p className='what-is-mfs-mySlides-div-text-header'><strong>Target Demographics</strong></p>
                            <p>Microfinance primarily serves low-income individuals, particularly women, who are often excluded from conventional financial systems. Evidence suggests that women are less likely to default on loans, making them a key focus for many MFIs.</p>
                        </div>
                        <div className='what-is-mfs-mySlides-div-img'>
                            <img src={TargetDemographics} alt='Target Demographics'></img>
                        </div>
                    </div>
                </div>


                <div className="what-is-mfs-mySlides what-is-mfs-fade">
                    <div className='what-is-msf-historical-context-header'style={{ display: slideIndex === 2 ? "block" : "none" }}>
                        <h2>Historical Context</h2>
                    </div>

                    <div className='what-is-msf-historical-context'>
                        <p>The concept of microfinance gained prominence in the 1980s, particularly through the work of Muhammad Yunus and the establishment of Grameen Bank in Bangladesh. Yunus's model demonstrated that providing small loans to the poor could help them generate income and improve their livelihoods. His efforts were recognised with the Nobel Peace Prise in 2006, highlighting the prominence that microfinance as a development concept gained amongst powerful organisations such as the World Bank.</p>
                    </div>
                </div>

                <div className="what-is-mfs-mySlides what-is-mfs-fade" style={{ display: slideIndex === 3 ? "block" : "none" }}>

                    <img src={BenefitsOfMicrofinance} alt="Benefits of Microfinance" className='what-is-mfs-slide-image'/>
                </div>

                <div className="what-is-mfs-mySlides what-is-mfs-fade" style={{ display: slideIndex === 4 ? "block" : "none" }}>
                    <div className='what-is-msf-challenges-header'>
                        <div className='what-is-msf-challenges-blob'></div>
                        <h2>Challenges and Criticisms of Microfinance </h2>
                    </div>

                    <div className='what-is-msf-challenges'>
                        <p>However, microfinance has faced increasing criticism. Some argue that it can lead to over-indebtedness among borrowers, particularly if interest rates are high or if borrowers take on multiple loans from different sources. Additionally, while microfinance aims to alleviate poverty, studies have shown mixed results regarding its impact on income levels and economic mobility.
                        In summary, microfinance represents a significant effort to provide financial services to underserved populations, with the potential to foster economic development and improve quality of life. However, careful implementation and regulation are necessary to mitigate risks associated with over-indebtedness and ensure that the benefits reach those who need them most.</p>
                    </div>
                </div>              
                <div className="what-is-mfs-mySlides what-is-mfs-fade" style={{ display: slideIndex === 5 ? "block" : "none" }}>
                    <div className='what-is-mfs-negative-impacts-header'>
                        <h2>Negative Impacts of Microfinance</h2>
                    </div>

                    <div className='what-is-mfs-negative-impacts-p'>
                        <p>Microfinance can have negative effects on the lives of poor class women. These adverse outcomes are facilitated through various financial instruments and practices that, instead of empowering women, may further entrench them in cycles of debt and financial instability. Here is how this can happen:</p>
                    </div>

                    <div className='what-is-mfs-negative-impacts-view-1'>
                        <div className='what-is-mfs-negative-impacts-icon-1'>
                            <img src={MicroloansAndDebtBurden} alt='Microloans and Debt Burden'></img>
                        </div>
                        <div className='what-is-mfs-negative-impacts-section-1'>
                            <p><strong>Microloans and Debt Burden</strong><br />
                            Microloans can lead to significant debt burdens for poor women. High-interest rates and short repayment periods can make it difficult for women to keep up with repayments, particularly if their business ventures do not generate expected returns. As a result, women may take out additional loans to repay existing ones, leading to a cycle of debt.</p>
                        </div>
                    </div>         

                    <div className='what-is-mfs-negative-impacts-view-2'>
                        <div className='what-is-mfs-negative-impacts-section-2'>
                            <p><strong>Group Lending Pressure</strong><br />
                            Group lending relies on social collateral, where members of a borrowing group are collectively responsible for each other's loans. This can lead to intense social pressure and stress, especially if one member struggles to repay. The fear of letting down the group or facing social ostracism can force women to take extreme measures, including borrowing from informal moneylenders at even higher rates, to meet their obligations.</p>
                        </div>
                        <div className='what-is-mfs-negative-impacts-icon-2'>
                            <img src={GroupLendingPressure} alt='Group Lending Pressure'></img>
                        </div>
                    </div>

                    <div className='what-is-mfs-negative-impacts-view-1'>
                        <div className='what-is-mfs-negative-impacts-icon-1'>
                            <img src={InadequateBusinessReturns} alt='Inadequate Business Returns'></img>
                        </div>
                        <div className='what-is-mfs-negative-impacts-section-1'>
                            <p><strong>Inadequate Business Returns</strong><br />
                            The assumption that microloans will always lead to successful entrepreneurial ventures is often unrealistic. Many microfinance clients invest in saturated markets with low returns, leading to insufficient income to cover loan repayments and improve their living standards. This can result in increased financial stress and economic insecurity. Moreover, there are no adequate direction provided to women for how the money can be invested.</p>
                        </div>
                    </div>

                    <div className='what-is-mfs-negative-impacts-view-2'>
                        <div className='what-is-mfs-negative-impacts-section-2'>
                            <p><strong>Social and Psychological Stress</strong><br />
                            The burden of debt repayment can cause significant social and psychological stress. Women may experience anxiety, depression, and strain on family relationships due to the pressure of meeting financial obligations. The responsibility of debt can also reinforce traditional gender roles, where women are expected to shoulder the financial burden without adequate support.</p>
                        </div>
                        <div className='what-is-mfs-negative-impacts-icon-2'>
                            <img src={SocialAndPsychologicalStress} alt='Social and Psychological Stress'></img>
                        </div>
                    </div>

                    <div className='what-is-mfs-negative-impacts-view-1'>
                        <div className='what-is-mfs-negative-impacts-icon-1'>
                            <img src={MisallocationOfLoans} alt='Misallocation of Loans'></img>
                        </div>
                        <div className='what-is-mfs-negative-impacts-section-1'>
                            <p><strong>Misallocation of Loans</strong><br />
                            Sometimes, the loans intended for business purposes are diverted to meet immediate household needs, such as medical expenses or school fees, rather than being invested in income-generating activities. This misallocation can prevent women from achieving the financial gains needed to repay the loans and improve their economic situation.</p>
                        </div>
                    </div>

                    <div className='what-is-mfs-negative-impacts-view-2'>
                        <div className='what-is-mfs-negative-impacts-section-2'>
                            <p><strong>Exacerbation of Inequality</strong><br />
                            Microfinance can exacerbate existing social and economic inequalities. Women who are already slightly better off may benefit more from microfinance services than the poorest women, who may be unable to take advantage of these opportunities due to lack of adequate governmental services, access to education, skills, or resources. This can widen the gap between different socio-economic groups within the poor population.</p>
                        </div>
                        <div className='what-is-mfs-negative-impacts-icon-2'> 
                            <img src={ExacerbationOfInequality} alt='Exacerbation of Inequality'></img>
                        </div>
                    </div>

                    <div className='what-is-mfs-negative-impacts-view-1'>
                        <div className='what-is-mfs-negative-impacts-icon-1'>
                            <img src={ExploitativePractices} alt='Exploitative Practices'></img>
                        </div>
                        <div className='what-is-mfs-negative-impacts-section-1'>
                            <p><strong>Exploitative Practices</strong><br />
                            In some cases, microfinance institutions (MFIs) may engage in exploitative practices, such as aggressive lending, coercive repayment methods, and insufficient regulation. These practices can trap women in cycles of debt and financial dependency, rather than providing the intended support for economic empowerment. In extreme cases, there have been reports of suicides among borrowers unable to cope with the pressure of repayment, highlighting the severe psychological toll that microfinance can impose.</p>
                        </div>
                    </div>
                   
                    <div className='what-is-mfs-negative-impacts-p'>
                        <p>While microfinance aims to empower poor women by providing financial services, it can also have negative effects if not implemented and managed carefully. High debt burdens, social pressure, financial mismanagement, and exploitative practices can undermine the potential benefits, leading to financial and psychological distress. It is crucial for microfinance programs to incorporate robust safeguards, transparent practices, and comprehensive support systems to mitigate these negative impacts.</p>
                    </div>
                </div>
                <button className="what-is-mfs-prev" onClick={() => plusSlides(-1)}>❮</button>
                <button className="what-is-mfs-next" onClick={() => plusSlides(1)}>❯</button>
            </div>

            <br />

            <div className='what-is-mfs-dot-container'>
                <span className="what-is-mfs-dot" onClick={() => currentSlide(1)}></span> 
                <span className="what-is-mfs-dot" onClick={() => currentSlide(2)}></span> 
                <span className="what-is-mfs-dot" onClick={() => currentSlide(3)}></span> 
                <span className="what-is-mfs-dot" onClick={() => currentSlide(4)}></span> 
                <span className="what-is-mfs-dot" onClick={() => currentSlide(5)}></span> 
            </div>

        </div>
    );
};

export default WhatIsMicrofinance;
