import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/moderator/ModContactOverview.css';
import { formatDateTime } from '../../components/FormatFunctions'; // import function to format date time
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModContactOverview = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const navigate = useNavigate(); 

    const handleClick = (contactID) => { // redirect to individual contact form
        navigate(`/reported-contact/${contactID}`, { state: { isPending: true }});
    };

    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getContactForms = async () => {
            try {
                console.log(`getting contact forms data`);
                const response = await axios.get(`${_URL}/reported-contact`);
                setForms(response.data);
            } catch (error) {
                console.error('error getting contact forms:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Contact Forms',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        getContactForms();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="reportedcontact-container">
            <h1>Contact Us</h1>
            <div className='reportedcontact-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Date / Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {forms.map((contact, index) => (
                            <tr key={contact.contactID} onClick={() => handleClick(contact.contactID)}>
                                <td className='contact-category'>{contact.category}</td>
                                <td className='contact-desc'>{contact.desc}</td>
                                <td className='contact-datetime'>{formatDateTime(contact.datetime)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ModContactOverview;
