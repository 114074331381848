
import { Link, useNavigate } from "react-router-dom";
import {useState, useEffect} from 'react';
// import css
import '../styles/Landing.css' 

// import WillowSphere logo
import logo from '../images/logo.jpg' 

import { useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import Loading from '../components/Loading';

function Landing() {

    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const handleSignUp = () => {
        navigate('/signup')
    }

    // additional resources li
    const usefulLinks = [{id: 1, name: 'Additional Resources', url: '/additional-resources'}, 
    {id: 2, name: 'FAQs', url: '/faq'}]

    // contact us li
    const contacts = [{id: 1, name: 'Contact Us Form', url: '/contact-us'}]

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await new Promise(resolve => setTimeout(resolve, 500)); 
            setLoading(false); 
        };
  
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;  
    }

    return (
        <div>
            <div className='landing'>     
                <div className='content'>
                    <p>Welcome to WillowSphere—your empowering companion in navigating the complex world of microfinance. </p>
                    {/* Show Sign Up button only if the user is NOT logged in */}
                    {!auth && <button onClick={handleSignUp}>Sign up</button>}
                </div>           
                <div>
                    {/* WillowSphere logo */}
                    <img src={logo} alt='WillowSphere Logo'width="500" height="500"></img>
                </div>
            </div>
            
            {/* about us */}
            <div className='outline-landing'>
                <div className='title'>
                    <h1><Link to='/about-us'>About Us</Link></h1>
                    <p>Click <Link to='/about-us'>here</Link> to read more about us.</p>
                </div> 
                <div className='description'> 
                    <p>WillowSphere is an initiative aimed at addressing the complex challenges women face
                         with microfinance. The app seeks to provide a holistic overview of microfinance by
                          offering essential tools and platforms for women to understand and navigate the true costs and implications of these financial services. 
                    </p> 
                </div>
            </div>
            
            {/* terms of use and policies */}
            <div className='outline-landing'>
                <div className='title'>
                    <h1><Link to='/terms-of-use-and-policies'>Terms of Use and Policies</Link></h1>
                    <p>Click <Link to='/terms-of-use-and-policies'>here</Link> to learn more about them.</p>
                    </div>
                <div className='description'>
                    <p>WillowSphere's Terms of Use and Policies require users to be at least 18 years old, provide accurate registration
                         information, and maintain the security of their accounts. Prohibited activities include harassment, unauthorized
                          access, and misuse of the app, with users expected to comply with all applicable laws. By sharing content on the
                           platform, users grant WillowSphere a license to use, reproduce, and distribute it, and the company reserves the
                            right to remove any inappropriate material. WillowSphere limits its liability and requires users to indemnify 
                            the company against any claims arising from their use of the app. The terms may be updated without notice, and
                             access can be terminated at any time without prior warning. The agreement is governed by specific local laws,
                              and disputes will be resolved in local courts within that jurisdiction.
                    </p> 
                </div>
            </div>

            {/* footer */}
            <footer className="footer">
                <div className="footer-content">
                    
                    {/* left side of footer */}
                    <div className='footer-left'>
                        <Link to='/'>WillowSphere</Link>
                    </div>

                    {/* right side of footer */}
                    <div className="footer-right">
                        <div>
                            <h2>Useful Links</h2>
                            <ul>
                                {usefulLinks.map((link) => (
                                <li key={link.id}>
                                    <Link to={link.url}>{link.name}</Link>
                                </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h2>Contact Us</h2>
                            <ul>
                                {contacts.map((contact) => (
                                <li key={contact.id}>
                                    <Link to={contact.url}>{contact.name}</Link>
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default Landing;
