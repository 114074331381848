import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import '../styles/MyPostOverview.css';
import AuthContext from '../context/AuthProvider';
import AxiosIntercept from '../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const MyPostOverview = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const {id} = useParams();
    const [myPosts, setMyPosts] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);

    const statusCategories = ['Published', 'Pending', 'Rejected'];
    const loanCategories = ['Payday Loan', 'Short Term Loan', 'Microcredit Loan'];

    useEffect(() => {
        const getMyPosts = async () => {
            try {
                const response = await axios.get(`${_URL}/myposts/${id}`);
                setMyPosts(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('error getting my posts:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get My Posts',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            } finally {
                setLoading(false);
            }
        };

        getMyPosts();
    }, [id]);

    const handleSearch = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchText(searchValue);

        const filteredMyPosts = myPosts.filter(post =>
            post.postTitle.toLowerCase().includes(searchValue) ||
            post.postDesc.toLowerCase().includes(searchValue)
        );
        setMyPosts(filteredMyPosts);
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleFilter = (type, value) => {
        let filteredPosts = myPosts;

        if (type === 'category') {
            if (value === 'All') {
                filteredPosts = myPosts;
            } else {
                filteredPosts = myPosts.filter(post => post.postCategory === value);
            }
        } else if (type === 'status') {
            if (value === 'All') {
                filteredPosts = myPosts;
            } else {
                filteredPosts = myPosts.filter(post => post.postStatus === value);
            }
        }

        setMyPosts(filteredPosts);
        setShowDropdown(false);
    };

    const truncateMyPost = (text, limit) => {
        const words = text.split(' ');
        if (words.length > limit) {
            return words.slice(0, limit).join(' ') + '...';
        }
        return text;
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Published':
                return '#BEF2B5';
            case 'Pending':
                return '#FDD5B0';
            case 'Rejected':
                return '#DEDFDF';
            default:
                return '#FFFFFF';
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="my-post-container">
            <div className={`my-post-title ${showDropdown ? 'show' : ''}`}>
                <h1><strong>My Posts</strong></h1>
                <input
                    type="text"
                    className="my-post-search-bar"
                    placeholder="Search bar"
                    onChange={handleSearch}
                />
                <div className={`my-post-filter-container ${showDropdown ? 'show' : ''}`}>
                    <button className="my-post-filter-button" onClick={toggleDropdown}>
                        Filter
                    </button>
                    <div className={`my-post-filter-dropdown ${showDropdown ? 'show' : ''}`}>
                        <ul>
                            <li className="my-post-filter-dropdown-item">
                                <button className="my-post-filter-dropdown-link" onClick={() => handleFilter('category', 'All')}>All</button>
                            </li>
                            <li className="my-post-filter-dropdown-item">
                                <strong>Post Status</strong>
                                {statusCategories.map(status => (
                                    <button key={status} className="my-post-filter-dropdown-link" onClick={() => handleFilter('status', status)}>{status}</button>
                                ))}
                            </li>
                            <li className="my-post-filter-dropdown-item">
                                <strong>Type of Loan</strong>
                                {loanCategories.map(category => (
                                    <button key={category} className="my-post-filter-dropdown-link-category" onClick={() => handleFilter('category', category)}>{category}</button>
                                ))}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {myPosts.map(mypost => (
                <Link key={mypost.postID} to={`/mypost/${mypost.postID}`} className="my-post-link">

                    <div className="my-post-box">
                        <div className="my-post-content">
                            <div className="my-post-content-header">
                                <h2>{mypost.postTitle}</h2>
                            </div>
                            <p className="my-post-author-category">
                                <strong>{mypost.authorInfo.username}</strong> | <strong>{mypost.postCategory}</strong>

                            </p>
                            <p className="my-post-description">{truncateMyPost(mypost.postDesc, 25)}</p>
                        </div>
                        <div className="my-post-status-container">
                            <span 
                                className="my-post-status" 
                                style={{ backgroundColor: getStatusColor(mypost.postStatus) }}
                            >
                                {mypost.postStatus}
                            </span>
                        </div>
                    </div>
                </Link>
            ))}
            <Link to="/new-forum-post">
                <button className="my-post-new-post-button">New Post</button>
            </Link>
        </div>
    );
};

export default MyPostOverview;
