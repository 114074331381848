import { useParams, useNavigate } from 'react-router-dom';
import '../styles/NarrativeDetail.css';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthProvider';
import AxiosIntercept from '../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const NarrativeDetail = () => {
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    if (auth !== null) {
        axios.defaults.withCredentials = true;
        const role = auth.userRole;
        // handle jwt refresh
        AxiosIntercept(userID, role);
    }

    const { id } = useParams();
    const navigate = useNavigate();
    const [narrative, setNarrative] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getIndivNarrative = async () => {
            try {
                console.log(`getting reported comments`);
                const response = await axios.get(`${_URL}/narratives/${id}`);
                setNarrative(response.data);
            } catch (error) {
                console.error('error getting reported comments:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Reported Comments',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            } finally {
                setLoading(false);
            }
        };
        getIndivNarrative();
    }, [id]);

    const handleEdit = () => {
        navigate(`/edit-narrative/${id}`, { state: { narrative, isEdit: true } });
    };

    const handleDelete = async () => {
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure you want to delete this narrative?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                confirmButton: 'swal2-confirmbutton-custom',
                cancelButton: 'swal2-cancelbutton-custom',
            },
        });
    
        if (isConfirmed) {
            try {
                await axios.delete(`${_URL}/narratives/${id}`);
                await Swal.fire({
                    title: 'Narrative deleted successfully.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom',
                        confirmButton: 'swal2-confirmbutton-custom',
                    }
                });
                navigate('/narratives'); // Navigate to the narratives overview page after deletion
            } catch (error) {
                console.error('Error deleting narrative:', error);
                await Swal.fire({
                    title: 'Error: \nFailed to delete the narrative.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            }
        }
    };

    if (loading) {
        return <Loading />;
    }

    // Split full description into paragraphs with \n
    const paragraphs = narrative.narrDesc.split('\n').map((text, index) => (
        <p key={index}>{text}</p>
    ));

    return (
        <div className="narrative-detail">
            <div className='narrative-detail-head'>
                <h2>{narrative.narrTitle}</h2>
                {auth && auth.userRole === 'moderator' && ( // if userRole is 'moderator'
                <div>
                    <button className='narr-edit-delete' onClick={handleEdit}>Edit</button>
                    <button className='narr-edit-delete' onClick={handleDelete}>Delete</button>
                </div>
                )}
            </div>
            <p className="disclaimer"><strong>Disclaimer: This narrative is an account of a real person. The names of these people are pseudonyms for anonymity purposes.</strong></p>
            <p><strong>Category:</strong> {narrative.narrCategory}</p>
            <p><strong>Occupation:</strong> {narrative.narrOccupation}</p>
            {/* Render paragraphs of full description */}
            {paragraphs}
        </div>
    );
};

export default NarrativeDetail;
