import React, { useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import AuthContext from '../context/AuthProvider';
import '../styles/components/Navigation.css';

function Nav() {
  const { auth } = useContext(AuthContext);
  const address = useLocation();

  const isActive = (path) => address.pathname === path;
  const userID = auth ? String(auth.userID) : null; // set userID to null if undefined

  return (
    <nav>
      <div className='nav-outline'>
        <div className='nav-left'>
          <Link to='/' className={isActive('/') ? 'active' : ''}>WillowSphere</Link>
        </div>

        <div className='nav-right'>
          <ul>
            <li><Link to='/about-us' className={isActive('/about-us') ? 'active' : ''}>About Us</Link></li>
            <li><Link to='/calculator' className={isActive('/calculator') ? 'active' : ''}>Calculator</Link></li>
            <li>
              <Link 
                to='/narratives'
                className={isActive('/narratives') || address.pathname.includes('/narratives/') ? 'active' : ''}
              >
                Narratives
              </Link>
            </li>
            <li>
              <Link 
                to='/forum'
                className={isActive('/forum') || address.pathname.includes('/forum/') || address.pathname.includes('/new-forum-post') ? 'active' : ''}
              >
                Forum
              </Link>
            </li>
            <li><Link to='/exhibit' className={isActive('/exhibit') || address.pathname.includes('/exhibit/') ? 'active' : ''}>Exhibit</Link></li>
            <li className='dropdown'>
              <span className='dropdown-list'>
              <Link 
                  to={userID ? `/account/${userID}` : '/login'} // redirect to login if userID is null
                  className={isActive(`/account/${userID}`) || address.pathname.includes('/account/') ? 'active' : ''}
                >
                  Account
                </Link>
              </span>

              <div className='dropdown-content'>
                {!auth ? ( // if userID is null
                  <>
                    <Link to='/login' className={isActive('/login') ? 'active' : ''}>Login</Link>
                  </>
                ) : auth.userRole === 'moderator' ? ( // if userRole is 'moderator'
                  <>
                    <Link to='/myview' className={isActive('/myview') ? 'active' : ''}>My View</Link>
                    <Link to={`/account/${userID}`} className={isActive(`/account/${userID}`) ? 'active' : ''}>My Account</Link>
                    <Link to={`/myposts/${userID}`} className={isActive(`/myposts/${userID}`) ? 'active' : ''}>My Posts</Link>
                    <Link to={`/signout/${userID}`} className={isActive(`/signout/${userID}`) ? 'active' : ''}>Sign Out</Link>
                  </>
                ) : ( // if userRole is 'user'
                  <> 
                    <Link to={`/account/${userID}`} className={isActive(`/account/${userID}`) ? 'active' : ''}>My Account</Link>
                    <Link to={`/myposts/${userID}`} className={isActive(`/myposts/${userID}`) ? 'active' : ''}>My Posts</Link>
                    <Link to={`/signout/${userID}`} className={isActive(`/signout/${userID}`) ? 'active' : ''}>Sign Out</Link>
                  </>
                )}
              </div>
            </li>
          </ul>
        </div>
        <hr />
      </div>
    </nav>
  );
}

export default Nav;
