//ForumDetail.js
import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../context/AuthProvider';
import axios from 'axios';
import { useParams, useNavigate , Link } from 'react-router-dom';
import '../styles/ForumDetail.css';
import { GoPaperAirplane } from "react-icons/go";
import { GoAlert } from "react-icons/go";
import { anonymousUser, formatDateTime, checkAge, checkActiveUserComment } from '../components/FormatFunctions';
import AxiosIntercept from '../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const ForumDetail = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [loading, setLoading] = useState(true); // Add loading state

    const handleReportPost = (url) => {
        navigate(`/report-post/${url}`);  // navigate to report post
    };

    const handleReportComment = (url) => {
        navigate(`/report-comment/${url}`); // navigate to report comment
    };

 
    const filterComments = (comments) => { // filter emprty {} and if cmtStatus is Rejected
        return comments.filter(comment => 
            Object.keys(comment).length > 0 && comment.cmtStatus !== 'Rejected'
        );
    };

    const handleCommentSubmit =  async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${_URL}/forum/${id}`, {cmtAuthor: userID, cmtDesc: newComment});
            //   navigate(`/forum/${response.data.postID}`);
            if (response.data) {
                Swal.fire({
                    title: 'Comment successful.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom',
                        confirmButton: 'swal2-confirmbutton-custom'
                    }
                });
                window.location.reload();
            }
        } 
        catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                Swal.fire({
                    title: 'Error: \n' + err.response.data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } 
            else {
                Swal.fire({
                    title: 'Error: \n Create post failed',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            }
        }
    };
    
    useEffect(() => {
        const getIndivPost = async () => {
            try {
                console.log(`getting forum post`);
                const response = await axios.get(`${_URL}/forum/${id}`);
                setPost(response.data[0]); 
                setComments(filterComments(response.data[0].comments));
            } catch (error) {
                console.error('error getting forum post:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Forum Post',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        getIndivPost();
    }, [id]);

    if (loading) {
        return <Loading />;  
    }

    return (
        <div className="forum-detail-container">
            <div className="title-container">
                <h1>{post.postTitle}</h1>
                <button className="report-post-button" onClick={() => handleReportPost(post.postID)}>Report</button>
            </div>
            <div className='desc'>
                <p><strong>{anonymousUser(post.postAnon, post.postUser)} {checkAge(post.postAge)} | {post.postCategory}</strong></p>
                <p>{post.postDesc}</p>
            </div>
            <div className="comment-section">
                <div className="comment-input-header">
                    <span><strong>Comments</strong></span>
                </div>

                <form className="comment-form" onSubmit={handleCommentSubmit}>
                    <div className="comment-input-container">
                        <button type="submit" className="submit-comment-button" onClick={handleCommentSubmit}>
                            <GoPaperAirplane />
                        </button>
                        <div className="input-with-icon">
                            <input 
                                type="text"
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                placeholder="Write a new comment"
                                className="comment-input"
                            />
                        </div>
                    </div>
                </form>

                <div className="rules-reminder">
                    <span><strong>Keep in mind the</strong></span>
                    <p>
                    <Link to="/rules-of-engagement" className="rules-link">
                        RULES OF ENGAGEMENT
                    </Link>
                    </p>
                </div>
                {comments.length > 0 ? (
                    <div className="comments-container">
                        <div className="comments-container">
                            <div className="comments-list">
                                {comments.map((comment, index) => (
                                    <div key={index} className="comment">
                                        <div className="comment-header">
                                            <button className="report-comment" onClick={() => handleReportComment(comment.cmtID)}>
                                                <GoAlert className="report-icon" />
                                            </button>
                                            <span><strong>{checkActiveUserComment(comment.cmtActiveUser, comment.cmtAuthorUsername)}</strong></span>
                                        </div>
                                        <div>
                                            <span><strong>{formatDateTime(comment.cmtDateTime)}</strong></span>
                                        </div>
                                        <span>{comment.cmtDesc}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="comments-container">
                        <div className="comments-list">
                                <div className="comment">
                                    <div className="comment-header">
                                        <span>There are no comments.</span>
                                    </div>
                                    <div>
                                        <span>Leave a comment to start a conversation!</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                )}
                </div>
            </div>
    );
};

export default ForumDetail;
