// App.js 
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from './context/AuthProvider';
import RestrictedRoute from './components/RestrictedRoutes';
import { MantineProvider } from '@mantine/core'; // Import MantineProvider

// import css
import './styles/App.css';

// import relevant page links
import Nav from './components/Navigation'; // navigation bar
import Landing from './pages/Landing'; // landing page
import Calculator from './pages/Calculator'; // calculator page
import AboutUs from './pages/AboutUs'; // about page
import NarrativeOverview from './pages/NarrativeOverview'; // NarrativeOverview component
import NarrativeDetail from './pages/NarrativeDetail'; // NarrativeDetail component
import ForumOverview from './pages/ForumOverview'; 
import ForumDetail from './pages/ForumDetail';
import NewForumPost from './pages/NewForumPost'; 
import Login from './pages/Login'; // login page
import SignUp from './pages/SignUp'; // create account / sign up page
import ForgotPassword from './pages/ForgotPassword'; // forget password page
import UserAccount from './pages/UserAccount'; // user account
import MyPostOverview from './pages/MyPostOverview'; // my post overview
import MyPostDetail from './pages/MyPostDetail'; // my post detail
import RulesOfEngagement from './pages/RulesOfEngagement'; // rules of engagement
import Terms from './pages/Terms'; // terms of use and policies
import ForumReportPost from './pages/ForumReportPost'; // report forum post page
import ForumReportComment from './pages/ForumReportComment'; // report forum comment page
import FAQs from './pages/FAQs'; // Import FAQs page
import Exhibit from './pages/graph/Exhibit';
import Resource from './pages/Resource'; // Import resource page
import ContactUs from './pages/ContactUs';
import ResetPassword from './pages/ResetPassword';

import ModLanding from './pages/moderator/ModLanding'; // moderator landing page
import ModReportedPostsOverview from './pages/moderator/ModReportedPostsOverview'; // moderator reported post overview
import ModReportedPosts from './pages/moderator/ModReportedPosts';
import ModReportedCmtsOverview from './pages/moderator/ModReportedCmtsOverview'; // moderator reported comment overview
import ModReportedCmts from './pages/moderator/ModReportedCmts'; 
import ModContactOverview from './pages/moderator/ModContactOverview'; // moderator contact us overview
import ModContact from './pages/moderator/ModContact';
import ModNewNarrative from './pages/moderator/ModNewNarrative';  
import ModLogin from './pages/moderator/ModLogin';
import ModReviewNewPostOverview from './pages/moderator/ModReviewNewPostOverview';
import ModReviewNewPost from './pages/moderator/ModReviewNewPost';
import ModUserManagement from './pages/moderator/ModUserManagement';
import ModArchivedUserPostsOverview from './pages/moderator/ModArchivedUserPostsOverview';
import ModArchivedUserPosts from './pages/moderator/ModArchivedUserPosts';
import ModArchivedContactOverview from './pages/moderator/ModArchivedContactOverview';
import ModArchivedPostReportsOverview from './pages/moderator/ModArchivedPostReportsOverview';
import ModArchivedCmtsReportsOverview from './pages/moderator/ModArchivedCmtsReportsOverview';
import ModNewModeratorAccount from './pages/moderator/ModNewModeratorAccount';

import SignOut from './components/SignOut';

import FinancialisationOfDailyLives from './pages/graph/FinancialisationOfDailyLives';
import Neoliberalism from './pages/graph/Neoliberalism';
import MicrofinanceWomenLives from './pages/graph/MicrofinanceWomenLives';
import WhatIsMicrofinance from './pages/graph/WhatIsMicrofinance';

import NotFound from './components/NotFound'; // NotFound404



function App() {

  return ( 
    <div>
          <Nav />

            <Routes>
              <Route path='/' element={<Landing />}/>
              <Route path='/about-us' element={<AboutUs />}/>
              <Route path='/calculator' element={<Calculator />}/>
              <Route path="/narratives" element={<NarrativeOverview />} />
              <Route path="/narratives/:id" element={<NarrativeDetail />} />  
              <Route path="/rules-of-engagement" element={<RulesOfEngagement />} />

              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path='/terms-of-use-and-policies' element={<Terms />} />
              <Route path='/faq' element={<FAQs />} />
              <Route path='/additional-resources' element={<Resource />} />
              <Route path="/contact-us" element={<ContactUs />} /> 

              <Route path='/exhibit' element={<Exhibit />} /> 
              <Route path="/exhibit/what-is-financialisation-of-daily-life" element={<FinancialisationOfDailyLives />} />
              <Route path="/exhibit/what-is-neoliberalism-and-its-relation-to-financialisation" element={<Neoliberalism />} />
              <Route path="/exhibit/how-does-microfinance-financialise-the-daily-lives-of-women" element={<MicrofinanceWomenLives />} />
              <Route path="/exhibit/what-is-microfinance" element={<WhatIsMicrofinance />} />

              <Route path="/reset-password" element={<RestrictedRoute element={<ResetPassword />} />}/>
              <Route path="/forum" element={<RestrictedRoute element={<ForumOverview />} />} />
              <Route path="/forum/:id" element={<RestrictedRoute element={<ForumDetail />} />} />
              <Route path="/new-forum-post" element={<RestrictedRoute element={<NewForumPost />} />} />
              <Route path="/update-forum-post/:id" element={<RestrictedRoute element={<NewForumPost />} />} />
              <Route path="/report-post/:id" element={<RestrictedRoute element={<ForumReportPost />} />}/>
              <Route path="/report-comment/:id" element={<RestrictedRoute element={<ForumReportComment />} />}/>
              <Route path='/account/:id' element={<RestrictedRoute element={<UserAccount />} />} />
              <Route path='/delete-account/:id' element={<RestrictedRoute element={<UserAccount />} />} />
              <Route path="/mypost/:id" element={<RestrictedRoute element={<MyPostDetail />} />} />
              <Route path="/myposts/:id" element={<RestrictedRoute element={<MyPostOverview />} />} />

              <Route path="/signout/:id" element={<SignOut />} />  


              <Route path="/moderator/login" element={<ModLogin />} />   
              <Route path='/myview' element={<RestrictedRoute element={<ModLanding />} />}/>
              <Route path='/reported-posts' element={<RestrictedRoute element={<ModReportedPostsOverview />} />}/>
              <Route path='/reported-posts/:id' element={<RestrictedRoute element={<ModReportedPosts />} />}/>
              <Route path='/reported-posts/:id/approve' element={<RestrictedRoute element={<ModReportedPosts />} />}/>
              <Route path='/reported-posts/:id/post' element={<RestrictedRoute element={<ModReportedPosts />} />}/>
              <Route path='/reported-posts/:id/reject' element={<RestrictedRoute element={<ModReportedPosts />} />}/>
              <Route path='/reported-comments' element={<RestrictedRoute element={<ModReportedCmtsOverview />} />}/>
              <Route path='/reported-comments/:id' element={<RestrictedRoute element={<ModReportedCmts />} />}/>
              <Route path='/reported-comments/:id/approve' element={<RestrictedRoute element={<ModReportedCmts />} />}/>
              <Route path='/reported-comments/:id/comment' element={<RestrictedRoute element={<ModReportedCmts />} />}/>
              <Route path='/reported-comments/:id/reject' element={<RestrictedRoute element={<ModReportedCmts />} />}/>
              <Route path='/reported-contact' element={<RestrictedRoute element={<ModContactOverview />} />}/>
              <Route path="/add-new-narrative" element={<RestrictedRoute element={<ModNewNarrative />} />}/> 
              <Route path="/edit-narrative/:id" element={<RestrictedRoute element={<ModNewNarrative />} />}/> 
              <Route path="/create-new-moderator" element={<RestrictedRoute element={<ModNewModeratorAccount />} />}/> 



              <Route path="/reported-contact/:id" element= {<RestrictedRoute element={<ModContact />} />} />
              <Route path="/review-new-posts" element={<RestrictedRoute element={<ModReviewNewPostOverview />} />} />
              <Route path="/review-new-posts/:id" element={<RestrictedRoute element={<ModReviewNewPost />} />} />   
              <Route path="/review-new-posts/:id/approve" element={<RestrictedRoute element={<ModReviewNewPost />} />} />   
              <Route path="/review-new-posts/:id/reject" element={<RestrictedRoute element={<ModReviewNewPost />} />} />   

              <Route path="/user-management" element={<RestrictedRoute element={<ModUserManagement />} />}/> 
              <Route path="/archived-user-posts" element={<RestrictedRoute element={<ModArchivedUserPostsOverview />} />}/> 
              <Route path="/archived-user-posts/:id" element={<RestrictedRoute element={<ModArchivedUserPosts />} />}/> 
              <Route path="/archived-contact" element={<RestrictedRoute element={<ModArchivedContactOverview />} />}/> 
              <Route path="/archived-contact/:id" element={<RestrictedRoute element={<ModContact />} />}/> 
              <Route path="/archived-post-reports" element={<RestrictedRoute element={<ModArchivedPostReportsOverview />} />}/> 
              <Route path="/archived-post-reports/:id" element={<RestrictedRoute element={<ModReportedPosts />} />}/> 
              <Route path="/archived-comment-reports" element={<RestrictedRoute element={<ModArchivedCmtsReportsOverview />} />}/> 
              <Route path="/archived-comment-reports/:id" element={<RestrictedRoute element={<ModReportedCmts />} />}/> 
              
              <Route path="/auth/google" element={<Login />} /> 
              <Route path="*" element={<NotFound />} />
            </Routes>

    </div>

  );
}

export default App;
