import React, { useState, useEffect, useContext } from 'react';
import { useParams} from 'react-router-dom';
import axios from 'axios';
import '../../styles/moderator/ModArchivedUserPosts.css'
import { formatDateTime } from '../../components/FormatFunctions';
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModArchivedUserPosts = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const { id } = useParams();

    const [archivedPost, setArchivedPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);


    // const filterComments = (comments) => { // filter empty {} and if cmtStatus is Rejected
    //     return comments.filter(comment =>
    //         Object.keys(comment).length > 0 && comment.cmtStatus !== 'Rejected'
    //     );
    // };

    const filterComments = (comments) => { // filter empty {} and if cmtStatus is Rejected
        return comments.filter(comment => Object.keys(comment).length > 0);
    };

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`${_URL}/archived-user-posts/${id}`);
                if (response.data[0]) {
                    console.log(response.data[0]);
                    setArchivedPost(response.data[0]);
                    setComments(filterComments(response.data[0].comments));
                } else {
                    Swal.fire({
                        title: 'Error: \nArchived Forum Post not found',
                        icon: 'error',
                        showConfirmButton: false,
                        customClass: {
                            popup: 'swal2-popup-custom',
                            title: 'swal2-title-custom'
                        }
                    });
                }
            } catch (error) {
                console.error('Error fetching archived post details:', error);
                Swal.fire({
                    title: 'Error: \nFailed to fetch Archived Forum Post Details',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        fetchPost();
    }, [id]); 

    if (loading) {
        return <Loading />;
    }

    if (!archivedPost) {
        return <div>Post not found.</div>;
    }

    return (
        <div className="archived-post-container">
            <div className="archived-post-title-container">
                <h1>{archivedPost.postTitle}</h1>
                <span className="archived-post-status">
                    Archived
                </span>
            </div>
            <div className='archived-post-desc'>
                <p><strong>{archivedPost.postUser}</strong> | <strong>{archivedPost.postCategory}</strong></p>

                <p>{archivedPost.postDesc}</p>
            </div>
            <div className="archived-post-comment-section">
                <div className="archived-post-comment-input-header">
                    <span><strong>Comments</strong></span>
                </div>
                {comments.length > 0 ? (
                    <div className="archived-post-comments-container">
                        <div className="archived-post-comments-list">
                        {comments.map((comment, index) => (
                            <div key={index} className="archived-post-comment">
                                <div className="archived-post-comment-header">
                                    <span><strong>{comment.cmtAuthorUsername}</strong></span>
                                </div>
                                <div className='archived-post-datetime'>
                                    <span><strong>{formatDateTime(comment.cmtDateTime)}</strong></span>
                                </div>
                                <span className='archived-post-comment-desc'>{comment.cmtDesc}</span>
                                {comment.cmtStatus === "Rejected" && (
                                    <div><span style={{color: "#FF6969"}}>This is a reported comment. Report was Approved.</span></div>
                                )}
                            </div>
                        ))}
                        </div>
                    </div>
                ) : (
                    <div className="archived-post-comments-container">
                        <div className="archived-post-comments-list">
                            <div className="archived-post-comment">
                                <div className="archived-post-comment-header">
                                    <span>There are no comments.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <br />
            <br />
            <p><strong>Post Created Date: {formatDateTime(archivedPost.created)}</strong></p>
            <p><strong>Post Archived (Deleted) Date: {formatDateTime(archivedPost.archived)}</strong></p>
            <br />
        </div>
    );
};

export default ModArchivedUserPosts;
