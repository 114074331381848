import React, { useState, useEffect, useContext }from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/moderator/ModArchivedUserPostsOverview.css';
import { formatDateTime } from '../../components/FormatFunctions';
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModArchivedUserPostsOverview = () => {

    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);
    
    const [archivedPosts, setArchivedPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleClick = (id) => {
        navigate(`/archived-user-posts/${id}`);
    };

    useEffect(() => {
        const getArchivedPosts = async () => {
            try {
                console.log(`getting all archived posts`);
                const response = await axios.get(`${_URL}/archived-user-posts`);

                setArchivedPosts(response.data); 
            } catch (error) {
                console.error('error getting archived user posts:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Archived User Posts',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        getArchivedPosts();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="mod-archived-post-overview-con">
            <h2>Archived User Posts</h2>
            <table className="archived-user-posts-table">
                <thead>
                    <tr>
                        <th><strong>Post Title</strong></th>
                        <th><strong>Post Category</strong></th>
                        <th><strong>Post Author</strong></th>
                        <th><strong>Created Date</strong></th>
                        <th><strong>Deleted Date</strong></th>
                    </tr>
                </thead>
                <tbody>
                    {archivedPosts.map((post) => (
                        <tr key={post.postID} onClick={() => handleClick(post.postID)}>
                            <td className='archived-post-title'>{post.postTitle}</td>
                            <td className='archived-post-category'>{post.postCategory}</td>
                            <td className='archived-post-ov-author'>{post.postUser.username}</td>
                            <td className='archived-post-ov-date'>{formatDateTime(post.created)}</td>
                            <td className='archived-post-ov-date'>{formatDateTime(post.archived)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ModArchivedUserPostsOverview;
