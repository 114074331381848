import UseRedirect from '../components/UseRedirect';
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { _URL } from '../Utils';
// import { useNavigate } from 'react-router';
const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const storedAuth = JSON.parse(localStorage.getItem('authState'));
    return storedAuth || null;
  });

  const redirectTo = UseRedirect();

  // const navigate = useNavigate();
  useEffect(() => {
    let logoutTimer;

    if (auth) {
      const handleSignOut = async () => {
        try {
          const signOutResponse = await axios.put(`${_URL}/signout/${auth.userID}`, {source: 'System Auto Sign Out'}, { withCredentials: true });
          if (signOutResponse.status === 200) {
            if (auth.userRole === "moderator"){
              redirectTo("/moderator/login");
            }
            else {
              redirectTo("/login");
            }
            await authSignOut();
          }
        } catch (error) {
          console.error("sign out failed", error);
        }
      };

      // set a timer automate user sign out 
      logoutTimer = setTimeout(handleSignOut, 900000); // time needs to be updated
    }

    // clear timer if the component unmounts or user logs out
    return () => clearTimeout(logoutTimer);
  }, [auth]);

  const authLogin = (userID, role) => {
    const authData = {userID: String(userID), userRole: role}; 
    setAuth(authData);
    localStorage.setItem('authState', JSON.stringify(authData)); // store authData in local storage
  };
  
  const authSignOut = () => {
    setAuth(null); // update auth to null value
    localStorage.removeItem('authState'); // remove authState field
  };

  return (
    <AuthContext.Provider value={{auth, authLogin, authSignOut}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
