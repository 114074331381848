// import css
import '../styles/AboutUs.css'
import { useState, useEffect } from 'react';
//import about us image
import logo from '../images/logo.jpg' 
import aboutus_img from '../images/about_us.jpg'
import aboutus2_img from '../images/about_us2.jpg'
import Loading from '../components/Loading';

function AboutUs() {

    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const handleImagesLoaded = () => {
            setLoading(false); 
        };

        const logoImage = new Image();
        const img1 = new Image();
        const img2 = new Image();
        logoImage.src = logo;
        img1.src = aboutus_img;
        img2.src = aboutus2_img;

        img1.onload = img2.onload = handleImagesLoaded;

        img1.onerror = img2.onerror = handleImagesLoaded; 

        return () => {
            
        };
    }, []);

    if (loading) {
        return <Loading />; 
    }

    return ( 
        <div>
            <h1 className='about-header'>About Us</h1>
            <div className='about-image-container'>
            <img src={logo} alt="" className="ws-logo"/>
            </div>

            {/* about us content */}
            <div className='about-p'>
                <p>
                Microfinance provides financial services like small loans, savings accounts, insurance, and other products to individuals
                or small businesses that lack access to traditional banking. It primarily targets low-income groups, particularly in developing countries, 
                who are often excluded from conventional banking due to a lack of collateral, credit history, or stable income. 
                </p>
                <p>
                A key focus of microfinance is on empowering women, who are frequently the primary recipients of these services. This focus is based on the
                assumption that women are more likely to reinvest their earnings into their families and communities, leading to broader social and economic benefits.
                By providing financial tools to women, microfinance aims to promote gender equality, enhance women's economic independence, and improve overall living standards.
                </p>
                <div className='about-image-container'>
                <img src={aboutus_img} alt="" className="aboutus-img" />
                </div>
                <p>
                However, despite its good intentions, microfinance has faced criticism and has had negative repercussions, particularly for the populations it seeks to help. One major concern is the
                high-interest rates charged by many microfinance institutions (MFIs). Although these rates are often justified by the high operational costs of serving poor and remote clients, they
                can trap borrowers in a cycle of debt, particularly when loans are used to covering basic needs rather than being invested in productive activities. Additionally, the pressure to repay
                loans can lead to significant stress and anxiety, particularly for women who may already face social and familial pressures. In some cases, this has led to severe outcomes, including
                    over-indebtedness and even suicides, particularly in areas where microfinance has been aggressively promoted.
                </p>
                <p>
                The group lending model, where community members are collectively responsible for each other's loans, can also have unintended negative effects. While designed to mitigate risk,
                it can strain social ties and lead to social ostracism if one member defaults, placing the burden on the entire group. Furthermore, the commercialisation of microfinance has led 
                to the entry of profit-driven entities into the sector, shifting the focus from social impact to financial returns, raising concerns about the exploitation of vulnerable populations
                and the erosion of microfinance's original mission to alleviate poverty.
                </p>
                <div className='about-image-container'>
                <img src={aboutus2_img} alt="" className="aboutus2-img" />
                </div>
                <p>
                In summary, while microfinance has the potential to empower women and promote financial inclusion, it is not without its drawbacks. Issues like high-interest rates, over-indebtedness, and potential exploitation underscore the need for careful regulation and a balanced approach that prioritises the well-being of borrowers over financial gain.
                </p>
                <p>
                WillowSphere is an initiative designed to address the complex challenges women face with microfinance. The app provides a holistic overview by offering essential tools and platforms 
                to help women understand and navigate the true costs and implications of these financial services. It features four main functions: a calculator to reveal the true costs of microloans; a repository of anonymised accounts from women sharing their real experiences with microfinance, both positive and negative; a social media platform for discussing common issues rela
                ted to microfinance; and an exhibit that explains how microfinance impacts women's daily lives in simple, understandable terms.
                </p>
            </div>
           
        </div>
    );
}
export default AboutUs ;