// How does microfinance financialise the daily lives of women?
import React, {useState, useEffect} from 'react';
import '../../styles/graph/MicrofinanceWomenLives.css';
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import HigherInterestRates from '../../images/exhibit/MicrofinanceWomenLives/HigherInterestRates.png';
import UnstableFoundations from '../../images/exhibit/MicrofinanceWomenLives/UnstableFoundations.png';
import LossOfSocialCapital from '../../images/exhibit/MicrofinanceWomenLives/LossOfSocialCapital.png';
import UnequalAccessToCapital from '../../images/exhibit/MicrofinanceWomenLives/UnequalAccessToCredit.png';
import Loading from '../../components/Loading';

const MicrofinanceWomenLives = () => {

    const [displayFoundation, setDisplayFoundation] = useState(false);
    const [displayInterestRate, setDisplayInterestRate] = useState(false);
    const [displaySocialCap, setDisplaySocialCap] = useState(false);
    const [displayCredit, setDisplayCredit] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await new Promise(resolve => setTimeout(resolve, 500)); 
            setLoading(false); 
        };
  
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;  
    }
    
    return (
        <div className='women-lives'>
            <h1 className='women-lives-h1'>How does microfinance financialise the daily lives of women?</h1>
            <p className='women-lives-p'>
            <strong>Valuation:</strong> Making micro-credit legible and subject to financial valuation metrics, ratings, and benchmarking by agencies like MicroRate, PlanetRating, and M-CRIL. Some microfinance institutions (MFIs) have even gone public through initial public offering (IPO) 35 to attract equity investment.
            </p>
            <p className='women-lives-p'>
            <strong>Intermediation:</strong> The use of specialised microcredit investment vehicles (MIVs) like fixed-income funds, blended value funds, holding companies, and private equity funds managed by large institutions (e.g., BlueOrchard, Oikocredit) to facilitate the flow of global capital into micro-credit networks.
            </p>
            <p className='women-lives-p'>
            <strong>Securitisation:</strong> Pooling and converting microloan receivables into tradable securities, allowing MFIs to access capital from global investors by separating the credit risk from the original lenders. An example is SHARE in India securitising $20 million worth of microloans in 2003.
            </p>
            <p className='women-lives-p'>
            In essence, the financialisation of micro-credit is the process of integrating poor micro-borrowers, primarily women, into mainstream global financial markets by transforming their loan repayments into investable asset streams for profit-seeking investors and financial institutions.
            </p>
            <p className='women-lives-p'>Micro/financialisation refers to how global financial practices are being applied to micro-lending. While it can bring more capital to those in need, it also introduces significant risks that can make life harder for the poorest borrowers.</p>
 
            <h2>KEY RISKS INVOLVED</h2>

            <div className='women-lives-drop'>
                <div>
                    <div className="women-lives-drop-bar blue-bar" onClick={() => setDisplayFoundation(prev => !prev)}>
                        <span className="women-lives-drop-icon">
                            {displayFoundation ? <GoChevronDown /> : <GoChevronRight />}
                        </span>
                        <span className="women-lives-drop-header">
                            {displayFoundation ? 'Hide Information' : 'Click To Read About Key Risk 1'}
                        </span>
                    </div>
                    {displayFoundation && (
                        <div className="women-lives-drop-show blue-drop-show">
                            <img src={UnstableFoundations} alt='Unstable Foundations'></img>
                            <h3>Unstable Foundations</h3>
                            <p>When micro-loans are bundled and sold as securities, similar to subprime mortgages, there's a risk of instability. Investors may be disconnected from the actual borrowers, making it hard to understand and manage risks properly.</p>
                        </div>
                    )}
                </div>
                <div>
                    <div className="women-lives-drop-bar" onClick={() => setDisplayInterestRate(prev => !prev)}>
                        <span className="women-lives-drop-icon">
                            {displayInterestRate ? <GoChevronDown /> : <GoChevronRight />}
                        </span>
                        <span className="women-lives-drop-header">
                            {displayInterestRate ? 'Hide Information' : 'Click To Read About Key Risk 2'}
                        </span>
                    </div>
                    {displayInterestRate && (
                        <div className="women-lives-drop-show">
                            <img src={LossOfSocialCapital} alt='Loss of Social Capital'></img>
                            <h3>Loss of Social Capital</h3>
                            <p>Traditional micro-lending relied on close relationships within communities. Financialisation changes this by involving distant investors, eroding the social bonds that helped ensure loans were used wisely and repaid.</p>
                        </div>
                    )}
                </div>
            </div>
            <br /><br /><br /><br /><br />
            <div className='women-lives-drop'>
                <div>
                    <div className="women-lives-drop-bar" onClick={() => setDisplaySocialCap(prev => !prev)}>
                        <span className="women-lives-drop-icon">
                            {displaySocialCap ? <GoChevronDown /> : <GoChevronRight />}
                        </span>
                        <span className="women-lives-drop-header">
                            {displaySocialCap ? 'Hide Information' : 'Click To Read About Key Risk 3'}
                        </span>
                    </div>
                    {displaySocialCap && (
                        <div className="women-lives-drop-show">
                            <img src={HigherInterestRates} alt='Higher Interest Rates'></img>
                            <h3>Higher Interest Rates</h3>
                            <p>To attract global investors, microfinance institutions (MFIs) often charge higher interest rates. This can make loans very expensive for poor borrowers, contradicting the original goal of helping them escape poverty.</p>
                        </div>
                    )}
                </div>
                <div>
                    <div className="women-lives-drop-bar blue-bar" onClick={() => setDisplayCredit(prev => !prev)}>
                        <span className="neolib-significant-changes-icon">
                            {displayCredit ? <GoChevronDown /> : <GoChevronRight />}
                        </span>
                        <span className="women-lives-drop-header">
                            {displayCredit ? 'Hide Information' : 'Click To Read About Key Risk 4'}
                        </span>
                    </div>
                    {displayCredit && (
                        <div className="women-lives-drop-show blue-drop-show">
                            <img src={UnequalAccessToCapital} alt='Unequal Access to Capital'></img>
                            <h3>Unequal Access to Credit</h3>
                            <p>Financialisation may only benefit a small number of well-established MFIs, leaving others behind. This creates a divide where only certain institutions can access global financial markets, while smaller ones struggle.</p>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default MicrofinanceWomenLives;
