// What is financialisation of daily life?
import React, {useState, useEffect} from 'react';
import '../../styles/graph/FinancialisationOfDailyLives.css';
import Loading from '../../components/Loading';

import ShiftInEconomicFocus from '../../images/exhibit/WhatIsFinancialisationOfDailyLife/ShiftInEconomicFocus.png'
import HouseholdFinancialBehaviour from '../../images/exhibit/WhatIsFinancialisationOfDailyLife/HouseholdFinancialBehaviour.png'
import CulturalTransformation from '../../images/exhibit/WhatIsFinancialisationOfDailyLife/CulturalTransformation.png'
import RiskAsANormalPartOfLife from '../../images/exhibit/WhatIsFinancialisationOfDailyLife/RiskAsANormalPartOfLife.png'
import FinancialLiteracyEmphasis from '../../images/exhibit/WhatIsFinancialisationOfDailyLife/FinancialLiteracyEmphasis.png'
import PolicyShifts from '../../images/exhibit/WhatIsFinancialisationOfDailyLife/PolicyShifts.png'
import ExpandedReachOfFinance from '../../images/exhibit/WhatIsFinancialisationOfDailyLife/ExpandedReachOfFinance.png'
import FinancialCitizenship from '../../images/exhibit/WhatIsFinancialisationOfDailyLife/FinancialCitizenship.png'

const FinancialisationOfDailyLives = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await new Promise(resolve => setTimeout(resolve, 500)); 
            setLoading(false); 
        };
  
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;  
    }

    return (
        <div className='finan-daily-lives'>
            <h1 className='finan-daily-lives-h1'>What is Financialisation of daily life?</h1>
            <p className='finan-daily-lives-p'>
            Financialisation of daily life refers to the increasing influence and dominance of financial practices, motives, and institutions in people's everyday lives and decision-making. This phenomenon emerged in the late 20th century and has become a significant aspect of contemporary society. 
            <br />
            <br />
            Here are the key aspects of financialisation of daily life:
            </p>

            <div>
                <div className='key-effects-for-comm'>
                    <div className='finan-daily-lives-left-icon'>  
                        <img src={ShiftInEconomicFocus} alt='Shift in Economic Focus'></img>
                    </div> 
                    <div className='finan-daily-lives-right-blue'>
                        <p className='finan-daily-lives-text'>
                            <strong className='finan-daily-lives-header'>Shift in Economic Focus</strong><br /><br />
                            Financial transactions and services have come to dominate economic exchanges, replacing the previous focus on industrial production.
                        </p>
                    </div>
                </div>      
                <div className='key-effects-for-comm'>
                    <div className='finan-daily-lives-left-pink'>  
                    <p className='finan-daily-lives-text'>
                            <strong className='finan-daily-lives-header'>Household Financial Behaviour</strong><br /><br />
                            Individuals and families are increasingly engaging with financial products that involve risk or active risk management, such as stocks, mutual funds, real estate investments, and various forms of credit.
                        </p>
                    </div> 
                    <div className='finan-daily-lives-right-icon'>
                        <img src={HouseholdFinancialBehaviour} alt='Household Financial Behaviour'></img>
                    </div>
                </div>            

                <div className='key-effects-for-comm'>
                    <div className='finan-daily-lives-left-icon'>  
                        <img src={CulturalTransformation} alt='Cultural Transformation'></img>
                    </div> 
                    <div className='finan-daily-lives-right-blue'>
                        <p className='finan-daily-lives-text'>
                            <strong className='finan-daily-lives-header'>Cultural Transformation</strong><br /><br />
                            There is a growing obsession with acquiring, borrowing, securing, and transferring money in Western societies. Financial considerations have become central to personal ambitions, family life, and decision-making.
                        </p>
                    </div>
                </div>  

                <div className='key-effects-for-comm'>
                    <div className='finan-daily-lives-left-pink'>  
                    <p className='finan-daily-lives-text'>
                            <strong className='finan-daily-lives-header'>Risk as a Normal Part of Life</strong><br /><br />
                            The financialisation of daily life has normalised risk-taking in personal financial matters.
                        </p>
                    </div> 
                    <div className='finan-daily-lives-right-icon'>
                        <img src={RiskAsANormalPartOfLife} alt='Risk as a Normal Part of Life'></img>
                    </div>
                </div>     
                <div className='key-effects-for-comm'>
                    <div className='finan-daily-lives-left-icon'>  
                        <img src={FinancialLiteracyEmphasis} alt='Financial Literacy Emphasis'></img>
                    </div> 
                    <div className='finan-daily-lives-right-blue'>
                        <p className='finan-daily-lives-text'>
                            <strong className='finan-daily-lives-header'>Financial Literacy Emphasis</strong><br /><br />
                            There is an increased focus on teaching financial literacy to both children and adults, promoting the idea that individuals should manage their finances like CEOs.
                        </p>
                    </div>
                </div>  

                <div className='key-effects-for-comm'>
                    <div className='finan-daily-lives-left-pink'>  
                    <p className='finan-daily-lives-text'>
                            <strong className='finan-daily-lives-header'>Policy Shifts</strong><br /><br />
                            Economic policies have shifted towards controlling inflation rather than promoting growth, reflecting the dominance of financial considerations.
                        </p>
                    </div> 
                    <div className='finan-daily-lives-right-icon'>
                        <img src={PolicyShifts} alt='Policy Shifts'></img>
                    </div>
                </div>  

                <div className='key-effects-for-comm'>
                    <div className='finan-daily-lives-left-icon'>  
                        <img src={ExpandedReachOfFinance} alt='Expanded Reach of Finance'></img>
                    </div> 
                    <div className='finan-daily-lives-right-blue'>
                        <p className='finan-daily-lives-text'>
                            <strong className='finan-daily-lives-header'>Expanded Reach of Finance</strong><br /><br />
                            Financial logic and practices have extended into areas of life previously not associated with finance, such as education, housing, and even biological life itself (bio-financialisation).
                        </p>
                    </div>
                </div>  

                <div className='key-effects-for-comm'>
                    <div className='finan-daily-lives-left-pink'>  
                    <p className='finan-daily-lives-text'>
                            <strong className='finan-daily-lives-header'>Financial Citizenship</strong><br /><br />
                            Access to financial services, financial literacy, and creditworthiness have become important factors in determining one's place in society.
                        </p>
                    </div> 
                    <div className='finan-daily-lives-right-icon'>
                        <img src={FinancialCitizenship} alt='Financial Citizenship'></img>
                    </div>
                </div>  

                <p className='finan-daily-lives-p'>
                The financialisation of daily life has profound implications for how people organise their lives and engage with money. It reflects a broader transformation in which financial considerations have become central to many aspects of contemporary culture and society. 
                </p>

            </div>
        </div>
    );
};

export default FinancialisationOfDailyLives;
