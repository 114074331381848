import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/ForumOverview.css';
import { FaRegCommentDots } from "react-icons/fa";
import { anonymousUser, checkAge } from '../components/FormatFunctions';
import { GoChevronDown, GoChevronRight } from "react-icons/go"; //import icon
import AuthContext from '../context/AuthProvider';
import AxiosIntercept from '../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const ForumOverview = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const [forumPosts, setForumPosts] = useState([]);
    const [originalPosts, setOriginalPosts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);

    //fixed filter options
    const ageCategories = ['18-24', '25-34', '35-44', '45-54', '55 above'];
    const loanCategories = ['Payday Loan', 'Short Term Loan', 'Microcredit Loan'];

    //handle toggle blurb 
    const [showBlurb, setShowBlurb] = useState(false);

    const toggleBlurb = () => {
        setShowBlurb(!showBlurb);
    };

    useEffect(() => {
        const getForumPosts = async () => {
            try {
                const response = await axios.get(`${_URL}/forum`, { withCredentials: true });
                console.log(response.data); 
                setForumPosts(response.data);
                setOriginalPosts(response.data);
            } catch (error) {
                console.error('error getting forum', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Forum Post',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                      }
                });
            } finally {
                setLoading(false);
            }
        };

        getForumPosts();
    }, []);

    useEffect(() => {
        const filteredPosts = originalPosts.filter(post => {
            const matchesSearch = post.postTitle.toLowerCase().includes(searchText) ||
                                  post.postDesc.toLowerCase().includes(searchText);
            const matchesCategory = !selectedCategory || 
                                    post.postCategory === selectedCategory ||
                                    post.postUser.age === selectedCategory;
            return matchesSearch && matchesCategory;
        });
        setForumPosts(filteredPosts);
    }, [searchText, selectedCategory, originalPosts]);

    const handleSearch = (event) => {
        setSearchText(event.target.value.toLowerCase());
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleFilter = (category) => {
        setSelectedCategory(category === 'All' ? '' : category);
        setShowDropdown(false);
    };

    const truncateForum = (text, limit) => {
        const words = text.split(' ');
        if (words.length > limit) {
            return words.slice(0, limit).join(' ') + '...';
        }
        return text;
    };

    if (loading) {
        return <Loading />;  
    }

    return (
        <div className="forum-container">
            <div className="forum-title">
                <h1><strong>Forum</strong></h1>
                <input
                    type="text"
                    className="forum-search-bar"
                    placeholder="Search bar"
                    onChange={handleSearch}
                />
                <div className="forum-filter-container">
                    <button className="forum-filter-button" onClick={toggleDropdown}>
                        Filter
                    </button>
                    {showDropdown && (
                        <div className="forum-filter-dropdown">
                            <ul>
                                <li className="forum-filter-dropdown-item">
                                    <a className="forum-filter-dropdown-link" onClick={() => handleFilter('All')}>All</a>
                                </li>
                                <li className="forum-filter-dropdown-item">
                                    <a className="forum-filter-dropdown-link"><strong>Age</strong></a>
                                    {ageCategories.map(category => (
                                        <a key={category} className="forum-filter-dropdown-link" onClick={() => handleFilter(category)}>{category}</a>
                                    ))}
                                </li>
                                <li className="forum-filter-dropdown-item">
                                    <a className="forum-filter-dropdown-link"><strong>Type of Loan</strong></a>
                                    {loanCategories.map(category => (
                                        <a key={category} className="forum-filter-dropdown-link-loan" onClick={() => handleFilter(category)}>{category}</a>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            {/* information blurb */}
            <div className="forum-info-bar" onClick={toggleBlurb}>
                <span className="forum-info-bar-icon">
                    {showBlurb ? <GoChevronDown /> : <GoChevronRight />}
                </span>
                <span className="forum-info-bar-title">
                    {showBlurb ? 'Hide Information About The Forum' : 'Show Information About The Forum'}
                </span>
            </div>
            {showBlurb && (
                <div className="forum-info-blurb">
                    <span>
                    This social media platform is designed for discussing common issues related to microfinance, allowing users to share experiences, seek advice, and connect with others facing similar challenges. It fosters a supportive community where individuals can exchange insights and strategies for navigating the complexities of microfinance.                 </span>
                </div>
            )}

            {forumPosts.map(post => (
                <Link key={post.postID} to={`/forum/${post.postID}`} className="forum-link">
                    <div className="forum-box">
                        <div className="forum-content">
                            <h2>{post.postTitle}</h2>
                            <p className="forum-author-category">
                                <strong>{anonymousUser(post.postAnon, post.postUser.username)} {checkAge(post.postUser.age)} | {post.postCategory}</strong>
                            </p>
                            <p className="forum-description">{truncateForum(post.postDesc, 35)}</p>
                        </div>
                        <div className="forum-image-container">
                            <FaRegCommentDots className="forum-icon"/>
                            <span><strong>Comment</strong></span>
                        </div>
                    </div>
                </Link>
            ))}
            <Link to="/new-forum-post">
                <button className="forum-new-post-button">New Post</button>
            </Link>
        </div>
    );
};

export default ForumOverview;
