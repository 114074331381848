// RestrictedRoutes.js
import React, { useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import { useNavigate } from 'react-router';
import '../styles/RestrictedRoutes.css'

const RestrictedRoute = ({ element }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/login')
  }
  const { auth } = useContext(AuthContext);

  console.log('Auth:', auth); 

  if (auth?.userID) {
    console.log('User is authenticated, rendering element:', element);
    return element;
  } else {
    console.log('User is not authenticated, showing login required message');
    return (
      <div className='login-required'>
        <h1><span onClick={handleClick}>Login</span> required to access this page.</h1>
      </div>
    );
  }
};


export default RestrictedRoute;
