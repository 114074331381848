// reformat date and time NEED TO UPDATE WHEN BACKEND IS FINALISED
export const formatDateTime = (datetime) => {
    const date = new Date(datetime);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' }); // whole month name
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0'); // hour in 2 digit
    const minute = date.getMinutes().toString().padStart(2, '0'); // minute in 2 digit

    return `${day} ${month} ${year} ${hour}:${minute}`;
};


// anonymous user
export const anonymousUser = (anon, username) => {
    return anon === 'Yes' ? "Anonymous User" : username;
}

export const checkAge = (age) => {
    const validAge = ['18-24', '25-34', '35-44', '45-54', '55 above'];
    return validAge.includes(age) ? ` | ${age}` : null;
};

export const checkActiveUserComment = (isActive, username) => {
    return isActive === true ? username : "Deleted Account"

};

