import '../styles/ForumReportComment.css'
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthProvider';
import { anonymousUser, formatDateTime, checkAge, checkActiveUserComment } from '../components/FormatFunctions';
import AxiosIntercept from '../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const ForumReportComment = () => {
    axios.defaults.withCredentials = true;
    const navigate = useNavigate();
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const {id} = useParams();
    const [comment, setComment] = useState();
    const [loading, setLoading] = useState(true);
    const [reason, setReason] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (reason.length <= 0) {
            Swal.fire({
                title: 'Error: \nReported reason cannot be empty.',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                  title: 'swal2-title-custom',
                  popup: 'swal2-popup-custom'
                }
              });
            return;
        }

        try {
            const response = await axios.post(`${_URL}/report-comment/${id}`, {reportedBy: userID, reportReason: reason});
            if (response.data.postID) {
              Swal.fire({
                title: 'Your report has been sent to the moderator.',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                    popup: 'swal2-popup-custom',
                    title: 'swal2-title-custom',
                    confirmButton: 'swal2-confirmbutton-custom'
                }
            });
              navigate(`/forum/${response.data.postID}`);
            }
        } 
        catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
              Swal.fire({
                title: 'Error: \n' + err.response.data.message,
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                  title: 'swal2-title-custom',
                  popup: 'swal2-popup-custom',
                }
              });
            } else {
              console.error(err);
              Swal.fire({
                title: 'Error: \nComment report failed.' ,
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                  title: 'swal2-title-custom',
                  popup: 'swal2-popup-custom'
                }
              });
            }
          }

    }

    useEffect(() => {
        const getReportComment = async () => {
            try {
                console.log(`getting report forum comment`);
                const response = await axios.get(`${_URL}/report-comment/${id}`);
                setComment(response.data[0]); 
            } catch (error) {
                console.error('error getting report forum comment:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Report Forum Post Comment',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        getReportComment();
    }, [id]);

    if (loading) {
      return <Loading />;  
    }

    return (
        <div className="reportcmt-container">
            <h1>{comment.postTitle}</h1>
            <div className='desc'>
                <p className='authCategory'><strong>{anonymousUser(comment.postAnon, comment.postUser)} {checkAge(comment.postAge)} | {comment.postCategory}</strong></p>
                <p>{comment.postDesc}</p>
            </div>
            <div className='reportcmt-cmt'>
                <div className='reportcmt-box'></div>
                <div className='reportcmt-user'>
                    <p><strong>{checkActiveUserComment(comment.cmtActiveUser, comment.cmtAuthorUsername)}</strong></p>
                    <p><strong>{formatDateTime(comment.comment.cmtDateTime)}</strong></p>
                    <p>{comment.comment.cmtDesc}</p>
                </div>
            </div>
            <div className='reportcmt-reason'>
                <label><strong>Reason</strong></label>
                <textarea placeholder='Reporting reason' value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
            </div>
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
};
export default ForumReportComment;

