import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/NewForumPost.css'; 
import AuthContext from '../context/AuthProvider';
import AxiosIntercept from '../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const NewForumPost = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [anonymous, setAnonymous] = useState(null); 
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    // Fixed filter options
    const ageCategories = ['18-24', '25-34', '35-44', '45-54', '55 above'];
    const loanCategories = ['Payday Loan', 'Short Term Loan', 'Microcredit Loan'];

    useEffect(() => {
        // Check if the component is in edit mode
        if (location.state && location.state.post) {
            console.log('Location state:', location.state); // Check state
            const { post } = location.state;
            setTitle(post.postTitle);
            setCategory(post.postCategory);
            setDescription(post.postDesc);
            setAnonymous(post.postAnon === 'Yes');
            setIsEditMode(true);
        }
    }, [location.state]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // setShowPopOut(true);

        // setTitle('');
        // setCategory('');
        // setDescription('');
        // setAnonymous(null);
        if (!title.trim()) {
            setLoading(false);
            Swal.fire({
                title: 'Error: \nPost Title is required.',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                    title: 'swal2-title-custom',
                    popup: 'swal2-popup-custom'
                }
            });
            return; 
        }
    
        if (!category) {
            setLoading(false);
            Swal.fire({
                title: 'Error: \nPlease select an item in the category',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                    title: 'swal2-title-custom',
                    popup: 'swal2-popup-custom'
                }
            });
            return; 
        }
    
        if (!description.trim()) {
            setLoading(false);
            Swal.fire({
                title: 'Error: \nPost Description is required.',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                    title: 'swal2-title-custom',
                    popup: 'swal2-popup-custom'
                }
            });
            return; 
        }
    
        const postAnon = (anonymous) => {
            return anonymous ? 'Yes' : 'No'; // yes if true, no if false
        };

        try {
            // check url and determine if it is edit or post mode
            const url = isEditMode 
                ? `${_URL}/update-forum-post/${location.state.post.postID}`
                : `${_URL}/new-forum-post`;
            const method = isEditMode ? 'put' : 'post'; 
            const response = await axios[method](url, {
                postTitle: title,
                postCategory: category,
                postAuthor: userID,
                postAnon: postAnon(anonymous),
                postDesc: description
            });

            if (response.data.postID || response.data.success) {
                if (isEditMode) {
                    Swal.fire({
                        title: 'Post updated successfully.',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        customClass: {
                          title: 'swal2-title-custom',
                          popup: 'swal2-popup-custom',
                          confirmButton: 'swal2-confirmbutton-custom'
                        }
                      });
                } else {
                    Swal.fire({
                        title: 'Post created successfully. \nUser post status will be updated shortly after moderator review.',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        customClass: {
                          title: 'swal2-title-custom',
                          popup: 'swal2-popup-custom',
                          confirmButton: 'swal2-confirmbutton-custom'
                        }
                      });
                }
                navigate(`/mypost/${response.data.postID}`);
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                Swal.fire({
                    title: 'Error: \n' + err.response.data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            } else {
                console.error(err);
                Swal.fire({
                    title: 'Error: \nPost'+ (isEditMode ? "update" : "creation") + ' failed.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await new Promise(resolve => setTimeout(resolve, 500)); 
            setLoading(false); 
        };
  
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;  
    }

    return (
        <div className="new-forum-post-container">
            <h1>{isEditMode ? 'Edit Forum Post' : 'New Forum Post'}</h1>
            <form onSubmit={handleSubmit}>
                {/* New Forum Post Title */}
                <div className="form-group title-category-group">
                    <div className="form-group">
                        <label htmlFor="title">Post Title</label>
                        <input
                            type="text"
                            id="title"
                            className="title-input" 
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    {/* New Forum Post Category */}
                    <div className="form-group">
                        <label htmlFor="category">Category</label>
                        <select
                            id="category"
                            className="category-select" 
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <option value="">Select Category</option>
                            <optgroup label="Type of Loan">
                                {loanCategories.map(category => (
                                    <option key={category} value={category}>{category}</option>
                                ))}
                            </optgroup>
                        </select>
                    </div>
                </div>
                {/* New Forum Post Description */}
                <div className="form-group">
                    <label htmlFor="description">Post Description</label>
                    <textarea
                        id="description"
                        className="description-textarea" 
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                {/* Anonymous statement and button */}
                <div className="anonymous-option">
                    <strong>I would like to remain anonymous.</strong>
                    <button
                        type="button"
                        className={`anonymous-button ${anonymous === true ? 'selected' : ''}`}
                        onClick={() => setAnonymous(true)}
                    >
                        <strong>Yes</strong>
                    </button>
                    <button
                        type="button"
                        className={`anonymous-button ${anonymous === false ? 'selected' : ''}`}
                        onClick={() => setAnonymous(false)}
                    >
                        <strong>No</strong>
                    </button>
                </div>
                {/* change button based on mode */}
                <button type="submit">
                    {isEditMode ? 'Update Post' : 'Upload Post'}
                </button>
            </form>
            {/* {showPopOut && (
                <div className="new-forum-pop-out-message">
                    <strong>Your post has been sent to the moderator for review.<br />
                    User post status will be updated shortly.</strong>
                </div>
            )} */}
        </div>
    );
};

export default NewForumPost;
