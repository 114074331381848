import React, {useState, useEffect} from 'react';
import '../styles/RulesOfEngagement.css';
import Loading from '../components/Loading';

const RulesOfEngagement = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await new Promise(resolve => setTimeout(resolve, 500)); 
            setLoading(false); 
        };
  
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;  
    }
    
    return (
        <div className="rules-container">
            <h1><strong>RULES OF ENGAGEMENT</strong></h1>
            <ul>
                <strong>
                    <li>1.Be kind.</li>
                    <li>2.Respond appropriately, and avoid going off topic.</li>
                    <li>3.Respect everyone’s opinions and privacy.</li>
                    <li>4.No bullying and hate speech.</li>
                    <li>5.No advertisements and spams.</li>
                </strong>
            </ul>
        </div>
    );
};

export default RulesOfEngagement;
