// Login.js

import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Loading from "../components/Loading";
import { _URL } from "../Utils";

function Login() {
  const { authLogin } = useContext(AuthContext);
  const navigate = useNavigate(); // Create a navigate function instance
  const [loading, setLoading] = useState(true);

  const handleSignUpClick = () => {
    navigate("/signup"); // Navigate to the signup page
  };

  const handleForgotPwd = () => {
    navigate("/forgot-password"); // redirect to forgot password page
  };

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  // Set visibility for password
  const [userLoginPasswordVisible, setUserLoginPasswordVisible] =
    useState(false);

  const toggleUserLoginPasswordVisibility = () => {
    setUserLoginPasswordVisible(!userLoginPasswordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${_URL}/login`, {
        username,
        password,
      });
      console.log(response);

      if (response.data.message === "Login successful.") {
        authLogin(response.data.userID, response.data.userRole);

        // show success alert using Swal
        Swal.fire({
          title: response.data.message,
          icon: "success",
          confirmButtonText: "Ok",
          customClass: {
            title: "swal2-title-custom",
            popup: "swal2-popup-custom",
            htmlContainer: "swal2-text-custom",
            confirmButton: "swal2-confirmbutton-custom",
          },
        }).then(() => {
          // Redirect based on user role
          if (response.data.userRole === "moderator") {
            navigate("/myview");
          } else {
            navigate("/");
          }
        });
      } else {
        // show error alert using Swal
        Swal.fire({
          title: "Error: \n" + response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            title: "swal2-title-custom",
            popup: "swal2-popup-custom",
          },
        });
      }
    } catch (err) {
      console.error(err);

      // show error alert for any login issues
      Swal.fire({
        title: "Error: Login error occurred. \nPlease try again.",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: "swal2-title-custom",
          popup: "swal2-popup-custom",
        },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="login-page">
        <main className="login-main">
          <section className="login-section">
            <h2 className="login-title">Login</h2>
            <form>
              <label htmlFor="username" className="input-label">
                Username
              </label>
              <input
                id="username"
                type="text"
                className="input-field-username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label htmlFor="password" className="input-label">
                Password
              </label>
              <div className="user-login-password-input-container">
                <input
                  id="password"
                  type={userLoginPasswordVisible ? "text" : "password"}
                  className="input-field-username"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i
                  onClick={toggleUserLoginPasswordVisibility}
                  className="user-login-eye-icon"
                >
                  <FontAwesomeIcon
                    icon={userLoginPasswordVisible ? faEye : faEyeSlash}
                  />
                </i>
              </div>
              <p onClick={handleForgotPwd}>
                Forgot your <span className="forgot-password">password</span>?
              </p>
              <button
                type="submit"
                className="login-button"
                onClick={handleLogin}
              >
                Login
              </button>
            </form>
          </section>
          <aside className="signup-section">
            <h2 className="signup-title">
              New here? <br /> Join us today!
            </h2>
            <span className="signup-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              vitae.
            </span>
            <button className="signup-button" onClick={handleSignUpClick}>
              Sign up here
            </button>
          </aside>
        </main>
      </div>
    </div>
  );
}

export default Login;
