import React, { useState, useContext } from 'react';
import AuthContext from '../../context/AuthProvider';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../../styles/moderator/ModLogin.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

function ModLogin() {
  const { authLogin } = useContext(AuthContext);
  const navigate = useNavigate();  

  const handleForgotPwd = () => {
    navigate('/forgot-password'); 
  };

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  //Set visibility for password
  const [modPasswordVisible, setModPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleModPasswordVisibility = () => {
    setModPasswordVisible(!modPasswordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
      const loginResponse = await axios.post(`${_URL}/moderator/login`, { username, password }, {withCredentials: true});
      if (loginResponse.data.message === "Moderator login successful.") {
        authLogin(loginResponse.data.userID, 'moderator');
        // localStorage.setItem('access_token', response.data.token);
        // axios.defaults.headers.common['Authorization'] = `Bearer ${loginResponse.data.accessToken}`;
        // console.log('modlogin', axios.defaults.headers.common['Authorization']);
        Swal.fire({
          title: loginResponse.data.message,
          icon: 'success',
          confirmButtonText: 'Ok',
          customClass: {
            title: 'swal2-title-custom',
            popup: 'swal2-popup-custom',
            htmlContainer: 'swal2-text-custom',
            confirmButton: 'swal2-confirmbutton-custom'
          }
        }).then(() => {
          navigate('/myview');  
        });
      } else {
        Swal.fire({
          title: 'Error: \n' + loginResponse.data.message,
          icon: 'error',
          showConfirmButton: false,
          customClass: {
            title: 'swal2-title-custom',
            popup: 'swal2-popup-custom'
          }
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: 'Error: Login error occurred. \nPlease try again.',
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          title: 'swal2-title-custom',
          popup: 'swal2-popup-custom'
        }
      });
    } finally {
      setLoading(false);  
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="mod-login-page">
        <main className="mod-login-main">
          <section className="mod-login-section">
            <h2 className="mod-login-title">Login</h2>
            <form>
              <label htmlFor="username" className="mod-input-label">Username</label>
              <input id="username" type="text" className="mod-input-field" value={username} onChange={(e) => setUsername(e.target.value)}/>
              <label htmlFor="password" className="mod-input-label">Password</label>
              <div className="mod-password-input-container">
                <input id="password" type={modPasswordVisible ? "text" : "password"} className="mod-input-field" value={password} onChange={(e) => setPassword(e.target.value)}/>
                <i onClick={toggleModPasswordVisibility} className="mod-login-eye-icon">
                  <FontAwesomeIcon icon={modPasswordVisible ? faEye : faEyeSlash} />
                </i>
              </div>
              <p onClick={handleForgotPwd}>Forgot your <span className="mod-forgot-password">password</span>?</p>
              <button type="submit" className="mod-login-button" onClick={handleLogin}>Login</button>
            </form>
          </section>
        </main>
      </div>
    </div>
  );
}

export default ModLogin;
