// // dev

// const host = "localhost:3333";
// const protocol = "http:";

// // dev

// prod

const host = String(window.location.hostname) + "/api";
const protocol = String(window.location.protocol);

// prod



export const _URL = `${protocol}//${host}`;