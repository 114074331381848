import React from 'react';
import '../styles/components/Loading.css';

const Loading = () => {
    return (
        <div className="loading-container">
            <h2>Loading...</h2>
        </div>
    );
};

export default Loading;