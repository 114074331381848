import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';

// import css
import '../../styles/graph/Exhibit.css';
import graph1 from '../../images/graph1.jpg'
import graph2 from '../../images/graph2.jpg';
import graph3 from '../../images/graph3.jpg';
import graph4 from '../../images/graph4.jpg';

const Exhibit = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await new Promise(resolve => setTimeout(resolve, 500)); 
            setLoading(false); 
        };
  
        fetchData();
    }, []);

    const handleClick = (link) => () => {
        navigate(link);
    };

    if (loading) {
        return <Loading />;  
    }

    return (
        <div id='graph'>
            <h1 className='graph-header'>Exhibition of Microfinance</h1>
            <div className='graph-row'>            
                <div className='graph-item'>
                    <img src={graph1} alt='What is financialization of daily life?' className='graph-picture'></img>
                    <p className='graph-text'>What is financialisation of daily life?</p>
                    <button className='graph-btn-right' onClick={handleClick('/exhibit/what-is-financialisation-of-daily-life')}>Learn more</button>
                </div>
                <div className='graph-item'>
                    <img src={graph4} alt='What is neoliberalism and its relation to financialisation?' className='graph-picture'></img>
                    <p className='graph-text'>What is neoliberalism and its relation to financialisation?</p>
                    <button className='graph-btn-left' onClick={handleClick('/exhibit/what-is-neoliberalism-and-its-relation-to-financialisation')}>Learn more</button>
                </div>
            </div>
            <div className='graph-row'>
                <div className='graph-item'>
                    <img src={graph2} alt='How does microfinance financialise the daily lives of women?' className='graph-picture'></img>
                    <p className='graph-text'>How does microfinance financialise the daily lives of women?</p>
                    <button className='graph-btn-right' onClick={handleClick('/exhibit/how-does-microfinance-financialise-the-daily-lives-of-women')}>Learn more</button>
                </div>
                <div className='graph-item'>
                    <img src={graph3} alt='What is microfinance?' className='graph-picture'></img>
                    <p className='graph-text'>What is microfinance?</p>
                    <button className='graph-btn-left' onClick={handleClick('/exhibit/what-is-microfinance')}>Learn more</button>
                </div>
            </div>
        </div>
    );
};

export default Exhibit;
