import { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// Import SweetAlert2
import Swal from 'sweetalert2';
import '../styles/components/SignOut.css';
import logo from '../images/logo.jpg';
import Loading from './Loading';
import { _URL } from '../Utils';

const SignOut = () => {
  const {auth} = useContext(AuthContext);
  const userID = auth ? String(auth.userID) : null; // get user id
  const { authSignOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const alertDisplay = useRef(false); // ref to track if the alert has been shown
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleSignOut = async () => {
      try {
        if (!alertDisplay.current) {
          Swal.fire({
            title: 'Signing out...',
            imageUrl: logo,
            imageWidth: 120,
            imageHeight: 120,
            showConfirmButton: false, 
            timer: 1500, 
            customClass: {
              popup: 'swal2-popup-custom',
              title: 'swal2-title-custom',
            },
          });
          alertDisplay.current = true; // true since alert has been displayed
        }

        //sign out request
        await axios.put(`${_URL}/signout/${userID}`, { source: userID }, { withCredentials: true });
        await authSignOut();
        navigate('/');

        setLoading(false);
      } catch (error) {
        console.error('User sign out error', error);

        Swal.fire({
          title: 'Error: Sign out error occurred. \nPlease try again.',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500, 
          customClass: {
            popup: 'swal2-popup-custom',
            title: 'swal2-title-custom',
            confirmButton: 'swal2-confirmbutton-custom',
          },
        });
        setLoading(false);
      }
    };

    handleSignOut();
  }, [authSignOut, navigate, userID]); 

  if (loading) {
    return <Loading />;
  }

  return null; 
};

export default SignOut;
