import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/UserAccount.css';
import AuthContext from '../context/AuthProvider';
import AxiosIntercept from '../context/AxiosIntercept';
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import SignOut from '../components/SignOut';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const UserAccount = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const { authSignOut } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const { id } = useParams();
    const navigate = useNavigate(); 
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleDelete = () => {
        setIsExpanded(!isExpanded);
      };

    // handle changes to input fields
    const handleChange = (e) => {
        const {id, value} = e.target;
        setUser(prevState => ({
            ...prevState, [id]: value
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        console.log(`updating ${id} user account information`);
        const response = await axios.put(`${_URL}/account/${id}`, {username: user.username, email: user.email, age: user.age, occupation: user.occupation, updatedby: userID});
        if (response.data.userID) {
            Swal.fire({
                title: 'My Account information updated successfully.',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                  title: 'swal2-title-custom',
                  popup: 'swal2-popup-custom',
                  confirmButton: 'swal2-confirmbutton-custom'
                }
              })
            navigate(`/account/${response.data.userID}`);
        } 
        else {
            Swal.fire({
                title: 'Error: \nMy Account information update failed.',
                icon: 'error',
                showConfirmButton: false,
                customClass: {
                  title: 'swal2-title-custom',
                  popup: 'swal2-popup-custom'
                }
              });
        }
    }

    useEffect(() => {
        const getUser = async () => {
            try {
                console.log(`getting ${id} data`);
                const response = await axios.get(`${_URL}/account/${id}`);

                setUser(response.data); 
            } catch (error) {
                console.error('error getting user:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get User',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            } finally {
                setLoading(false);
            }
        };
        getUser();
    }, [id]);

    if (loading) {
        return <Loading />;
    }

    if (!user) {
        return <div><h1>User does not exist.</h1></div>;
    }

    const handleResetPassword = () => {
        navigate('/reset-password'); 
    };

    const handleDeleteUser = async () => {
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure you want to delete your account? \nThis action cannot be undone.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                confirmButton: 'swal2-confirmbutton-custom',
                cancelButton: 'swal2-cancelbutton-custom'
            }
        });

        if (isConfirmed) {
            try {
                const response = await axios.delete(`${_URL}/delete-account/${id}`, { data: { userID } });
                if (response.status === 200) {
                    Swal.fire({
                        title: 'Your account has been deleted successfully.',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        customClass: {
                            popup: 'swal2-popup-custom',
                            title: 'swal2-title-custom',
                            confirmButton: 'swal2-confirmbutton-custom'
                        }
                    });
                    await authSignOut();
                    navigate('/'); 
                } else {
                    Swal.fire({
                        title: 'Error: Something went wrong. \nPlease try again.',
                        icon: 'error',
                        showConfirmButton: false,
                        customClass: {
                            popup: 'swal2-popup-custom',
                            title: 'swal2-title-custom',
                        }
                    });
                }
            } catch (error) {
                console.error("Error deleting account: ", error);
                Swal.fire({
                    title: 'Error: Failed to delete your account. \nPlease try again later.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom',
                    }
                });
            }
        }
    }
    
    return (
        <div>
            <div className="useracc-container">
                <h1>My Account</h1>
                <form>
                    <div className="useracc-form">
                        <label htmlFor="username">Username</label>
                        <input id="username" type="text" value={user.username} onChange={handleChange} />
                    </div>
                    <div className="useracc-form">
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" value={user.email} onChange={handleChange} />
                    </div>
                    <div className="useracc-form">
                        <label htmlFor="age">Age</label>
                        <select id="age" value={user.age} onChange={handleChange} >
                            <option value="">Select age</option>
                            <option value="18-24">18-24</option>
                            <option value="25-34">25-34</option>
                            <option value="35-44">35-44</option>
                            <option value="45-54">45-54</option>
                            <option value="55 above">55 above</option>
                        </select>
                    </div>
                    <div className="useracc-form">
                        <label htmlFor="occupation">Occupation</label>
                        <input id="occupation" type="text" value={user.occupation} onChange={handleChange} />
                    </div>
                    <div className="useracc-form">
                        <button type="submit" className="update-button" onClick={handleUpdate}>Update Information</button>
                    </div>
                    <div>
                        <button type="button" className="user-account-reset-button" onClick={handleResetPassword}>Reset Password</button>
                    </div>
                </form>
            </div>

            {/* Delete account dropdown */}
            <div className="delete-dropdown-container">
                <div className="delete-dropdown-bar-wrapper">
                    <div className={`delete-dropdown-bar ${isExpanded ? "expanded" : ""}`}>
                        <button className="delete-dropdown-bar-button" onClick={toggleDelete}>
                            {isExpanded ? (
                            <>
                                <GoChevronDown /> Hide Information About Delete User Account
                            </>
                            ) : (
                            <>
                                <GoChevronRight /> Delete User Account
                            </>
                            )}
                        </button>
                        {isExpanded && (
                            <div className="delete-dropdown-content">
                            {auth && auth.userRole === 'moderator' ? ( // For moderators
                                        <span>
                                        Deleting your account will permanently remove access to WillowSphere.
                                        </span>
                                    ) : ( // For user
                                        <span>
                                        Deleting your account will permanently remove access to WillowSphere.<br />
                                        When you delete your account, your posts will be removed from the Forum.<br />
                                        Your comments will remain in the Forum but will be disassociated from your account.
                                        </span>
                                    )}
                            <button type="button" className="delete-user-button" onClick={handleDeleteUser}>Delete Account</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserAccount;
