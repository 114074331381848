import React, { useState, useEffect, useContext }from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/moderator/ModReviewNewPostOverview.css';
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModReviewNewPostOverview = () => {
    axios.defaults.withCredentials = true;
    const {auth} = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // set userID to null if undefined
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);
    
    const [newPost, setNewPost] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handlePostClick = (id) => {
        navigate(`/review-new-posts/${id}`);
    };

    useEffect(() => {
        const getNewPost = async () => {
            try {
                console.log(`getting reported comments`);
                const response = await axios.get(`${_URL}/review-new-posts`);

                setNewPost(response.data); 
            } catch (error) {
                console.error('error getting review new posts:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Review New Forum Posts',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom',
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        getNewPost();
    }, []);

    if (loading) {
        return <Loading />; 
    }

    return (
        <div className="mod-review-new-post-container">
            <h2>New Post Created</h2>
            <table className="review-new-post-table">
                <thead>
                    <tr>
                        <th><strong>Post ID</strong></th>
                        <th><strong>New Post Title</strong></th>
                    </tr>
                </thead>
                <tbody>
                    {newPost.map((post) => (
                        <tr key={post.postID} onClick={() => handlePostClick(post.postID)}>
                            <td>{post.postID}</td>
                            <td>{post.postTitle}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ModReviewNewPostOverview;
