import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/moderator/ModReportedCmtsOverview.css'
import { formatDateTime } from '../../components/FormatFunctions';
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModReportedCmtsOverview = () => {
    axios.defaults.withCredentials = true;
    const {auth} = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // set userID to null if undefined
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const navigate = useNavigate(); 

    const handleClick = (id, source) => { // redirect to individual comment report
        navigate(`/reported-comments/${id}?source=${source}`, {state: {isArchived: false}});  
    };

    const [reportedForumCmts, setReportedForumCmts] = useState([]);
    const [reportedArchivedCmts, setReportedArchivedCmts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getReportedCmts = async () => {
            try {
                console.log(`getting reported comments`);
                const response = await axios.get(`${_URL}/reported-comments`);

                setReportedForumCmts(response.data.forum);
                setReportedArchivedCmts(response.data.archived)
            } catch (error) {
                console.error('error getting reported comments:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Reported Forum Comments',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        getReportedCmts();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="reportedcmt-container">
            <h1>Reported Forum Comments</h1>
            <div className='reportedcmt-table'>
                <table>
                    <tr>
                        <th>Post ID</th>
                        <th>Reported Comment</th>
                        <th className='reason'>Reason</th>
                        <th className='datetime'>Date / Time</th>
                    </tr>
                    {reportedForumCmts.map((report, index) => {
                        console.log(report);
                        return (
                            <tr key={report.index} onClick={() => handleClick(report.reportID, "published")}>
                                <td>{report.postRef}</td> 
                                <td className='comment-text'>{report.post.comments.cmtDesc}</td>
                                <td className='reason'>{report.reportReason}</td>
                                <td>{formatDateTime(report.reportDateTime)}</td>
                            </tr>
                        );
                    })}
                    {reportedArchivedCmts.map((report, index) => {
                        console.log(report);
                        return (
                            <tr key={report.index} onClick={() => handleClick(report.reportID, "archived")}>
                                <td>{report.postRef}</td> 
                                <td className='comment-text'>{report.post.comments.cmtDesc}</td>
                                <td className='reason'>{report.reportReason}</td>
                                <td>{formatDateTime(report.reportDateTime)}</td>
                            </tr>
                        );
                    })}
                </table>
            </div>
        </div>
    );
};

export default ModReportedCmtsOverview;
