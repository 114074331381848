// ModContact.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../../styles/moderator/ModContact.css';
import AuthContext from '../../context/AuthProvider';
import { formatDateTime } from '../../components/FormatFunctions';
import AxiosIntercept from '../../context/AxiosIntercept';

// import SweetAlert2
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

function ModContact() {
  const { id } = useParams(); 
  const navigate = useNavigate(); 
  const { auth } = useContext(AuthContext);
  const userID = auth ? String(auth.userID) : null; // get user id
  const location = useLocation();
  const isPending = location.state?.isPending;

  axios.defaults.withCredentials = true;
  const role = auth.userRole;
  // handle jwt refresh
  AxiosIntercept(userID, role);

  const [contactForm, setContactForm] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getContactDetails = async () => {
      try {
        if (isPending) {
          const pendingContact = await axios.get(`${_URL}/reported-contact/${id}`);
          setContactForm(pendingContact.data);
        }
        else if (!isPending) {
          const archivedContact = await axios.get(`${_URL}/archived-contact/${id}`);
          setContactForm(archivedContact.data[0]);
        }

      } catch (error) {
        console.error('error getting contact forms:', error);
          Swal.fire({
            title: 'Error: \nFailed to get Contact Forms',
            icon: 'error',
            showConfirmButton: false,
            customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom'
            }
        });
      } finally {
        setLoading(false);
      }
    };
    getContactDetails();
  }, [id,isPending]);

  if (loading) {
    return <Loading />;
  }

  const handleCloseForm = async (e) => {
    try {
      const response = await axios.put(`${_URL}/reported-contact/${id}`, { userID });
      console.log(response.data); // log the response from the server
  
      if (response.data.message === 'Contact form deleted / archived successfully.') {
        // show success alert
        Swal.fire({
          title: 'Form closed successfully.',
          icon: 'success',
          confirmButtonText: 'Ok',
          customClass: {
            popup: 'swal2-popup-custom',
            title: 'swal2-title-custom',
            confirmButton: 'swal2-confirmbutton-custom',
          },
        }).then(() => {
          navigate('/reported-contact'); 
        });
      } else {
        Swal.fire({
          title: response.data.message || 'Something went wrong. Please try again.',
          icon: 'question',
          showConfirmButton: false,
          customClass: {
            popup: 'swal2-popup-custom',
            title: 'swal2-title-custom',
            confirmButton: 'swal2-confirmbutton-custom',
          },
        });
      }
    } 
    catch (error) {
      console.error('Error deleting contact form:', error.response ? error.response.data : error.message);
      Swal.fire({
        title: 'Error: An error occurred while closing the form. \nPlease try again.',
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          popup: 'swal2-popup-custom',
          title: 'swal2-title-custom',
          confirmButton: 'swal2-confirmbutton-custom',
        },
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mod-contact-us-page">
      <div className="mod-contact-content">
        <form className="mod-contact-form">
          <div className="mod-form-group-contact">
            <label htmlFor="name" className="contact-label">Name</label>
            <input 
              id="name" 
              type="text" 
              className="input-field-mod-contact-us" 
              defaultValue={contactForm.name} 
              readOnly 
            />
          </div>
          <div className="mod-form-group-contact">
            <label htmlFor="email" className="contact-label">Email*</label>
            <input
              id="email"
              type="email"
              className="input-field-mod-contact-us"
              value={contactForm.email}
              readOnly
            />
          </div>
          <div className="mod-form-group-contact">
            <label htmlFor="subject" className="contact-label">What is it about?</label>
            <select
              id="subject"
              className="input-field-mod-contact-us"
              value={contactForm.category}
              readOnly
            >
              <option value="" disabled>Select an option</option>
              <option value="Urgent help needed">Urgent help needed</option>
              <option value="General queries">General queries</option>
              <option value="Feedback">Feedback</option>
              <option value="Issues with account">Issues with account</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div className="mod-form-group-contact">
            <label htmlFor="message" className="contact-label">How can we help?*</label>
            <textarea 
              id="message" 
              className="input-field-help-decs" 
              defaultValue={contactForm.desc} 
              readOnly
            ></textarea>
          </div>
          {isPending && (         
             <button type="button" className="close-form-button" onClick={handleCloseForm}>
              Close Form
            </button>
          )}
        </form>
      </div>
      {!isPending && (       
            <div className='archived-contact-form'>
              <p><strong>Contact Form was received on {formatDateTime(contactForm.datetime)}.
              <br />Contact Form was closed on {formatDateTime(contactForm.archived)} by {contactForm.archivedUser.username}.</strong></p>
            </div>  
          )}
    </div>
  );
}

export default ModContact;
