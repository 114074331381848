// What is neoliberalism and its relation to financialisation?

import {useState, useEffect} from 'react';
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import '../../styles/graph/Neoliberalism.css';
import Loading from '../../components/Loading';
import SignificantChanges from '../../images/exhibit/WhatIsNeoliberalism/SignificantChanges.png';
import RelationshipBetweenNeoliberalismAndFinancialisation from '../../images/exhibit/WhatIsNeoliberalism/RelationshipBetweenNeoliberalismAndFinancialisation.png';
import FinancialAndEconomicInequality1 from '../../images/exhibit/WhatIsNeoliberalism/FinancialAndEconomicInequality-1.png';
import FinancialAndEconomicInequality2 from '../../images/exhibit/WhatIsNeoliberalism/FinancialAndEconomicInequality-2.png';

const Neoliberalism = () => {

    //slide show animation
    const [slideIndex1, setSlideIndex1] = useState(1);
    const [slideIndex2, setSlideIndex2] = useState(1);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await new Promise(resolve => setTimeout(resolve, 500)); 
            setLoading(false); 
        };
    
        fetchData();
      }, []);

    const plusSlides1 = (n) => {
        setSlideIndex1(prevIndex => {
            let newIndex = prevIndex + n;
            if (newIndex > 3) newIndex = 1;
            if (newIndex < 1) newIndex = 3;
            return newIndex;
        });
    }
    const plusSlides2 = (n) => {
        setSlideIndex2(prevIndex => {
            let newIndex = prevIndex + n;
            if (newIndex > 2) newIndex = 1;
            if (newIndex < 1) newIndex = 2;
            return newIndex;
        });
    }

    const currentSlide1 = (n) => setSlideIndex1(n);
    const currentSlide2 = (n) => setSlideIndex2(n);

    useEffect(() => {
        const slides1 = document.getElementsByClassName("neoliberalism-mySlides-1");
        const dots1 = document.getElementsByClassName("neoliberalism-dot-1");
        const slides2 = document.getElementsByClassName("neoliberalism-mySlides-2");
        const dots2 = document.getElementsByClassName("neoliberalism-dot-2");

        if (slides1.length>0 && dots1.length>0) {
            for (let i = 0; i < slides1.length; i++) {
                slides1[i].style.display = "none";
            }
            slides1[slideIndex1 - 1].style.display = "block";
            for (let i = 0; i < dots1.length; i++) {
                dots1[i].className = dots1[i].className.replace(" neoliberalism-active", "");
            }
        
        dots1[slideIndex1 - 1].className += " neoliberalism-active";
        }

        if (slides2.length>0 && dots2.length>0) {
            for (let i = 0; i < slides2.length; i++) {
                slides2[i].style.display = "none";
            }
            slides2[slideIndex2 - 1].style.display = "block";
            for (let i = 0; i < dots2.length; i++) {
                dots2[i].className = dots2[i].className.replace(" neoliberalism-active", "");
            }
        
        dots2[slideIndex2 - 1].className += " neoliberalism-active";
        }
    }, [slideIndex1, slideIndex2]);

    // handle content display (significant changes dropdown)
    const [displaySignificantChanges, setDisplaySignificantChanges] = useState(false);

    if (loading) {
        return <Loading />;  
    }

        return (
        <div className='neoliberalism'>
            <div>   
                <h1 className='neoliberalism-h1'>What is neoliberalism and its relation to financialisation?</h1>
                <p className='neoliberalism-p'>
                <strong>Neoliberalism</strong> is an economic and political ideology that emphasises free-market capitalism, deregulation, and reduced government intervention in the economy. Its relation to financialisation is closely intertwined, as financialisation is often seen as a key aspect or consequence of neoliberal policies.
                <br />
                <br />
                <strong>Financialisation</strong> refers to the increasing dominance of the financial sector and financial interests in the overall economy and economic policy-making.
                This process has led to several significant changes in the economic landscape.</p>
            </div>
            <div className="neolib-significant-changes-bar" onClick={() => setDisplaySignificantChanges(prev => !prev)}>
                <span className="neolib-significant-changes-icon">
                    {displaySignificantChanges ? <GoChevronDown /> : <GoChevronRight />}
                </span>
                <span className="neolib-significant-changes-header">
                    {displaySignificantChanges ? 'Hide Significant Changes' : 'Click To Read About The Significant Changes'}
                </span>
            </div>
            {displaySignificantChanges && (
                <div className="neolib-significant-changes">
                    <img src={SignificantChanges} alt='Significant Changes'></img>
                </div>
            )}


            {/* Relationship Between Neoliberalism and Financialisation slideshow */}
            <div>
                <div className="neoliberalism-relationship-slideshow-container">
                    <h2>Relationship Between Neoliberalism and Financialisation</h2>
                    <div className="neoliberalism-mySlides-1 neoliberalism-fade" style={{ display: slideIndex1 === 1 ? "block" : "none" }}>
                        <div className='neolib-section neolib-one'>
                            <div className='neolib-wrap'> {/* circle header */}
                                <div className='neolib-num'>
                                    <div className='neolib-circle'></div>
                                    <div className='neolib-inner'>
                                        <p>1</p>
                                    </div>
                                </div>
                                <div className='neolib-heading'>Ideological alignment</div> {/* header */}
                            </div>
                            <div className='neolib-heading-p'> {/* text */}
                                <p>
                                Neoliberalism promotes free-market principles and minimal government intervention, which aligns with the expansion and deregulation of financial markets.
                                </p>
                            </div>
                        </div>
                        <div className='neolib-section neolib-two'>
                            <div className='neolib-wrap'>
                                <div className='neolib-num'>
                                    <div className='neolib-circle'></div>
                                    <div className='neolib-inner'>
                                        <p>2</p>
                                    </div>
                                </div>
                                <div className='neolib-heading'>Policy framework</div>
                            </div>
                            <div className='neolib-heading-p'>
                                <p>
                                Neoliberal policies have facilitated the growth and dominance of the financial sector through deregulation and privatisation.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="neoliberalism-mySlides-1 neoliberalism-fade" style={{ display: slideIndex1 === 2 ? "block" : "none" }}>
                        <div className='neolib-section neolib-four'>
                            <div className='neolib-wrap'>
                                <div className='neolib-num'>
                                    <div className='neolib-circle'></div>
                                    <div className='neolib-inner'>
                                        <p>4</p>
                                    </div>
                                </div>
                                <div className='neolib-heading'>Wealth concentration</div>
                            </div>
                            <div className='neolib-heading-p'>
                                <p>
                                Both neoliberalism and financialisation have contributed to the concentration of wealth and power in the hands of financial elites.
                                </p>
                            </div>
                        </div>
                        <div className='neolib-section neolib-three'>
                            <div className='neolib-wrap'>
                                <div className='neolib-num'>
                                    <div className='neolib-circle'></div>
                                    <div className='neolib-inner'>
                                        <p>3</p>
                                    </div>
                                </div>
                                <div className='neolib-heading'>Economic restructuring</div>
                            </div>
                            <div className='neolib-heading-p'>
                                <p>
                                The shift towards financialisation has been part of the broader neoliberal project of restructuring economies and societies.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="neoliberalism-mySlides-1 neoliberalism-fade" style={{ display: slideIndex1 === 3 ? "block" : "none" }}>
                        <div className='neolib-section neolib-five'>
                            <div className='neolib-wrap'>
                                <div className='neolib-num'>
                                    <div className='neolib-circle'></div>
                                    <div className='neolib-inner'>
                                        <p>5</p>
                                    </div>
                                </div>
                                <div className='neolib-heading'>Crisis and response</div>
                            </div>
                            <div className='neolib-heading-p'>
                                <p>
                                Financial crises, such as the 2008 global financial crisis, have been seen as consequences of neoliberal financialisation, yet responses often involve further entrenchment of financial sector interest.
                                </p>
                            </div>
                        </div>
                        <div className='neolib-section neolib-six'>
                            <img src={RelationshipBetweenNeoliberalismAndFinancialisation} alt='Relationship Between Neoliberalism And Financialisation'></img>
                        </div>
                    </div>

                    <button className="neoliberalism-prev" onClick={() => plusSlides1(-1)}>❮</button>
                    <button className="neoliberalism-next" onClick={() => plusSlides1(1)}>❯</button>
                </div>

                <br />

                <div className='neoliberalism-dot-container'>
                    <span className="neoliberalism-dot-1" onClick={() => currentSlide1(1)}></span> 
                    <span className="neoliberalism-dot-1" onClick={() => currentSlide1(2)}></span> 
                    <span className="neoliberalism-dot-1" onClick={() => currentSlide1(3)}></span> 
                </div>
            </div>
            
            <div>
                {/* Financial and Economic Inequality slideshow */}
                <div className="neoliberalism-inequality-slideshow-container">
                    <div className="neoliberalism-mySlides-2 neoliberalism-fade" style={{ display: slideIndex2 === 1 ? "block" : "none" }}>
                        <img src={FinancialAndEconomicInequality1} alt='Financial and Economic Inequality 1'></img>
                    </div>

                    <div className="neoliberalism-mySlides-2 neoliberalism-fade" style={{ display: slideIndex2 === 2 ? "block" : "none" }}>
                        <img src={FinancialAndEconomicInequality2} alt='Financial and Economic Inequality 2'></img>

                    </div>

                    <button className="neoliberalism-prev" onClick={() => plusSlides2(-1)}>❮</button>
                    <button className="neoliberalism-next" onClick={() => plusSlides2(1)}>❯</button>
                </div>

                <br />

                <div className='neoliberalism-dot-container'>
                    <span className="neoliberalism-dot-2" onClick={() => currentSlide2(1)}></span> 
                    <span className="neoliberalism-dot-2" onClick={() => currentSlide2(2)}></span> 
                </div>
            </div>
        </div>
    );
};

export default Neoliberalism;