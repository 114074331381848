import { useNavigate } from 'react-router-dom';
import '../../styles/moderator/ModReportedPostsOverview.css';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { formatDateTime } from '../../components/FormatFunctions';
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModReportedPostsOverview = () => {
    axios.defaults.withCredentials = true;
    const {auth} = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // set userID to null if undefined
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const navigate = useNavigate(); 
    const handleClick = (id, source) => { // redirect to individual post report
        navigate(`/reported-posts/${id}?source=${source}`, {state: {isArchived: false}});  
    };

    const [reportedForumPosts, setReportedForumPosts] = useState([]);
    const [reportedArchivedPosts, setReportedArchivedPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getReportedPosts = async () => {
            try {
                console.log(`getting reported posts`);
                const response = await axios.get(`${_URL}/reported-posts`);
                setReportedForumPosts(response.data.forumpost); 
                setReportedArchivedPosts(response.data.archivedpost);
            } 
            catch (error) {
                console.error('error getting reported posts:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Reported Forum Posts',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } 
            finally {
                setLoading(false);
            }
        };
        getReportedPosts();
    }, []);

    if (loading) {
        return <Loading />;  
    }

    return (
        <div className="reportedposts-container">
            <h1>Reported Forum Posts</h1>
            <div className='reportedposts-table'>
                <table>
                    <tr>
                        <th>Post Title</th>
                        <th className='reason'>Reason</th>
                        <th className='datetime'>Date / Time</th>
                        <th className='poststatus'>Post Status</th>
                    </tr>
                    {reportedForumPosts.map((report, index) => {
                        return (
                            <tr key={report.index} onClick={() => handleClick(report.reportID, "published")}>
                                <td>{report.forumpost.postTitle}</td> 
                                <td className='reason'>{report.reportReason}</td>
                                <td>{formatDateTime(report.reportDateTime)}</td>
                                <td>{report.postStatus}</td>
                            </tr>
                        );
                    })}
                    {reportedArchivedPosts.map((report, index) => {
                        return (
                            <tr key={report.index} onClick={() => handleClick(report.reportID, "archived")}>
                                <td>{report.archivedpost.postTitle}</td> 
                                <td className='reason'>{report.reportReason}</td>
                                <td>{formatDateTime(report.reportDateTime)}</td>
                                <td>Archived</td>
                            </tr>
                        );
                    })}
                </table>
            </div>
        </div>
    );
};

export default ModReportedPostsOverview;