import { useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import '../../styles/moderator/ModNewNarrative.css';
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';

// import SweetAlert2
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

function ModNewNarrative() {
  axios.defaults.withCredentials = true;
  const { auth } = useContext(AuthContext);
  const userID = auth ? String(auth.userID) : null; // get user id
  const role = auth.userRole;
  // handle jwt refresh
  AxiosIntercept(userID, role);

  const {id} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const narrative = location.state?.narrative || {};

  const [title, setTitle] = useState(narrative.narrTitle || '');
  const [occupation, setOccupation] = useState(narrative.narrOccupation || '');
  const [category, setCategory] = useState(narrative.narrCategory || '');
  const [desc, setDesc] = useState(narrative.narrDesc || '');

  const [loading, setLoading] = useState(true);

  const handleSave = async (e) => {
    e.preventDefault();

    if (!title || !occupation || !category || !desc) {
      Swal.fire({
        title: 'Error: \nPlease fill out all input fields.',
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          popup: 'swal2-popup-custom',
          title: 'swal2-title-custom'
        },
      });
      return;
    }
  
    const confirmMessage = isEdit
      ? 'Do you wish to update this narrative?'
      : 'Do you wish to upload this narrative? \nUploading will make it visible to all users.';
  
    const confirmSave = await Swal.fire({
      title: confirmMessage,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        popup: 'swal2-popup-custom',
        title: 'swal2-title-custom',
        confirmButton: 'swal2-confirmbutton-custom',
        cancelButton: 'swal2-cancelbutton-custom',
      },
    });
  
    if (confirmSave.isConfirmed) {
      const data = {
        userID: userID,
        narrTitle: title,
        narrOccupation: occupation,
        narrCategory: category,
        narrDesc: desc,
      };
  
      try {
        if (isEdit) {
          const response = await axios.put(`${_URL}/edit-narrative/${id}`, data);
          if (response.data.narrID) {
            Swal.fire({
              title: 'Narrative updated successfully.',
              icon: 'success',
              confirmButtonText: 'Ok',
              customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                confirmButton: 'swal2-confirmbutton-custom'
              },
            }).then(() => {
              navigate(`/narratives/${response.data.narrID}`);
            });
          } else {
            Swal.fire({
              title: 'Error: \nNarrative update failed.',
              icon: 'error',
              showConfirmButton: false,
              customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom'
              },
            });
          }
        } else {
          const response = await axios.post(`${_URL}/add-new-narrative`, data);
          if (response.data.narrID) {
            Swal.fire({
              title: 'New narrative added.',
              icon: 'success',
              confirmButtonText: 'Ok',
              customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                confirmButton: 'swal2-confirmbutton-custom'
              },
            }).then(() => {
              navigate(`/narratives/${response.data.narrID}`);
            });
          } else {
            Swal.fire({
              title: 'Error: \nNew narrative upload failed.',
              icon: 'error',
              showConfirmButton: false,
              customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom'              
              },
            });
          }
        }
      } catch (err) {
        console.error(err);
        Swal.fire({
          title: 'Error: Error occurred. \nNarrative save unsuccessful.',
          icon: 'error',
          showConfirmButton: false,
          customClass: {
            popup: 'swal2-popup-custom',
            title: 'swal2-title-custom'
          },
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true); 
        await new Promise(resolve => setTimeout(resolve, 500)); 
        setLoading(false); 
    };

    fetchData();
  }, []);

  if (loading) {
      return <Loading />;  
  }

  return (
    <div className="mod-new-narrative-page">
      <div className="mod-new-narrative-content">
        <h1 className="mod-new-narrative-title">{isEdit ? 'Edit Narrative' : 'New Narrative'}</h1>
        <form className="mod-new-narrative-form" onSubmit={handleSave}>
          <div className="form-group-new-narrative">
            <label htmlFor="narrative-title" className="mod-new-narrative-label">Narrative Title</label>
            <input id="narrative-title" type="text" className="input-field-narrative-title-input" value={title} onChange={(e) => setTitle(e.target.value)}/>
          </div>
          <div className="form-group-row">
            <div className="form-group-cat-occ">
              <label htmlFor="occupation" className="mod-new-narrative-label">Occupation</label>
              <input id="occupation" type="text" className="input-field-occupation-input" value={occupation} onChange={(e) => setOccupation(e.target.value)}/>
            </div>
            <div className="form-group-cat-occ">
              <label htmlFor="category" className="mod-new-narrative-label">Category</label>
              <select id="category" className="input-field-category-input" value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="" className="default-option" disabled>Dropdown</option>
                <option value="Homemaker">Homemaker</option>
                <option value="Social Worker">Social Worker</option>
                <option value="Entrepreneur">Entrepreneur</option>
                <option value="Office Worker">Office Worker</option>
                <option value="Physical Labour">Physical Labour</option>
              </select>
            </div>
          </div>
          <div className="form-group-narr-desc">
            <label htmlFor="narrative-description" className="mod-new-narrative-label">Narrative Description</label>
            <textarea id="narrative-description" className="input-field-narrative-description" value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
          </div>
          <button type="submit" className="upload-button">{isEdit ? 'Update Narrative' : 'Upload'}</button>
        </form>
      </div>
    </div>
  );
}

export default ModNewNarrative;
