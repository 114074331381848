
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { _URL } from '../Utils';

const AxiosIntercept = (userID, userRole) => {
    const navigate = useNavigate();

    useEffect(() => {
        // Add an Axios response interceptor
        const interceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;

                // Check if the error status is 401 or 403 and retry flag is not set
                if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
                    originalRequest._retry = true; // Set retry to true to prevent infinite loops

                    try {
                        // Refresh the token by making a POST request
                        const tokenResponse = await axios.post(`${_URL}/refresh/${userID}`, {}, { withCredentials: true });

                        // If token refresh is successful, retry the original request with the new token
                        if (tokenResponse.status === 200) {
                            originalRequest.headers['Authorization'] = `Bearer ${tokenResponse.data.accessToken}`;
                            return axios(originalRequest); // Retry the original request
                        }
                    } catch (err) {
                        console.error('Token refresh failed:', err.message);

                        // Redirect to login if token refresh fails
                        navigate('/login');
                        return Promise.reject(err);
                    }
                }

                return Promise.reject(error); // Reject any other errors
            }
        );

        // Clean up the interceptor when the component unmounts
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, [navigate, userID, userRole]); // Only run the effect when userID, userRole, or navigate changes
};

export default AxiosIntercept;