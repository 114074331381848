import React, { useState, useEffect } from 'react';
import '../styles/ContactUs.css';
import axios from 'axios';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { validateEmail } from '../components/InputRegularExpression';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

function Contactus() {
  const navigate = useNavigate();
  const [category, setCategory] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const emailValidation = validateEmail(email);
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name === '' || email === '' || category === '' || desc === '') {
      Swal.fire({
        title: 'Error: \nPlease fill out all input fields',
        icon: 'error',
        showConfirmButton: false,
        customClass: {
            popup: 'swal2-popup-custom',
            title: 'swal2-title-custom'
        }
    });
      return;
    }

    if (emailValidation !== true) {
      Swal.fire({
        title: 'Error: \n' + emailValidation,
        icon: 'error',
        showConfirmButton: false,
        customClass: {
          title: 'swal2-title-custom',
          popup: 'swal2-popup-custom'
        }
      });
      return;
    }

    try {
      const response = await axios.post(`${_URL}/contact-us`, { name, email, category, desc });
        console.log(response);
        if (response.data.message === "contact us form created.") {
          Swal.fire({
            title: 'Form has been submitted. \nWe will get back to you at our earliest convenience',
            icon: 'success',
            confirmButtonText: 'Ok',
            customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                confirmButton: 'swal2-confirmbutton-custom'
            }
        });
          navigate('/');
        } 
        else {
          Swal.fire({
            title: 'Error: \n'+ response.data.message,
            icon: 'error',
            showConfirmButton: false,
            customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom'
            }
        });
        }
    } 
    catch (err) {
      console.error(err);
      Swal.fire({
        title: 'Error: Error occurred when processing the Contact Us Form. \nPlease try again.',
        icon: 'error',
        showConfirmButton: false,
        customClass: {
            popup: 'swal2-popup-custom',
            title: 'swal2-title-custom'
        }
    });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true); 
        await new Promise(resolve => setTimeout(resolve, 500)); 
        setLoading(false); 
    };

    fetchData();
  }, []);

  if (loading) {
      return <Loading />;  
  }

  return (
    <div className="contact-us-page">
      <div className="contact-content">
        <div className="contact-header">
          <h1 className="contact-title">Need help? We got you!</h1>
          <p className="contact-subtitle">
            Want to get in touch? Please fill in the form to reach us, we will get back at our earliest availability.
          </p>
        </div>
        <div className="contact-form-container">
          <form className="contact-form">
            <div className="form-group-contact">
              <label htmlFor="name" className="contact-label">Name</label>
              <input id="name" type="text" className="input-field-contact-us" value={name} onChange={(e) => setName(e.target.value)} required/>
            </div>
            <div className="form-group-contact">
              <label htmlFor="email" className="contact-label">Email</label>
              <input
                id="email"
                type="email"
                className="input-field-contact-us"
                value={email}
                onChange={(e) => setEmail(e.target.value) }
                required
              />
            </div>
            <div className="form-group-contact">
              <label htmlFor="subject" className="contact-label">What is it about?</label>
              <select
                id="subject"
                className="input-field-contact-us"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              >
                <option value="" disabled selected className="default-option" required>Select an option</option>
                <option>Urgent help needed</option>
                <option>General queries</option>
                <option>Feedback</option>
                <option>Issues with account</option>
                <option>Others</option>
              </select>
            </div>
            <div className="form-group-contact">
              <label htmlFor="message" className="contact-label">How can we help?</label>
              <textarea id="message" className="input-field-help-decs" value={desc} onChange={(e) => setDesc(e.target.value)} required></textarea>
            </div>
          </form>
        </div>
        <button type="submit" className="submit-button" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}

export default Contactus;
