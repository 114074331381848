import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../styles/ResetPassword.css';
import axios from 'axios';
import React, { useState, useContext , useEffect} from 'react';
import { useNavigate } from 'react-router';
import AuthContext from '../context/AuthProvider';
import AxiosIntercept from '../context/AxiosIntercept';
import { validatePassword } from '../components/InputRegularExpression'; 
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

function ResetPassword() {
  axios.defaults.withCredentials = true;
  const { auth } = useContext(AuthContext);
  const userID = auth ? String(auth.userID) : null; // get user id
  const role = auth.userRole;
  // handle jwt refresh
  AxiosIntercept(userID, role);

  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [username, setUsername] = useState('');
  const [pwd, setPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleReset = async (e) => {
    e.preventDefault();

    try {
      if (username.trim() === '') {
        Swal.fire({
          title: 'Error: \nUsername input required.',
          icon: 'error',
          showConfirmButton: false,
          customClass: {
              popup: 'swal2-popup-custom',
              title: 'swal2-title-custom'
            }
        });
        return;
      }

      if (pwd.trim() === '' || confirmPwd.trim() === '') {
        Swal.fire({
          title: 'Error: \nPassword input required.',
          icon: 'error',
          showConfirmButton: false,
          customClass: {
              popup: 'swal2-popup-custom',
              title: 'swal2-title-custom'
            }
        });
        return;
      }
      if (pwd !== confirmPwd) {
        Swal.fire({
          title: 'Error: \nPasswords provided do not match.',
          icon: 'error',
          showConfirmButton: false,
          customClass: {
              popup: 'swal2-popup-custom',
              title: 'swal2-title-custom'
            }
        });
        return;
      }

      // Validate the new password
      const passwordValidationResult = validatePassword(pwd);
      if (passwordValidationResult !== true) {
        Swal.fire({
          title: 'Error: \n' + passwordValidationResult,
          icon: 'error',
          showConfirmButton: false,
          customClass: {
              popup: 'swal2-popup-custom',
              title: 'swal2-title-custom'
            }
        });
        return;
      }

      const response = await axios.put(`${_URL}/reset-password`, { userID: userID, username: username, password: pwd });
      if (response.data.userID) {
        Swal.fire({
          title: 'Password reset successful.',
          icon: 'success',
          confirmButtonText: 'Ok',
          customClass: {
            popup: 'swal2-popup-custom',
            title: 'swal2-title-custom',
            confirmButton: 'swal2-confirmbutton-custom'
          }
        });
        navigate(`/account/${response.data.userID}`);
      } else {
        Swal.fire({
          title: 'Error: \nError occurred during password reset.',
          icon: 'error',
          showConfirmButton: false,
          customClass: {
              popup: 'swal2-popup-custom',
              title: 'swal2-title-custom'
            }
        });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        Swal.fire({
          title: 'Error: \n' + err.response.data.message,
          icon: 'error',
          showConfirmButton: false,
          customClass: {
              popup: 'swal2-popup-custom',
              title: 'swal2-title-custom'
            }
        });
      } else {
        console.error(err);
        Swal.fire({
          title: 'Error: \nPassword reset failed.',
          icon: 'error',
          showConfirmButton: false,
          customClass: {
              popup: 'swal2-popup-custom',
              title: 'swal2-title-custom'
            }
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
        setLoading(true); 
        await new Promise(resolve => setTimeout(resolve, 500)); 
        setLoading(false); 
    };

    fetchData();
    }, []);

    if (loading) {
        return <Loading />;  
    }

  return (
    <div className="reset-password-page">
      <div className="reset-password-content">
        <h1 className="reset-password-title">Reset Password</h1>
        <form className="reset-password-form">
          <div className="form-group-password">
            <label htmlFor="username">Username</label>
            <input id="username" type="text" className="input-field" value={username} onChange={(e) => setUsername(e.target.value)}/>
          </div>
          <div className="form-group-password">
            <label htmlFor="newPassword">New Password</label>
            <div className="password-input-container">
              <input
                id="newPassword"
                type={newPasswordVisible ? 'text' : 'password'}
                className="input-field-new-pass"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
              />
              <i onClick={toggleNewPasswordVisibility} className="password-icon">
                <FontAwesomeIcon icon={newPasswordVisible ? faEye : faEyeSlash} />
              </i>
            </div>
          </div>
          <div className="form-group-password">
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <div className="password-input-container">
              <input
                id="confirmPassword"
                type={confirmPasswordVisible ? 'text' : 'password'}
                className="input-field"
                value={confirmPwd}
                onChange={(e) => setConfirmPwd(e.target.value)}
              />
              <i onClick={toggleConfirmPasswordVisibility} className="password-icon">
                <FontAwesomeIcon icon={confirmPasswordVisible ? faEye : faEyeSlash} />
              </i>
            </div>
          </div>
          <button type="submit" className="reset-button" onClick={handleReset}>Reset Password</button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
