import '../../styles/moderator/ModLanding.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

function ModLanding() {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    
    // handle jwt refresh
    AxiosIntercept(userID, role);
    const navigate = useNavigate();  
    
    const handleRedirect = (link) => ()=> {
        navigate(link);
    };

    const [total, setTotal] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getTotal = async () => {
            try {
                console.log('getting moderator landing');
                const response = await axios.get(`${_URL}/myview`);
                setTotal(response.data);
            } catch (error) {
                console.error('error getting moderator landing', error);
                Swal.fire({
                    title: 'Error: \nFailed to get Moderator Landing',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom'
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        getTotal();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
      <div className='modlanding-outline'>
        <div className="modlanding-container">
            <div>
                <div className="box blue" onClick={handleRedirect('/narratives')}>
                    {total.totalNarratives}
                </div>
                <p onClick={handleRedirect('/narratives')}>
                    Total <br /> Narratives
                </p>
            </div>
            <div>
                <div className="box pink" onClick={handleRedirect('/reported-posts')}>
                    {total.totalPostReport}
                </div>
                <p onClick={handleRedirect('/reported-posts')}>
                    Reported <br /> Posts
                </p>
            </div>
            <div>
                <div className="box pink" onClick={handleRedirect('/reported-comments')}>
                    {total.totalCmtReport}
                </div>
                <p onClick={handleRedirect('/reported-comments')}>
                    Reported <br /> Comments
                </p>
            </div>
            <div>
                <div className="box blue" onClick={handleRedirect('/review-new-posts')}>
                    {total.totalNewPost}
                </div>
                <p onClick={handleRedirect('/review-new-posts')}>
                    Review New <br /> Post
                </p>
            </div>
            <div>
                <div className="box blue" onClick={handleRedirect('/reported-contact')}>
                    {total.totalPendingContact}
                </div>
                <p onClick={handleRedirect('/reported-contact')}>
                    Contact <br /> Us
                </p>
            </div>
        </div>
        <div className="modlanding-container">
            <div>
                <div className="box blue" onClick={handleRedirect('/user-management')}>
                {total.totalUsers}
                </div>
                <p onClick={handleRedirect('/user-management')}>
                    User <br /> Management
                </p>
            </div>
            <div>
                <div className="box pink" onClick={handleRedirect('/archived-post-reports')}>
                {total.totalArchivedPostReport}
                </div>
                <p>
                    Archived <br /> Post Reports
                </p>
            </div>
            <div>
                <div className="box pink" onClick={handleRedirect('/archived-comment-reports')}>
                    {total.totalArchivedCmtReport}
                </div>
                <p onClick={handleRedirect('/archived-comment-reports')}>
                    Archived <br /> Comment Reports
                </p>
            </div>
            <div>
                <div className="box blue" onClick={handleRedirect('/archived-user-posts')}>
                    {total.totalArchivedPosts}
                </div>
                <p onClick={handleRedirect('/archived-user-posts')}> 
                    Archived <br /> User Posts
                </p>
            </div>
            <div>
                <div className="box blue" onClick={handleRedirect('/archived-contact')}>
                    {total.totalClosedContact}
                </div>
                <p onClick={handleRedirect('/archived-contact')}>
                    Archived <br />Contact Us
                </p>
            </div>
        </div>
      </div>

    );
}

export default ModLanding;