//Input regular expression about Password
export const validatePassword = (password) => {
    const standardPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-#$.%&*!@])(?=.*[a-zA-Z]).{10,24}$/;

    if (standardPassword.test(password)) {
        return true;
    } else if (password.length === 0) {
        return 'Password cannot be empty. Please provide a password.';
    } else {
        return 'Password minimally contain 10 to 24 characters. Password must include numbers, lower, uppercase letters and at least 1 special character.';
    }
};

//Input regular expression about Email
export const validateEmail = (email) => {
    const standardEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (standardEmail.test(email)) {
        return true;
    } else {
        return 'Please provide a valid email address.';
    }
};

//Input regular expression about Username
export const validateUsername = (username) => {
    const standardUsername = /^[a-zA-Z\d_.]{2,24}$/;

    if (standardUsername.test(username)) {
        return true;
    } else if (username.length === 0) {
        return 'Username cannot be empty. Please provide a username.';
    } else {
        return 'Please change your username.';
    }
};

