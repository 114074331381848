import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/MyPostDetail.css';
import { GoPaperAirplane, GoAlert } from "react-icons/go";
import { formatDateTime, checkActiveUserComment} from '../components/FormatFunctions';
import AuthContext from '../context/AuthProvider';
import AxiosIntercept from '../context/AxiosIntercept';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import { _URL } from '../Utils';

const MyPostDetail = () => {
    axios.defaults.withCredentials = true;
    const { auth } = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // get user id
    const role = auth.userRole;
    // handle jwt refresh
    AxiosIntercept(userID, role);
    
    const { id } = useParams();
    const navigate = useNavigate();

    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [loading, setLoading] = useState(true);
    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${_URL}/mypost/${id}`, {cmtAuthor: userID, cmtDesc: newComment});

            if (response.data) {
                Swal.fire({
                    title: 'Comment successful.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom',
                      confirmButton: 'swal2-confirmbutton-custom'
                    }
                  })
                window.location.reload();
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                Swal.fire({
                    title: 'Error: \n' + err.response.data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            } else {
                console.error(err);
                Swal.fire({
                    title: 'Error: \nCreate post failed.',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            }
        }
    };

    const filterComments = (comments) => { // filter empty {} and if cmtStatus is Rejected
        return comments.filter(comment =>
            Object.keys(comment).length > 0 && comment.cmtStatus !== 'Rejected'
        );
    };

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`${_URL}/mypost/${id}`);
                if (response.data) {
                    console.log(response.data);
                    setPost(response.data);
                    setComments(filterComments(response.data.comments));
                } else {
                    Swal.fire({
                        title: 'Error: \nPost not found',
                        icon: 'error',
                        showConfirmButton: false,
                        customClass: {
                          title: 'swal2-title-custom',
                          popup: 'swal2-popup-custom'
                        }
                      });
                }
            } catch (error) {
                console.error('Error fetching post details:', error);
                Swal.fire({
                    title: 'Error: \nFailed to fetch Post Details',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                      title: 'swal2-title-custom',
                      popup: 'swal2-popup-custom'
                    }
                  });
            } finally {
                setLoading(false);
            }
        };
        fetchPost();
    }, [id]); 

    const handleReportComment = (cmtID) => {
        navigate(`/report-comment/${cmtID}`);
    };

    // redirect to update forum post
    const handleEditPost = () => {
        navigate(`/update-forum-post/${id}`, { state: { post } });
    };

    const handleDeletePost = async () => {
        Swal.fire({
            title: 'Do you wish to delete this post? \nDeleting it will permanently remove it.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                confirmButton: 'swal2-confirmbutton-custom',
                cancelButton: 'swal2-cancelbutton-custom',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${_URL}/mypost/${id}`, { data: { userID } });
                    if (response.data) {
                        Swal.fire({
                            title: 'Post deleted successfully.',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            customClass: {
                                popup: 'swal2-popup-custom',
                                title: 'swal2-title-custom',
                                confirmButton: 'swal2-confirmbutton-custom',
                            }
                        }).then(() => {
                            navigate(`/myposts/${userID}`);
                        });
                    }
                } catch (err) {
                    console.error('Error deleting post:', err);
                    Swal.fire({
                        title: 'Error: \nFailed to delete Forum Post.',
                        icon: 'error',
                        showConfirmButton: false,
                        customClass: {
                            popup: 'swal2-popup-custom',
                            title: 'swal2-title-custom',
                        }
                    });
                }
            }
        });
    };

    if (loading) {
        return <Loading />;  
    }

    if (!post) {
        return <div>Post not found.</div>;
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 'Published':
                return '#BEF2B5';
            case 'Pending':
                return '#FDD5B0';
            case 'Rejected':
                return '#DEDFDF';
            default:
                return '#FFFFFF';
        }
    };

    return (
        <div className="my-post-detail-container">
            <div className="title-container">
                <h1>{post.postTitle}</h1>
                <span className="my-post-detail-status" style={{ backgroundColor: getStatusColor(post.postStatus) }}>
                    {post.postStatus}
                </span>
            </div>
            <div className='desc'>
                <p><strong>{post.postUser}</strong> | <strong>{post.postCategory}</strong></p>
                <p>{post.postDesc}</p>
            </div>

            {/* only show edit and delete button when post status is published */}
            {(post.postStatus === 'Published') && (
                <div className="my-post-detail-buttons">
                    <button className="my-post-detail-edit-button" onClick={handleEditPost}>Edit</button>
                    <button className="my-post-detail-delete-button" onClick={handleDeletePost}>Delete</button>
                </div>
            )}

            {/* only show delete button and comment section when post status is rejected */}
            {post.postStatus === 'Rejected' && (
                <div>
                    <div className="my-post-detail-buttons">
                        <button className="my-post-detail-delete-button" onClick={handleDeletePost}>Delete</button>
                    </div>
                    <div className="comment-section">
                        <div className="comment-input-header">
                            <span><strong>Comments</strong></span>
                        </div>
                        {comments.length > 0 ? (
                            <div className="comments-container">
                                <div className="comments-list">
                                    {comments.map((comment, index) => (
                                        <div key={index} className="comment">
                                            <div className="comment-header">
                                                <button className="report-comment" onClick={() => handleReportComment(comment.cmtID)}>
                                                    <GoAlert className="report-icon" />
                                                </button>
                                                <span><strong>{checkActiveUserComment(comment.cmtActiveUser, comment.cmtAuthorUsername)}</strong></span> 
                                            </div>
                                            <div>
                                                <span><strong>{formatDateTime(comment.cmtDateTime)}</strong></span>
                                            </div>
                                            <span>{comment.cmtDesc}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="comments-container">
                                <div className="comments-list">
                                    <div className="comment">
                                        <div className="comment-header">
                                            <span>There are no comments.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* only show comment section if post status is published */}
            {post.postStatus === 'Published' && (
                <div className="comment-section">
                    <div className="comment-input-header">
                        <span><strong>Comments</strong></span>
                    </div>
                    <form className="comment-form" onSubmit={handleCommentSubmit}>
                        <div className="comment-input-container">
                            <button type="submit" className="submit-comment-button">
                                <GoPaperAirplane />
                            </button>
                            <div className="input-with-icon">
                                <input
                                    type="text"
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                    placeholder="Write a new comment"
                                    className="comment-input"
                                />
                            </div>
                        </div>
                    </form>

                    <div className="rules-reminder">
                        <span><strong>Keep in mind the</strong></span>
                        <p>
                        <Link to="/rules-of-engagement" className="rules-link">
                            RULES OF ENGAGEMENT
                        </Link>
                        </p>
                    </div>
                    {comments.length > 0 ? (
                        <div className="comments-container">
                            <div className="comments-list">
                                {comments.map((comment, index) => (
                                    <div key={index} className="comment">
                                        <div className="comment-header">
                                            <button className="report-comment" onClick={() => handleReportComment(comment.cmtID)}>
                                                <GoAlert className="report-icon" />
                                            </button>
                                            <span><strong>{checkActiveUserComment(comment.cmtActiveUser, comment.cmtAuthorUsername)}</strong></span>
                                        </div>
                                        <div>
                                            <span><strong>{formatDateTime(comment.cmtDateTime)}</strong></span>
                                        </div>
                                        <span>{comment.cmtDesc}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="comments-container">
                            <div className="comments-list">
                                <div className="comment">
                                    <div className="comment-header">
                                        <span>There are no comments.</span>
                                    </div>
                                    <div>
                                        <span>Leave a comment to start a conversation!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MyPostDetail;
