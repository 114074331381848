import  React,{ useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { sanitizeInput, allowOnlyNumbers } from '../components/inputSanitisation';
// import css
import '../styles/Calculator.css';

// import icon
import { GoChevronDown, GoChevronRight } from "react-icons/go";

//import different types of loan image
import payday_img from '../images/payday_loan.jpg'
import shortterm_img from '../images/shortterm_loan.jpg'
import micro_img from '../images/micro_loan.jpg'

import Swal from 'sweetalert2';
import Loading from '../components/Loading';

function Calculator() {

    const [loading, setLoading] = useState(true);

    // handle toggle blurb
    const [showInfoBlurb, setShowInfoBlurb] = useState(false); // Information blurb toggle
    const [showAssumpBlurb, setShowAssumpBlurb] = useState(false); // Assumption blurb toggle
    const [showPaydayLoanTypeBlurb, setShowPaysdayLoanTypeBlurb] = useState(false); // Payday loan type blurb toggle
    const [showShortTermLoanTypeBlurb, setShowShortTermLoanTypeBlurb] = useState(false); // Payday loan type blurb toggle
    const [showMicrocreditLoanTypeBlurb, setShowMicrocreditLoanTypeBlurb] = useState(false); // Payday loan type blurb toggle

    // input form values
    const [calcForm, setCalcForm] = useState({
        amount: '', // amount borrowed
        duration: '', // duration
        durationType: '', // day or week or month
        interest: '', // interest rate
        loanFees: '', // loan fees
        loanType: 'Payday Loan', // default to Payday Loan
        repaymentFrequency: 'Monthly' // default repayment frequency
    });

    // loan breakdown values
    const [out, setOut] = useState({
        borrowFees: 0, // loan borrow fees
        totalAmount: 0, // total amount owed
        totalInterest: 0, // total interest owed
        monthlyRepayment: 0, // repayment amount
        numRepayment: 0, // number of repayments
        apr: 0 // APR for payday loans
    });

    // calculate loan breakdown 
    const calcOutput = ({ loanType, amount, duration,durationType, interest, loanFees, repaymentFrequency }) => {
        const p = parseFloat(amount); // principal amount
        const rate = parseFloat(interest); // interest rate
        const fees = parseFloat(loanFees); // loan fees

        let normalizedDuration;
        switch (durationType) {
            case 'Day(s)':
                normalizedDuration = duration / 30; // Convert days to months
                break;
            case 'Week(s)':
                normalizedDuration = duration / 4; // Convert weeks to months
                break;
            case 'Month(s)':
                normalizedDuration = duration; // Already in months
                break;
            case 'Year(s)':
                normalizedDuration = duration * 12; // Convert years to months for calculations
                break;
            default:
                normalizedDuration = duration; // Fallback if not specified
        }
    
        const time = parseFloat(normalizedDuration);

        // check if values are a number
        if (isNaN(p) || isNaN(rate) || isNaN(time) || isNaN(fees)) {
            return null; // return null if not a number
        }

        // Number of Repayments logic based on repayment frequency
        let numRepayment;
        switch (repaymentFrequency) {
            case 'Weekly':
                numRepayment = time * 4; // 4 weeks in a month
                break;
            case 'Fortnightly':
                numRepayment = (time * 4) / 2; // 2 repayments per month
                break;
            case 'Monthly':
                numRepayment = time; // 1 repayment per month
                break;
            case 'Yearly':
                numRepayment = time / 12; // Convert months back to years
                break;
            default:
                numRepayment = time; // Fallback to monthly
        }

        // insert calculation
        let output = {};

        let totalRepaymentAmount;

        if (loanType === "Payday Loan") {
            const establishmentFee = (rate/100) * p;
            const monthlyFee = 0.04 * p;
            const weeklyFee = monthlyFee / 4;
            const totalMonthlyFee = weeklyFee * duration; 
            totalRepaymentAmount = p + establishmentFee + totalMonthlyFee;
            //const numRepayment = time / 2; 
            const repaymentAmount = totalRepaymentAmount / numRepayment;
            const apr = ((establishmentFee + totalMonthlyFee) / p) * (365 / (duration * 7)) * 100;

            output = {
                borrowFees: fees, // loan borrow fees
                totalAmount: totalRepaymentAmount, // total amount owed
                totalInterest: totalRepaymentAmount - p, // total interest owed
                monthlyRepayment: repaymentAmount.toFixed(2), // repayment amount
                numRepayment: numRepayment // number of repayments
            };
        }

        if (loanType === "Short Term Loan" || loanType === "Microcredit Loan") {
            const r = (rate / 12) / 100; 
            const n = time; 
            const repaymentAmount = p * (r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
            totalRepaymentAmount = repaymentAmount * numRepayment;

            output = {
                borrowFees: 0, 
                totalAmount: totalRepaymentAmount.toFixed(2),
                totalInterest: (totalRepaymentAmount - p).toFixed(2),
                monthlyRepayment: repaymentAmount.toFixed(2),
                numRepayment: numRepayment
            };
        }

        return output;
    };

    const handleKeyDown = (e) => {
        if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
            e.preventDefault(); 
        }
    };

    const handleChange = (i) => {
        const { name, value } = i.target; // get name and value of current input field
        
        const sanitizedValue = allowOnlyNumbers(value); // Allow only numbers and dots
        const finalValue = sanitizeInput(sanitizedValue);

        setCalcForm({
            ...calcForm, [name]: value // spread calcForm object and update values
        });
    };

    const handleSubmit = (i) => {
        i.preventDefault(); 

        const output = calcOutput(calcForm); 

        if (output) { 
            setOut(output); // update the loan breakdown values here
            //console.log("Calculation output:", output);
        }
    };

    // dynamic label based on repayment frequency
    const repaymentLabel = `${calcForm.repaymentFrequency} repayment`;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await new Promise(resolve => setTimeout(resolve, 500)); 
            setLoading(false); 
        };
  
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;  
    }

    return (
        <div>
            {/*information blurb */}
            <div className="cal-info-bar" onClick={() => setShowInfoBlurb(!showInfoBlurb)}>
                <span className="cal-info-bar-icon">
                    {showInfoBlurb ? <GoChevronDown /> : <GoChevronRight />}
                </span>
                <span className="cal-info-bar-title">
                    {showInfoBlurb ? 'Hide Information About The Calculator' : 'Show Information About The Calculator'}
                </span>
            </div>
            {showInfoBlurb && (
                <div className="cal-info-blurb">
                <span>
                    The calculator helps uncover the true costs of microloans. Additionally, resources are provided on budgeting tools, as well as domestic violence helplines and support services.
                </span>
            </div>
        )}

            <div className='outline-calc'>
                <div className='container-calc'>
                    <div className='calc-header'>         
                        <h1>Loan Calculator</h1>
                    </div>

                    {/* loan calculator user input form */}
                    <div className='loan-calc'>
                        <form className='loan-form' onSubmit={handleSubmit}>
                            <label>Loan type</label>
                            <br/>
                            <select className='select-input' name="loanType" value={calcForm.loanType} onChange={handleChange}>
                                <option>Payday Loan</option>
                                <option>Short Term Loan</option>
                                <option>Microcredit Loan</option>
                            </select>
                            <br/>
                            <label>Amount borrowed</label>
                            <br/>
                            <input 
                                type='number' 
                                className='type-input' 
                                name='amount' 
                                value={calcForm.amount} 
                                onChange={handleChange} 
                                onKeyDown={handleKeyDown}
                                required 
                            />
                            <br/>
                            <label>Duration</label>
                            <br/>
                            <input 
                                type='number' 
                                className='right' 
                                name='duration' 
                                value={calcForm.duration} 
                                onChange={handleChange} 
                                onKeyDown={handleKeyDown}
                                required 
                            />                            
                            <select className='left' name='durationType' value={calcForm.durationType} onChange={handleChange}>
                                <option>Day(s)</option>
                                <option>Week(s)</option>
                                <option>Month(s)</option>
                                <option>Year(s)</option>
                            </select>
                            <br/>
                            <label>Interest %</label>
                            <br/>
                            <input 
                                type='number' 
                                className='right' 
                                name='interest' 
                                value={calcForm.interest} 
                                onChange={handleChange} 
                                onKeyDown={handleKeyDown}
                                required 
                            />                            
                            <select className='left'>
                                <option>APR</option>
                                <option>Per Annum (p.a.)</option>
                            </select>
                            <br/>
                            <label>Loan fees</label>
                            <br/>
                            <input 
                                type='number' 
                                className='type-input' 
                                name='loanFees' 
                                value={calcForm.loanFees} 
                                onChange={handleChange} 
                                onKeyDown={handleKeyDown}
                                required 
                            />
                            <br/>
                            <label>Repayment frequency</label>
                            <br/>
                            <select className='select-input' name="repaymentFrequency" value={calcForm.repaymentFrequency} onChange={handleChange}>
                                <option>Weekly</option>
                                <option>Fortnightly</option>
                                <option>Monthly</option>
                                <option>Quarterly</option>
                                <option>Yearly</option>
                            </select>
                            <input type="submit" value="Calculate" id='calc-button' />
                        </form>
                    </div>
                </div>

                <div className='container-calc'>
                    {/* loan breakdown display */}
                    <div className='loan-breakdown'>
                        <h1>Loan Breakdown</h1>
                        <div className='breakdown'>
                            <div className='item'>
                                <label>Loan borrow fees:</label>
                                <span>{out.borrowFees}</span>
                            </div>
                            <div className='item'>
                                <label>Total amount owed:</label>
                                <span>{out.totalAmount}</span>
                            </div>
                            <div className='item'>
                                <label>Total interest owed:</label>
                                <span>{out.totalInterest}</span>
                            </div>
                            <div className='item'>
                                <label>{repaymentLabel}:</label>
                                <span>{out.monthlyRepayment}</span>
                            </div>
                            <div className='item'>
                                <label>Number of repayments:</label>
                                <span>{out.numRepayment}</span>
                            </div>
                            {/* disclaimer */}
                            <p className='breakdown-disclaimer'>Disclaimer: 
                            <br />This amounts shown above is an estimate. 
                            <br />Information provided is not meant to and should not be taken as financial or legal advice.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/*Assumption dropdown for calculator*/}
            <div className="cal-assump-info-bar" onClick={() => setShowAssumpBlurb(!showAssumpBlurb)}>
                <span className="cal-assump-info-bar-icon">
                    {showAssumpBlurb ? <GoChevronDown /> : <GoChevronRight />}
                </span>
                <span className="cal-assump-info-bar-title">
                    {showAssumpBlurb ? 'Hide Information About The Calculator Assumption' : 'Show Information About The Calculator Assumption'}
                </span>
            </div>
            {showAssumpBlurb && (
                <div className="cal-assump-info">
                    <span>
                    <ul className="cal-assump-info-list">
                        <li>The Fees do not change for the life of the loan.</li>
                        <li>The calculator assumes that the interest rates provided remain constant over the term of the loan. If there are variable rates, these will not be reflected.</li>
                        <li>Any establishment and monthly fees are considered part of the total repayment calculation.</li>
                        <li>It is assumed that a year consists of 52 weeks.</li>
                        <li>No rounding is done throughout the calculation whereas repayments are rounded to at least the nearest cent in practice.</li>
                    </ul>
                    </span>
                </div>
            )}

            {/*information blurb for the payday loan*/}
            <div className="diff-type-loan-info-bar" onClick={() => setShowPaysdayLoanTypeBlurb(!showPaydayLoanTypeBlurb)}>
            <span className="diff-type-loan-info-bar-icon">
            {showPaydayLoanTypeBlurb ? <GoChevronDown /> : <GoChevronRight />}
            </span>
                <span className="diff-type-loan-info-bar-title">
                    {showPaydayLoanTypeBlurb ? 'Hide Information About The Payday Loan' : 'Show Information About The Payday Loan'}
                </span>
            </div>

            {showPaydayLoanTypeBlurb && (
                <div className="diff-type-loan-info-blurb">
                <span>
                    <ul className="diff-type-loan">
                    <h2>Payday Loan </h2>
                    <div className= "loan_img_container"> 
                        <img src={payday_img} alt="" className="loan_img"/>
                    </div>
                    <li>
                        <strong>Purpose and Target Audience:</strong>
                        <ul>
                        <li>Designed for individuals needing immediate cash to cover expenses until their next payday. </li>
                        <li>Typically used for covering unexpected bills, repairs, or other urgent financial needs. </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Loan Amounts and Terms: </strong>
                        <ul>
                        <li>Usually very small amounts, generally up to $2,000. </li>
                        <li>Extremely short repayment terms, usually aligned with the borrower’s payday cycle (from a few days to a month).</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Interest Rates and Fees: </strong>
                        <ul>
                        <li>Very high interest rates and fees, including flat fees and high annual percentage rates (APRs). </li>
                        <li>Significant penalties for late repayment, making them expensive options for borrowing. </li>
                        </ul>
                    </li>  
                    <li>
                        <strong>Providers: </strong>
                        <ul>
                        <li>Provided by payday loan companies and some online platforms. Examples include Cash Converters and Wallet Wizard. </li>
                        </ul>
                    </li>                   
                    </ul>
                </span>
            </div>
        )}

            {/*information bar for the different type of loan*/}
            <div className="diff-type-loan-info-bar" onClick={() => setShowMicrocreditLoanTypeBlurb(!showMicrocreditLoanTypeBlurb)}>
            <span className="diff-type-loan-info-bar-icon">
            {showMicrocreditLoanTypeBlurb ? <GoChevronDown /> : <GoChevronRight />}
            </span>
                <span className="diff-type-loan-info-bar-title">
                    {showMicrocreditLoanTypeBlurb ? 'Hide Information About The Microcredit Loan' : 'Show Information About The Microcredit Loan'}
                </span>
            </div>

            {showMicrocreditLoanTypeBlurb && (
                <div className="diff-type-loan-info-blurb">
                <span>
                    <ul className="diff-type-loan">
                    <h2>Microcredit Loan</h2>
                    <div className= "loan_img_container"> 
                        <img src={micro_img} alt="" className="loan_img"/>
                    </div>
                    <li>
                        <strong>Purpose and Target Audience:</strong>
                        <ul>
                        <li>Designed to support individuals who are financially excluded and unable to access traditional banking services. </li>
                        <li>Often aimed at low-income individuals, entrepreneurs, or small business owners needing small amounts of capital for starting or expanding a business or covering essential personal expenses. </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Loan Amounts and Terms: </strong>
                        <ul>
                        <li>Typically small amounts, ranging from a few hundred to a few thousand dollars.</li>
                        <li>Usually weekly or fortnightly payments, tailored to the borrower’s financial situation, ranging from a few months to a couple of years. </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Interest Rates and Fees: </strong>
                        <ul>
                        <li>Generally lower interest rates compared to short-term and payday loans, often supported by non-profit organisations, government programs, or community finance initiatives.</li>
                        <li>Minimal fees, with an emphasis on affordability and borrower support.</li>
                        </ul>
                    </li>  
                    <li>
                        <strong>Providers: </strong>
                        <ul>
                        <li>Provided by non-profit organisations, community development financial institutions (CDFIs), and certain government programs. Examples include Good Shepherd Microfinance and the No Interest Loan Scheme (NILS). </li>
                        </ul>
                    </li>                   
                    </ul>
                </span>
            </div>
        )}

            {/*information blurb for short term loan*/}
            <div className="diff-type-loan-info-bar" onClick={() => setShowShortTermLoanTypeBlurb(!showShortTermLoanTypeBlurb)}>
            <span className="diff-type-loan-info-bar-icon">
            {showShortTermLoanTypeBlurb ? <GoChevronDown /> : <GoChevronRight />}
            </span>
                <span className="diff-type-loan-info-bar-title">
                    {showShortTermLoanTypeBlurb ? 'Hide Information About The Short Term Loan' : 'Show Information About The Short Term Loan'}
                </span>
            </div>

            {showShortTermLoanTypeBlurb && (
                <div className="diff-type-loan-info-blurb">
                    <ul className="diff-type-loan">
                    <h2>Short Term Loan</h2>
                    <div className= "loan_img_container"> 
                        <img src={shortterm_img} alt="" className="loan_img"/>
                    </div>
                    <li>
                        <strong>Purpose and Target Audience:</strong>
                        <ul>
                        <li>Designed for individuals needing quick access to cash for immediate or emergency expenses. </li>
                        <li>Cater to a broader audience, including those with access to traditional banking but preferring the convenience or speed of short-term loan providers. </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Loan Amounts and Terms: </strong>
                        <ul>
                        <li>Amounts can range from a few hundred to several thousand dollars.</li>
                        <li>Repayment terms are typically short, ranging from a few weeks to a few months.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Interest Rates and Fees: </strong>
                        <ul>
                        <li>Higher interest rates than microcredit loans, reflecting the convenience, speed, and higher risk associated with lending without extensive credit checks. </li>
                        <li>Substantial fees, including application fees, service fees, and late payment penalties.</li>
                        </ul>
                    </li>  
                    <li>
                        <strong>Providers: </strong>
                        <ul>
                        <li>Commonly provided by private lenders, online loan platforms, and sometimes traditional financial institutions. Examples include Nimble and MoneyMe. </li>
                        </ul>
                    </li>                 
                    </ul>
            </div>
        )}

            <div className='resources'>
                <p>Need additional budgeting resources? Look <Link to='/additional-resources'>here</Link>.</p>
            </div>
        </div>
    );
}

export default Calculator;
