import React, { useState, useEffect, useContext }from 'react';
import axios from 'axios';
import '../../styles/moderator/ModUserManagement.css';
import { saveAs } from 'file-saver';
import AuthContext from '../../context/AuthProvider';
import AxiosIntercept from '../../context/AxiosIntercept';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import { _URL } from '../../Utils';

const ModUserManagement = () => {
    axios.defaults.withCredentials = true;
    const {auth} = useContext(AuthContext);
    const userID = auth ? String(auth.userID) : null; // set userID to null if undefined
    const role = auth.userRole;
    const navigate = useNavigate();
    // handle jwt refresh
    AxiosIntercept(userID, role);

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    // export all emails into emails.txt
    const handleDownload = () => {
        const emails = activeUsers.map(user => user.email).join('\n'); // add all emails with line break
        const blob = new Blob([emails], { type: 'text/plain;charset=utf-8' }); // new blob with emails
        saveAs(blob, 'emails.txt'); // download emails.txt
    }

    // filter users to separate normal app users from moderators
    const moderators = users.filter(user => user.isModerator === true);
    const activeUsers = users.filter(user => user.isActive === true && user.isModerator === false);
    const inactiveUsers = users.filter(user => user.isActive === false && user.isModerator === false);

    useEffect(() => {
        const getAllUsers = async () => {
            try {
                console.log(`getting user information`);
                const response = await axios.get(`${_URL}/user-management`);

                setUsers(response.data); 
            } catch (error) {
                console.error('error getting user information:', error);
                Swal.fire({
                    title: 'Error: \nFailed to get User Information',
                    icon: 'error',
                    showConfirmButton: false,
                    customClass: {
                        popup: 'swal2-popup-custom',
                        title: 'swal2-title-custom',
                    }
                });
            } finally {
                setLoading(false);
            }
        };
        getAllUsers();
    }, []);

    if (loading) {
        return <Loading />; 
    }

    const handleNewMod = () =>{
        navigate('/create-new-moderator');
    }

    return (
        <div className="mod-user-management-container">
            <div>
                <div className='mod-user-management-header'>
                    <h2>User Management - Moderator Information</h2>
                    <div>
                        <button className='new-mod-button' onClick={handleNewMod}>New Moderator</button>
                    </div>
                </div>
                <table className="user-management-tb">
                    <thead>
                        <tr>
                            <th className='user-management-username'><strong>Username</strong></th>
                            <th className='user-management-email'><strong>Email</strong></th>
                            <th className='user-management-age'><strong>Age</strong></th>
                            <th className='user-management-occupation'><strong>Occupation</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {moderators.map((user) => (
                            <tr key={user.userID}>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.age}</td>
                                <td>{user.occupation}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='mod-user-management-header'>
                    <h2>User Management - Active Users</h2>
                    <div>
                        <button className='export-user-email' onClick={handleDownload}>Export User Emails</button>
                    </div>
                </div>
                <table className="user-management-tb">
                    <thead>
                        <tr>
                            <th className='user-management-username'><strong>Username</strong></th>
                            <th className='user-management-email'><strong>Email</strong></th>
                            <th className='user-management-age'><strong>Age</strong></th>
                            <th className='user-management-occupation'><strong>Occupation</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeUsers.map((user) => (
                            <tr key={user.userID}>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.age}</td>
                                <td>{user.occupation}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <h2>User Management - Inactivate Users</h2>
                <table className="user-management-tb">
                    <thead>
                        <tr>
                            <th className='user-management-username'><strong>Username</strong></th>
                            <th className='user-management-email'><strong>Email</strong></th>
                            <th className='user-management-age'><strong>Age</strong></th>
                            <th className='user-management-occupation'><strong>Occupation</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {inactiveUsers.map((user) => (
                            <tr key={user.userID}>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.age}</td>
                                <td>{user.occupation}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
};

export default ModUserManagement;
